import _debounce from "lodash/debounce";
import { mobMenuState } from 'swag-client-common/utils/constants';
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { SUPER_SPEED_ERR_MSG } from 'swag-common/constants/error.const';
import { browserHistory } from 'react-router';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { changeActiveState, changeIsSearchResultsShown } from '../search-products/search-products.actions';
import { blockPage } from '../common/common.actions';
import { changeIsShownMobMenuState } from '../pages/pages.actions';
import { hideIntercom } from '../../utils/ui.utils';
import { searchProducts, getProducts, sortProducts, resetProducts, applyFilter, changeCheckboxFilter, changeRadioFilter, removeActiveFilter, setFilterVisibility, changePriceRange, changeIsBox, changeIsInventory, openFilterInSearchPopup, closeFilterInSearchPopup, changeIsSuperSpeed, changeIsInternationalShipping } from './search-results.actions';
export const searchResultsDispatcher = dispatch => ({
  getProducts: data => {
    dispatch(getProducts(data));
  },
  onFocus: isPageBlocked => {
    dispatch(blockPage(isPageBlocked));
  },
  onPageScrollBlock: isPageBlocked => {
    dispatch(blockPage(isPageBlocked));
  },
  sortProducts: ({
    sorting
  }) => {
    dispatch(sortProducts(sorting));
  },
  onOpenFilterPanel: () => {
    dispatch(openFilterInSearchPopup());
  },
  onCloseFilterPanel: () => {
    dispatch(closeFilterInSearchPopup());
  },
  onLeavePage: () => {
    dispatch(changeActiveState(false));
    dispatch(changeIsSearchResultsShown(false));
  },
  changeIsShownMobMenuState: isShown => {
    hideIntercom(isShown);
    dispatch(changeIsShownMobMenuState(isShown, mobMenuState.CATEGORIES));
  }
});
export const filterItemDispatcher = dispatch => {
  const applyFilters = () => {
    dispatch(applyFilter());
    dispatch(resetProducts());
    dispatch(getProducts());
    dispatch(setFilterVisibility(true));
  };
  const debouncedApply = _debounce(applyFilters, 500);
  return {
    onChangeCheckboxFilter: (filterId, filterPropertyValue, isAttributes) => {
      dispatch(changeCheckboxFilter({
        filterId,
        filterPropertyValue,
        isAttributes
      }));
      applyFilters();
    },
    onChangeRadioFilter: (filterId, filterPropertyValue) => {
      dispatch(changeRadioFilter({
        filterId,
        filterPropertyValue
      }));
      applyFilters();
    },
    onChangePriceRange: (filterId, values) => {
      dispatch(changePriceRange({
        values
      }));
      debouncedApply();
    },
    onChangeBox: (filterId, isBox) => {
      dispatch(changeIsBox({
        filterId,
        isBox
      }));
      applyFilters();
    },
    onInventoryChange: (filterId, isInventory) => {
      dispatch(changeIsInventory({
        filterId,
        isInventory
      }));
      applyFilters();
    },
    onSuperSpeedChange: async (filterId, isSuperSpeed) => {
      var _payload$config;
      const {
        payload
      } = await dispatch(getSuperSpeedSettings());
      const isSuperSpeedEnabled = payload === null || payload === void 0 ? void 0 : (_payload$config = payload.config) === null || _payload$config === void 0 ? void 0 : _payload$config.enabled;
      if (isSuperSpeedEnabled || !isSuperSpeedEnabled && !isSuperSpeed) {
        dispatch(changeIsSuperSpeed({
          filterId,
          isSuperSpeed
        }));
        applyFilters();
      }
      if (!isSuperSpeedEnabled) {
        dispatch(showNotification({
          text: SUPER_SPEED_ERR_MSG
        }));
      }
    },
    onInternationalShippingChange: (filterId, isInternationalShipping) => {
      dispatch(changeIsInternationalShipping({
        filterId,
        isInternationalShipping
      }));
      applyFilters();
    }
  };
};
export const activeFiltersDispatcher = dispatch => ({
  onClearFilters: () => {
    browserHistory.push(APP_ROUTES.COLLECTIONS);
    dispatch(searchProducts());
    dispatch(setFilterVisibility(true));
  },
  onDeleteActiveFilter: (filterId, index) => {
    dispatch(removeActiveFilter(filterId, index));
    dispatch(resetProducts());
    dispatch(searchProducts());
  }
});