function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hidePopup, setPopupParams } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { errorPopupSelector, isStoreLinkAndMakeItLivePopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { cbsStoreNameSelector } from 'swag-client-common/redux/user/user.selectors';
import { addNotification } from 'swag-client-common/redux/snackbar/snackbar.actions';
import { INotificationType } from 'swag-common/interfaces/snackbar-notification-type.interface';
import { saveResellerPreBuiltCart } from 'swag-client-common/redux/cart/cart.actions';
import { PreBuiltCartAction } from 'swag-common/constants/reseller.constants';
import { StoreLinkAndMakeItLivePopupComponent } from './store-link-and-make-it-live-popup';
const mapStateToProps = createStructuredSelector({
  open: isStoreLinkAndMakeItLivePopupShownSelector,
  storeName: cbsStoreNameSelector,
  errorMessage: errorPopupSelector
});
const mapDispatchToProps = dispatch => ({
  onConfirmClick: data => {
    dispatch(saveResellerPreBuiltCart(_objectSpread({
      action: PreBuiltCartAction.Save
    }, data)));
  },
  onCloseClick: () => {
    dispatch(hidePopup());
  },
  notifyAboutCopy: () => {
    dispatch(addNotification({
      message: 'Copied!',
      type: INotificationType.SUCCESS,
      key: Date.now()
    }));
  },
  onHideError: () => {
    dispatch(setPopupParams({
      errorMessage: ''
    }));
  }
});
export const StoreLinkAndMakeItLivePopup = connect(mapStateToProps, mapDispatchToProps)(StoreLinkAndMakeItLivePopupComponent);