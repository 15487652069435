import _pick2 from "lodash/pick";
import _flattenDeep2 from "lodash/flattenDeep";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getAmountOfUniqueColorsForAllSides } from 'swag-common/utils/colors';
import { UPSELL_WIDGET_STATES } from 'swag-common/constants/main-swag.constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { isSuperSpeedProductionOption, spreadQuantities } from 'swag-common/utils/product/product.utils';
import { PRODUCTION_TIME_STANDART_TEXT, SUPER_SPEED_PRODUCTION_OPTION } from 'swag-common/constants/product.constants';
import { checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { TOOLTIP_INDIVIDUAL_ITEM_NAME, getTooltipItemName } from '../../utils/get-tooltip-item-name';
import { getVariantsInfo, groupMulticolorSharedItemsInSameBox } from '../cart/cart.utils';
export const getPercentageDifference = (v1, v2) => 100 - v2 * 100 / v1;
export const isImprintAreaChanged = (newState, oldState) => {
  const isImprintAreaHeightChanged = newState.imprintAreaStyle.height !== oldState.imprintAreaStyle.height;
  const isImprintAreaWidthChanged = newState.imprintAreaStyle.width !== oldState.imprintAreaStyle.width;
  const isImprintAreaDifferent = isImprintAreaHeightChanged || isImprintAreaWidthChanged;
  const isViewTheSame = newState.view === oldState.view;
  const isImprintAreaNotZeroWidth = oldState.imprintAreaStyle.width !== 0;
  const isImprintAreaNotZeroHeight = oldState.imprintAreaStyle.height !== 0;
  return isImprintAreaDifferent && isViewTheSame && isImprintAreaNotZeroWidth && isImprintAreaNotZeroHeight;
};
export const isImageChanged = (newImage, oldImage) => {
  if (!newImage || !oldImage) {
    return false;
  }
  return newImage.url !== oldImage.url;
};
export const isVariantUpdated = (newImage, oldImage) => {
  if (!newImage || !oldImage) {
    return false;
  }
  return newImage.variantIndex !== oldImage.variantIndex;
};
export const generateFontsImport = fonts => {
  return fonts.map(font => `@import url(${font.src});`).join(' ');
};
export const getValidRelatedProducts = (productViews, relatedProducts) => {
  const validRelatedProducts = [];
  relatedProducts.forEach(item => {
    return item.variants.length > 0 && item.variants.every(variant => Object.values(variant.images).filter(Boolean).length === productViews.length) ? validRelatedProducts.push(item) : null;
  });
  return validRelatedProducts;
};
export const getDeletedRelatedItems = (relatedOrderItems, mainItemId) => {
  return relatedOrderItemFromCart => !relatedOrderItems.some(relatedOrderItem => relatedOrderItem._id === relatedOrderItemFromCart._id && mainItemId !== relatedOrderItem._id);
};
export function getBiggestScreenPrice(logos, texts, selectedProductSettings) {
  if (selectedProductSettings.length === 1) {
    return 0;
  }
  const colorAmount = getAmountOfUniqueColorsForAllSides(logos, texts);
  const amountOfSides = Object.keys(logos).length;
  const screenPrices = selectedProductSettings.map(settings => {
    let colorNumber = colorAmount;
    if (settings.addOneColor) {
      colorNumber += amountOfSides;
    }
    return colorNumber * settings.screenPrice / amountOfSides;
  });
  return Math.max.apply(null, screenPrices);
}
export function isQuantityChangedWhenUpsellWidgetWasDismissedAtBuilder({
  upsellDismissedStates,
  originalCartItem,
  newItem
}) {
  return originalCartItem && newItem.quantity !== originalCartItem.quantity && upsellDismissedStates && upsellDismissedStates[UPSELL_WIDGET_STATES.PRODUCT_BUILDER];
}
export function updateRelatedItemBeforeAdd(item) {
  const itemToProcess = _objectSpread({}, item, {
    textImages: {}
  });
  delete itemToProcess.relatedMode;
  return itemToProcess;
}
export function formatRelatedProductForQuantitiesBlock(item, items, sharedItems, withoutSharedItems) {
  const {
    product,
    size,
    sizeIndex,
    selectedVariants,
    quantities,
    isShared
  } = item;
  const variants = selectedVariants.map(index => {
    return formatVariantsForRelatedProductQuantitiesBlock(index, quantities, product);
  });
  const sharedInfo = getSharedItemsTotalQuantities(items, variants, product, sharedItems, item, withoutSharedItems);
  const isQuantityPerSizeEnabled = product.hasSize && !product.sizeDependentPrice;
  return {
    size,
    product,
    isShared,
    variants,
    sizeIndex,
    sharedInfo,
    isQuantityPerSizeEnabled
  };
}
function formatVariantsForRelatedProductQuantitiesBlock(variantIndex, quantities, product) {
  const {
    color,
    name,
    sizeSkus
  } = product.variants.find((item, index) => (item.index || index) === variantIndex);
  const variantQuantities = quantities[variantIndex];
  return {
    variantIndex,
    color,
    name,
    sizeSkus,
    quantities: variantQuantities
  };
}
export function getSharedItemsTotalQuantities(items, variants, prod, sharedItems, editingItem, skipIndividualItemQuantity = false) {
  if (!sharedItems.length) {
    return {
      isSharedItem: false
    };
  }
  const individualItemVariantsInfo = skipIndividualItemQuantity ? [{
    quantity: 0
  }] : getVariantsInfo({
    variants,
    prod
  });
  let totalQuantity = individualItemVariantsInfo.reduce((acc, variantInfo) => acc + variantInfo.quantity, 0);
  const isEditedSplitShippedToInventory = editingItem && checkIsSplitShippedToInventory(editingItem);
  const sharedItemsInfo = [{
    name: isEditedSplitShippedToInventory ? `${TOOLTIP_INDIVIDUAL_ITEM_NAME} (Warehouse)` : TOOLTIP_INDIVIDUAL_ITEM_NAME,
    variantsInfo: individualItemVariantsInfo
  }];
  groupMulticolorSharedItemsInSameBox(sharedItems).forEach(sharedItem => {
    var _box$name;
    const box = items.find(inner => inner.boxItemsId === sharedItem.boxItemsId && inner.prod.designUploadMode === DesignUploadMode.box);
    totalQuantity += sharedItem.quantity;
    const boxName = `${(_box$name = box === null || box === void 0 ? void 0 : box.name) !== null && _box$name !== void 0 ? _box$name : ''} (Box)`;
    const name = getTooltipItemName(editingItem, sharedItem, boxName);
    sharedItemsInfo.push({
      name,
      variantsInfo: getVariantsInfo(sharedItem)
    });
  });
  return {
    isSharedItem,
    totalQuantity,
    sharedItemsInfo
  };
}
export function getRelatedProductVariantQuantities(item, variant) {
  if (isItemBlankSampleOrPrintedSample(item)) {
    return spreadQuantities({
      quantity: 100,
      itemsQuantityToSpread: variant.quantities.length
    });
  }
  return variant.quantities;
}
export const checkIsNonPSOutOfStock = ({
  supplierInventoryLevels
}) => Boolean((supplierInventoryLevels === null || supplierInventoryLevels === void 0 ? void 0 : supplierInventoryLevels.length) && supplierInventoryLevels.every(lvl => !lvl.isTrulyPSRecord));
export const getSuperSpeedProductionTimeWithIndex = productionTimeList => {
  let result = {
    productionTime: null,
    index: -1
  };
  productionTimeList === null || productionTimeList === void 0 ? void 0 : productionTimeList.forEach((productionTime, index) => {
    if (productionTime.name === SUPER_SPEED_PRODUCTION_OPTION) {
      result = {
        productionTime,
        index
      };
    }
  });
  return result;
};
export const isStandardProductionTime = productionTime => productionTime.name === PRODUCTION_TIME_STANDART_TEXT;
export const getStandardProductionTimeIndex = (list = []) => list.findIndex(isStandardProductionTime);
export const getProductionTimeIndex = (list, selectedIdx) => {
  if (list.length && !list[selectedIdx]) {
    let idx = getStandardProductionTimeIndex(list);
    if (idx === -1) {
      idx = 0;
    }
    return idx;
  }
  return selectedIdx;
};
export const getLongestProductionTimeIdx = (list = []) => {
  let index = 0;
  if (isSuperSpeedProductionOption(list[index]) && list.length > index + 1) {
    index++;
  }
  for (let i = 0; i < list.length; i++) {
    if (list[i].time > list[index].time && !isSuperSpeedProductionOption(list[i])) {
      index = i;
    }
  }
  return index;
};
export const getDefaultProductionTimeIdx = list => {
  const idx = getStandardProductionTimeIndex(list);
  if (idx !== -1) {
    return idx;
  }
  return getLongestProductionTimeIdx(list);
};
export const prepareCompanyArtFolderLogosForSaving = data => {
  var _Object$values;
  const logos = _flattenDeep2((_Object$values = Object.values(data)) !== null && _Object$values !== void 0 ? _Object$values : []).map(logo => _objectSpread({}, _pick2(logo, ['etag', 'preview', 'originalPreview', 'original', 'previewWOBackground', 'originalFileName']), {
    colors: _pick2(logo.colors, ['detectedColors', 'selectedColors', 'colorsToPrint', 'convertTo', 'isBackgroundRemoved', 'hasBackground', 'colorsNumber'])
  }));
  return logos;
};
export const mapImagesForSpec = (imagesForSpec, name) => {
  return imagesForSpec.map(image => _objectSpread({}, image, {
    alt: `Specification image for product - ${name}`
  }));
};
export const getProductionTimeIndexFromSettings = (settings, productionTime) => settings.productionTimeList.findIndex(({
  name,
  time,
  cost
}) => name === productionTime.name && time === productionTime.time && cost === productionTime.cost);
export const getShortestProductionTimeFromList = productionTimeList => {
  let result = productionTimeList[0] || {};
  for (const productionTime of productionTimeList) {
    if (productionTime.time < result.time) {
      result = productionTime;
    }
  }
  return result;
};