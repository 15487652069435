import _isEmpty2 from "lodash/isEmpty";
import _isFinite from "lodash/isFinite";
import _cloneDeep2 from "lodash/cloneDeep";
import _get from "lodash/get";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector, createStructuredSelector } from 'reselect';
import { cartItemsSelector, isPreBuiltLimitedCartOpenedByCustomerSelector, specialBoxProductsSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { isProductionTimePopupShownSelector, popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { isAvailableSuperSpeedSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { isPreBuiltCartUserInventoryTermAcceptedSelector, isResellerInOrderCreationModeSelector, resellerAdminModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { getItemsWithEmptyLogos, getItemsWithRemovedPrintLocations } from 'swag-client-common/utils/cart.util';
import { Price } from 'swag-client-common/utils/price';
import { getIsDisabledBySizeForSampleProduct, getProductInStockLevelsByProductIdAndVariantSku } from 'swag-client-common/utils/product.utils';
import { getValidationMessages, getValidationMessagesForStandaloneItems } from 'swag-client-common/utils/validations';
import { calculateSetupFeeValue, getRelatedItemsBasedOnProvidedId, getSetupFeeForBoxProduct, getSetupFeeForLogoProduct } from 'swag-common/business-logic';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import { getFormattedProductNameFromItem, getModifiedProductNameFromItem } from 'swag-common/business-logic/inventory-product-name/get-modified-item-name-and-original.logic';
import { getOrderItemImage } from 'swag-common/business-logic/items/get-order-item-image.logic';
import { getSharedItemsWithItemItself } from 'swag-common/business-logic/items/get-shared-items-with-item-itself.logic';
import { calculateSampleDeductionDiscount } from 'swag-common/business-logic/order/calculate-sample-deduction-discount.logic';
import { calculateSetupFeeDeductionDiscount } from 'swag-common/business-logic/order/calculate-setup-fee-deduction-discount.logic';
import { findCheapestShipmentRate } from 'swag-common/business-logic/order/find-cheapest-shipment-rate.logic';
import { getDataForGettingItemShippingCost, getItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import { sortBoxContentInSwagItems } from 'swag-common/business-logic/order/get-order-of-box-content-items.logic';
import { groupItemsAsRelatedAndDefault } from 'swag-common/business-logic/order/group-items-as-related-and-default.logic';
import { OrderStorageTypes, UPSELL_WIDGET_STATES } from 'swag-common/constants/main-swag.constants';
import { CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { EMBELLISHMENT_METHODS, MAX_QUANTITY_TO_BUY, SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { ADDITIONAL_DISCOUNT_TYPES } from 'swag-common/constants/promocode';
import { PROCEED_BUTTON_TEXT } from 'swag-common/constants/reseller.constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { getCartItemsCount } from 'swag-common/utils/get-cart-items-count.util';
import { checkIsWholeBoxSuperSpeed, checkIsWholeBoxSuperSpeedWithoutAddons } from 'swag-common/utils/items/check-is-whole-box-super-speed.util';
import { getPossibleProductionListForItem, getProductionTimeListForItem } from 'swag-common/utils/items/get-production-time.util';
import { isForbiddenForInventoryItemExists } from 'swag-common/utils/items/is-forbidden-for-inventory.util';
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { isRelatedItem, isSharedOrRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import { checkIsMoreThanOneItemWithSimilarKey } from 'swag-common/utils/items/is-more-than-one-item-with-similar-key.util';
import { getLogosUniqueColorsCountInItem } from 'swag-common/utils/items/super-speed-color-limit.util';
import * as utils from 'swag-common/utils/order';
import { isLEItem, isPaymentCC } from 'swag-common/utils/order';
import { isItemInventoryReordered } from 'swag-common/utils/order/is-item-inventory-reordered.util';
import { isItemOriginallyInventoryReordered } from 'swag-common/utils/order/is-item-originally-inventory-reordered.util';
import { isItemSampleBox } from 'swag-common/utils/order/is-item-sample-box.util';
import checkIsSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { checkIsSplitShipped, checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { getFullBox, isBox, isBoxSuperSpeedType, isItemBoxAddon, isItemBoxSpecialCrinklePaper, isItemBoxSpecialNotecard, isItemBoxSpecialTape, isItemBoxSpecialTypeOrAddon, isItemBoxWithoutSpecialTypes } from 'swag-common/utils/order/item-is-box.util';
import { getAbsoluteDiscount, getCartItemsPrice, getTotalBeforeDiscount, orderTotalPrice } from 'swag-common/utils/order/order-summary-helpers.util';
import { productionCostCalculate } from 'swag-common/utils/order/production-cost-calculate.utils';
import { setupFeeForItem } from 'swag-common/utils/order/setup-fee-for-item.utils';
import { filterSuperSpeedFromProductionTimeList } from 'swag-common/utils/product/product.utils';
import { renameDeliveryOptions } from 'swag-common/utils/rename-delivery-options';
import { checkIsOrderCreationMode, isPresentationOrInvoiceMode } from 'swag-common/utils/reseller/check-is-reseller-admin-mode.util';
import { capitalize } from 'swag-common/utils/strings';
import { featureTogglesSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { filterSuperSpeedProductionTimeFromProductSettings } from 'swag-common/utils/product-settings/filter-super-speed-production-time-from-product-settings';
import { isSingleProductColoredSelector } from '../../../custom-store-admin/redux/products/products.selectors';
import { getItemWithNonZeroQuantities } from '../../utils/get-item-with-non-zero-quantities';
import { getUpsellWidgetDataForCartItem } from '../../utils/get-upsell-widget-data/get-upsell-filter-data-for-cart-item.util';
import { presentUpsellWidgetForCartItem } from '../../utils/get-upsell-widget-data/present-upsell-widget-for-cart-item.util';
import { inventoriesSelector } from '../inventory/inventory.selectors';
import { addEdgePriceToProduct } from '../product-listing/product-listing.selectors';
import { routingLocationStateSelector } from '../routing/routing.selectors';
import { userSelector } from '../user/user.selectors';
import { debossedPopupRootSelector } from '../debossed-popup/debossed-popup.selectors';
import { isCuratedBrandStoreSelector } from '../curated-brand-store/curated-brand-store-other.selector';
import { shouldShowWavedLoaderSelector, showSuggestedProductsAfterSFLSelectorFn, showSuggestedProductsBeforeSFLSelectorFn } from './cart.selectors-other';
import { absoluteDiscountValueFn, checkIfConflictRelateToBox, getAllRelatedItems, getAllSharedItems, getBreakdown, getProdVariant, getSetupFeeLabel, getSharedItemInfo } from './cart.utils';
import { flatCartItemsSelector } from './cart.selectors.no-circular';
const {
  IS_FREE_SHIPPING,
  IS_FREE_SETUP_FEE,
  IS_TAXFREE
} = ADDITIONAL_DISCOUNT_TYPES;
export * from 'swag-client-common/redux/cart/cart.selectors';
export * from './cart.selectors-other';
const checkoutRootSelector = state => state.checkout;
const propsSelector = (state, props) => props;
export const deliverToFulfillmentCenterSelector = createSelector(checkoutRootSelector, checkout => checkout.deliverToFulfillmentCenter);
export const isDeliveryOptionWasChosenSelector = createSelector(checkoutRootSelector, checkout => checkout.deliveryOptionWasChosen);
export const cartRootSelector = state => state.cart;
export const suggestedProductsSelector = createSelector(cartRootSelector, state => state ? state.suggestedProducts.map(item => addEdgePriceToProduct(item)) : []);
export const listOfNearestVariantsSelector = createSelector(cartRootSelector, state => state ? state.listOfNearestVariants : {});
export const draftItemsSelector = createSelector(cartRootSelector, state => state && state.draftItems);
export const getCartIsLoadingSelector = createSelector(cartRootSelector, cart => cart.getCartIsLoading);
export const getDraftIsLoadingSelector = createSelector(cartRootSelector, cart => cart.getDraftIsLoading);
const isSpecialBoxProductsLoadingSelector = createSelector(cartRootSelector, state => state && state.specialBoxProductsLoading);
export const recentlyViewedListSelector = createSelector(cartRootSelector, state => {
  var _state$recentlyViewed;
  return (_state$recentlyViewed = state === null || state === void 0 ? void 0 : state.recentlyViewed) !== null && _state$recentlyViewed !== void 0 ? _state$recentlyViewed : [];
});
export const isRecentlyViewedLoadingSelector = createSelector(cartRootSelector, state => !!state.isRecentlyViewedLoading);
export const checkoutButtonTouchedSelector = createSelector(cartRootSelector, state => state && state.checkoutButtonTouched);
export const pickedSpecialBoxesProductsSelector = createSelector(cartRootSelector, state => state && state.pickedSpecialBoxesProducts);
export const cartItemsUpsellDismissedStatesSelector = createSelector(cartRootSelector, state => state && state.upsellDismissedStates);
export const addOnProductsForBoxSelector = createSelector(cartRootSelector, state => {
  var _state$addOnProductsF;
  return (_state$addOnProductsF = state === null || state === void 0 ? void 0 : state.addOnProductsForBox) !== null && _state$addOnProductsF !== void 0 ? _state$addOnProductsF : [];
});
export const inventoryIdFromCartItemSelector = createSelector(cartRootSelector, ({
  items
}) => {
  var _items$;
  return ((_items$ = items[0]) === null || _items$ === void 0 ? void 0 : _items$.inventoryId) || '';
});
export const isSpecialBoxProductsAvailableSelector = createSelector(specialBoxProductsSelector, specialBoxProducts => {
  let hasNotecard = false;
  let hasCrinklePaper = false;
  specialBoxProducts.forEach(({
    specialProductType
  }) => {
    if (specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD) {
      hasNotecard = true;
    }
    if (specialProductType === SPECIAL_PRODUCT_TYPES.BOX_CRINKLE_PAPER) {
      hasCrinklePaper = true;
    }
  });
  return hasNotecard && hasCrinklePaper;
});
export const isSuggestedProductEmptySelector = createSelector(suggestedProductsSelector, suggestedProducts => !suggestedProducts || suggestedProducts.length === 0);
export const isSuggestedProductBlankSelector = createSelector(cartItemsSelector, items => items.some(item => item.isCustomizedBlank));
export const isCartLoadedSelector = createSelector(cartRootSelector, state => state && state.cartLoaded);
export const isSavedForLaterListEmptySelector = createSelector(draftItemsSelector, isCartLoadedSelector, isPreBuiltLimitedCartOpenedByCustomerSelector, (items, isCartLoaded, isPreBuiltLimitedCart) => isCartLoaded && (!items || items.length === 0) || isPreBuiltLimitedCart);
export const crinklePaperVariantsSelector = createSelector(specialBoxProductsSelector, specialBoxProducts => {
  const boxCrinklePaper = specialBoxProducts.find(({
    specialProductType
  }) => specialProductType === SPECIAL_PRODUCT_TYPES.BOX_CRINKLE_PAPER);
  if (!boxCrinklePaper) {
    return [];
  }
  return boxCrinklePaper.variants.reduce((acc, {
    isHidden,
    color,
    name
  }, index) => {
    if (!isHidden) {
      acc.push({
        name,
        color,
        value: index
      });
    }
    return acc;
  }, []);
});
function getIsBoxNotecardWasChosen(boxItems, pickedSpecialBoxesProducts) {
  const hasNotecardInBox = boxItems.some(isItemBoxSpecialNotecard);
  if (hasNotecardInBox) {
    return true;
  }
  const boxItemItself = boxItems.find(isBox);
  if (boxItemItself.isBoxNotecardAbsent) {
    return true;
  }
  const [{
    boxItemsId
  }] = boxItems;
  const specialProduct = pickedSpecialBoxesProducts[boxItemsId];
  return specialProduct && specialProduct.isNotecardPicked !== undefined;
}
function getBoxItem(boxItemsId) {
  return item => isBox(item) && item.boxItemsId === boxItemsId;
}
export const cartBoxesSpecialProductsNotPresentSelector = createSelector(cartItemsSelector, pickedSpecialBoxesProductsSelector, isSpecialBoxProductsAvailableSelector, specialBoxProductsSelector, (cartItems, pickedSpecialBoxesProducts, isSpecialBoxProductsAvailable, specialProducts) => {
  const cartBoxes = {};
  cartItems.forEach(item => {
    if (isBox(item) && !isItemInventoryReordered(item)) {
      cartBoxes[item.boxItemsId] = getFullBox(item, cartItems);
    }
  });
  const result = [];
  if (!isSpecialBoxProductsAvailable) {
    return result;
  }
  Object.entries(cartBoxes).forEach(([boxItemId, boxItems]) => {
    const boxItem = boxItems.find(getBoxItem(boxItemId));
    if (!boxItem || isItemOriginallyInventoryReordered(boxItem)) {
      return;
    }
    const hasCrinklePaperInBox = boxItems.find(isItemBoxSpecialCrinklePaper);
    if (!hasCrinklePaperInBox) {
      result.push(_objectSpread({}, specialProducts.find(prod => prod.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_CRINKLE_PAPER), {
        boxItem
      }));
    }
    if (!getIsBoxNotecardWasChosen(boxItems, pickedSpecialBoxesProducts)) {
      result.push(_objectSpread({}, specialProducts.find(prod => prod.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD), {
        boxItem
      }));
    }
  });
  return result;
});
export const storageTypeSelector = createSelector(cartRootSelector, state => state && state.storageType);
export const isRemoveFromBoxSelector = createSelector(cartRootSelector, state => state === null || state === void 0 ? void 0 : state.isRemoveFromBox);
export const isLoadingSelector = createSelector(cartRootSelector, state => state && state.isLoading);
export const cardPaymentMethodSelector = createSelector(cartRootSelector, state => state && state.paymentMethod);
const makeItemsFlat = items => items.reduce((acc, item) => {
  const flatItems = item.products ? [item, ...item.products] : item;
  return acc.concat(flatItems);
}, []);
export const supplierInventoryLevelsCartAndDraftSelector = createSelector(cartRootSelector, state => {
  const draftSupplierInventoryLevels = _get(state, 'draft.supplierInventoryLevels', []);
  const cartSupplierInventoryLevels = _get(state, 'cart.supplierInventoryLevels', []);
  return draftSupplierInventoryLevels.concat(cartSupplierInventoryLevels);
});
export const stockLevelsByProductIdAndVariantSkuSelector = createSelector(supplierInventoryLevelsCartAndDraftSelector, supplierInventoryLevels => getProductInStockLevelsByProductIdAndVariantSku({
  supplierInventoryLevels
}));
export const flatDraftItemsSelector = createSelector(draftItemsSelector, makeItemsFlat);
export const cartItemsCountSelector = createSelector(cartItemsSelector, getCartItemsCount);
export const draftItemsCountSelector = createSelector(draftItemsSelector, getCartItemsCount);
export const cartItemIdToEditSelector = state => state.cart.itemToEdit;
export const orderProductionTotalSelector = state => state.cart.total;
export const ksFlowSelector = state => state.cart.ksFlow;
export const shippingPriceSelector = state => state.cart.shipmentCustomerTotal;
export const ksFulfillmentPriceSelector = state => state.cart.ksFulfillment || 0;
export const promocodeDiscountSelector = state => +state.cart.promocodeDiscount;
export const discountMeasurementSelector = state => state.cart.measurement;
export const isShippingFreeSelector = state => state.cart[IS_FREE_SHIPPING];
export const isSetupFeeFreeSelector = state => state.cart[IS_FREE_SETUP_FEE];
export const isTaxFreeSelector = state => state.cart[IS_TAXFREE];
export const shipmentDiscountSelector = state => state.cart.shipmentDiscount;
export const setupFeeDiscountSelector = state => state.cart.setupFeeDiscount;
export const orderIdtSelector = state => state.cart.orderId;
export const isSomeSuperSpeedItemsSelector = createSelector(cartItemsSelector, items => items.some(item => isSuperSpeedItem(item)));
export const isSomeNoInternationalShippingItemsSelector = createSelector(cartItemsSelector, items => items.some(item => Boolean(item.prod.isForbiddenToShipInternationally)));
export const noInternationalShippingItemsSelector = createSelector(cartItemsSelector, items => items.reduce((acc, item) => {
  if (item.prod.isForbiddenToShipInternationally && !acc.includes(item.prod.name)) {
    acc.push({
      productId: item.prod._id,
      name: item.prod.name
    });
  }
  return acc;
}, []));
export const isNoInternationalShippingInfoShownSelector = createSelector(propsSelector, cartItemsSelector, ({
  item
}, cartItems) => {
  const isBoxItself = isBox(item);
  const boxItems = cartItems.filter(el => el.boxItemsId === item.boxItemsId);
  if (isBoxItself) {
    return boxItems.some(el => Boolean(el.prod.isForbiddenToShipInternationally));
  }
  return item.prod.isForbiddenToShipInternationally;
});
export const paymentMethodSelector = createSelector(userSelector, isSomeSuperSpeedItemsSelector, (user, isSomeSuperSpeedItems) => {
  const {
    paymentMethod,
    allowACHForSuperSpeedOrders
  } = user;
  if (isSomeSuperSpeedItems && paymentMethod !== CREDIT_CARD && !allowACHForSuperSpeedOrders) {
    return CREDIT_CARD;
  }
  return paymentMethod;
});
export const isItemInDraftSelector = createSelector(storageTypeSelector, storageType => storageType === OrderStorageTypes.DRAFT);
const findAllItemsForBox = (items, boxId, itemId) => {
  const boxItems = items.filter(i => i.boxItemsId === boxId && itemId !== String(i._id));
  return sortBoxContentInSwagItems(boxItems).map(item => _objectSpread({}, item, {
    prod: _objectSpread({}, item.prod, {
      quantity: item.quantityPerBox
    })
  }));
};
export const cartItemToEditSelector = createSelector(flatCartItemsSelector, flatDraftItemsSelector, cartItemIdToEditSelector, storageTypeSelector, (cartItems, draftItems, selectedId, storageType) => {
  const items = storageType === OrderStorageTypes.DRAFT ? draftItems : cartItems;
  let item = _cloneDeep2(items.find(({
    _id
  }) => _id === selectedId));
  if (!item) {
    return null;
  }
  if (item.boxItemsId) {
    if (item.prod.designUploadMode === DesignUploadMode.box) {
      const products = findAllItemsForBox(items, item.boxItemsId, item._id);
      item = _objectSpread({}, item, {
        products
      });
    } else {
      let boxItem = _cloneDeep2(items.find(i => i.boxItemsId === item.boxItemsId && i.prod.designUploadMode === DesignUploadMode.box));
      if (!boxItem) {
        return item;
      }
      const products = _cloneDeep2(findAllItemsForBox(items, boxItem.boxItemsId, boxItem._id));
      boxItem = _objectSpread({}, boxItem, {
        products
      });
      return boxItem;
    }
  }
  return item;
});
export const cartItemToEditWithSplitShippedSelector = createSelector(cartItemToEditSelector, item => {
  if (checkIsSplitShippedToInventory(item)) {
    return getItemWithNonZeroQuantities(item);
  }
  return item;
});
export const sharedItemInfoSelector = createSelector(flatCartItemsSelector, flatDraftItemsSelector, cartItemToEditSelector, storageTypeSelector, (cartItems, draftItems, editedItem, storageType) => {
  const items = storageType === OrderStorageTypes.CART ? cartItems : draftItems;
  const editedItemWithNonZeroQuantities = getItemWithNonZeroQuantities(editedItem);
  const itemsWithNonZeroQuantities = items.map(getItemWithNonZeroQuantities);
  return getSharedItemInfo(editedItemWithNonZeroQuantities, itemsWithNonZeroQuantities, storageType);
});
export const sharedVariantsSelector = createSelector(flatCartItemsSelector, cartItemToEditSelector, (items, editedItem) => {
  if (!checkIsSharedItem(editedItem, items)) {
    return editedItem.variants;
  }
  const sharedItems = items.filter(item => checkIsSharedItem(editedItem, [item]));
  const sharedVariants = [...editedItem.variants];
  sharedItems.reduce((acc, i) => [...acc, ...i.variants], []).forEach(variant => {
    const sharedVariantIndex = sharedVariants.findIndex(v => v.variantIndex === variant.variantIndex);
    if (sharedVariantIndex === -1) {
      return sharedVariants.push(variant);
    }
    const oldSharedVariant = sharedVariants[sharedVariantIndex];
    sharedVariants[sharedVariantIndex] = _objectSpread({}, oldSharedVariant, {
      quantities: oldSharedVariant.quantities.map((q, i) => q + variant.quantities[i])
    });
  });
  return sharedVariants;
});
export const sampleProductToEditPerSizeSelector = createSelector(cartItemToEditSelector, stockLevelsByProductIdAndVariantSkuSelector, (item, stockLevelsByProductIdAndVariantSku) => {
  const isDisabledBySize = getIsDisabledBySizeForSampleProduct(item, stockLevelsByProductIdAndVariantSku);
  return _objectSpread({}, item, {
    isDisabledBySize
  });
});
export const cartItemToRemoveSelector = createSelector(flatCartItemsSelector, flatDraftItemsSelector, cartItemIdToEditSelector, storageTypeSelector, (cartItems, draftItems, selectedId, storageType) => {
  const items = storageType === OrderStorageTypes.DRAFT ? draftItems : cartItems;
  const item = _cloneDeep2(items.find(({
    _id
  }) => _id === selectedId));
  if (!item) {
    return null;
  }
  if (item.boxItemsId) {
    if (item.prod.designUploadMode === DesignUploadMode.box) {
      item.products = findAllItemsForBox(items, item.boxItemsId, item._id);
    }
  }
  return item;
});
export const cartItemBoxItemItselfSelector = createSelector(cartItemToRemoveSelector, flatCartItemsSelector, flatDraftItemsSelector, (cartItemToRemove, cartItems, draftItems) => {
  if (!cartItemToRemove) {
    return null;
  }
  const items = [...draftItems, ...cartItems];
  return items.find(item => item.boxItemsId === cartItemToRemove.boxItemsId && item.prod.designUploadMode === DesignUploadMode.box);
});
export const isCartEmptySelector = createSelector(cartItemsSelector, isCartLoadedSelector, (items, isCartLoaded) => isCartLoaded && (!items || items.length === 0));
export const isGreyRecentlyViewedSelector = createSelector(isSavedForLaterListEmptySelector, isSuggestedProductEmptySelector, isSuggestedProductBlankSelector, (isSavedForLaterListEmpty, isSuggestedProductEmpty, isSuggestedProductBlank) => isSavedForLaterListEmpty && isSuggestedProductEmpty || isSavedForLaterListEmpty && isSuggestedProductBlank || !isSavedForLaterListEmpty && !isSuggestedProductEmpty);
export const logoSelector = createSelector(cartItemsSelector, items => {
  if (!items || items.length === 0) {
    return null;
  }
  const firstItemLogos = items[0].logos;
  const firstLogoLocation = Object.keys(firstItemLogos || {}).find(location => firstItemLogos[location].preview);
  return firstLogoLocation && firstItemLogos[firstLogoLocation].preview || null;
});
export const cartPageIsLoadingSelector = createSelector(isLoadingSelector, isSpecialBoxProductsLoadingSelector, getCartIsLoadingSelector, getDraftIsLoadingSelector, (isLoading, isSpecialBoxProductsLoading, getCartIsLoadingSelector, getDraftIsLoadingSelector) => {
  return isSpecialBoxProductsLoading || isLoading || getCartIsLoadingSelector || getDraftIsLoadingSelector;
});
export const orderTokenSelector = createSelector(cartRootSelector, state => state && state.orderToken);
export const selectedItemSelector = state => state.cart.selectedItem;
export const itemToEditSelector = state => state.cart.itemToEdit;
export const checkIsWholeBoxSuperSpeedSelector = createSelector((_, {
  items
}) => items, items => checkIsWholeBoxSuperSpeed(items));
export const checkIsSuperSpeedAddonWarnSelector = createSelector((_, {
  items
}) => items, items => {
  const isWholeBoxSuperSpeedWithoutAddons = checkIsWholeBoxSuperSpeedWithoutAddons(items);
  const isAddonsExists = Boolean(items.filter(isItemBoxAddon).length);
  return isWholeBoxSuperSpeedWithoutAddons && isAddonsExists;
});
export const productSettingsSelector = createSelector(selectedItemSelector, isAvailableSuperSpeedSelector, (item, isAvailableSuperSpeed) => {
  if (!(item !== null && item !== void 0 && item.allProductSettings)) {
    return [];
  }
  const sortedProductSettings = [...item.allProductSettings].sort((a, b) => a.index - b.index);
  if (isAvailableSuperSpeed) {
    return sortedProductSettings;
  }
  return filterSuperSpeedProductionTimeFromProductSettings(sortedProductSettings);
});
export const productSettingsNotBlankSelector = createSelector(productSettingsSelector, productSettings => {
  return productSettings.filter(settings => !!settings.embellishmentMethodId);
});
export const propsItemSelector = (state, props) => Array.isArray(props.item) ? props.item[0] : props.item;
export const allItemsInCartAndDraftSelector = createSelector(cartItemsSelector, draftItemsSelector, (cartItems, draftItems) => [...cartItems, ...draftItems]);
export const itemSelector = createSelector(propsItemSelector, cartItemsSelector, allItemsInCartAndDraftSelector, (item, cartItems, allItems) => {
  const cartItem = item ? allItems.find(i => item._id === i._id) : item;
  if (item && (!item.products || !item.products.length)) {
    const products = cartItems.filter(i => {
      var _i$prod;
      return ((_i$prod = i.prod) === null || _i$prod === void 0 ? void 0 : _i$prod.designUploadMode) !== DesignUploadMode.box && i.boxItemsId === item.boxItemsId;
    });
    return _objectSpread({}, cartItem, {
      products
    });
  }
  return cartItem;
});
export const relatedItemsBasedOnProvidedId = createSelector(itemSelector, cartItemsSelector, (item, items) => {
  return getRelatedItemsBasedOnProvidedId(item, items, true);
});
export const isItemSampleSelector = createSelector(itemSelector, item => {
  if (!item) {
    return false;
  }
  const {
    isSample,
    isPrintedSample
  } = item;
  return isSample || isPrintedSample;
});
export const cartHasSampleItemsSelector = createSelector(cartItemsSelector, items => {
  const sampleItems = items.filter(item => {
    if (!item) {
      return false;
    }
    return isItemBlankSampleOrPrintedSample(item) || isItemSampleBox(item);
  });
  return sampleItems.length > 0;
});
const isSelectedItemIsSharedItemSelector = createSelector(selectedItemSelector, popupParamsSelector, cartItemsSelector, draftItemsSelector, (editedItem, popupParams, cartItems, draftItems) => {
  if (!editedItem) {
    return false;
  }
  const storageType = popupParams === null || popupParams === void 0 ? void 0 : popupParams.storageType;
  const items = storageType === OrderStorageTypes.DRAFT ? draftItems : cartItems;
  const sharedItems = getSharedItemsWithItemItself(editedItem, items);
  if (sharedItems.length < 2) {
    return false;
  }
  return sharedItems.length > 1;
});
const selectedItemProductIdSelector = createSelector(selectedItemSelector, item => {
  var _item$prod$_id, _item$prod;
  return (_item$prod$_id = item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod._id) !== null && _item$prod$_id !== void 0 ? _item$prod$_id : '';
});
const uniqueColorsForOrderItemSelector = createSelector(selectedItemSelector, item => {
  if (!item) {
    return 0;
  }
  if (item.prod && isProductFullDesign(item.prod)) {
    return 1;
  }
  return getLogosUniqueColorsCountInItem(item);
});
const currentProductSettingsSelector = createSelector(selectedItemSelector, productSettingsNotBlankSelector, (item, productSettings) => {
  return productSettings.find(({
    embellishmentMethodId
  }) => embellishmentMethodId === item.embellishmentMethod) || null;
});
const debossedEmbellishmentMethodIdSelector = createSelector(productSettingsNotBlankSelector, productSettings => {
  const debossedSettings = productSettings.find(({
    embellishmentMethod
  }) => embellishmentMethod.name === EMBELLISHMENT_METHODS.DEBOSSED);
  return (debossedSettings === null || debossedSettings === void 0 ? void 0 : debossedSettings.embellishmentMethodId) || '';
});
const itemIdPopupPropSelector = createSelector(popupParamsSelector, ({
  itemId
}) => itemId);
const hasDebossedPopupBeenShownSelector = createSelector(selectedItemSelector, debossedPopupRootSelector, (item, {
  itemId: debossedItemId,
  hasDebossedPopupBeenShownOnItemEdit
}) => {
  if (debossedItemId && debossedItemId === (item === null || item === void 0 ? void 0 : item._id)) {
    return hasDebossedPopupBeenShownOnItemEdit;
  }
  return false;
});
const prodTimeForItemPopupSelector = createSelector(selectedItemSelector, itemIdPopupPropSelector, cartItemsSelector, draftItemsSelector, (selectedItem, itemId, items, draftItems) => {
  var _item$prodTime;
  const allItems = [...items, ...draftItems];
  if (selectedItem !== null && selectedItem !== void 0 && selectedItem.selectedProductionTimeIdx) {
    return selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.selectedProductionTimeIdx;
  }
  const item = allItems.find(i => i._id === itemId);
  return (_item$prodTime = item === null || item === void 0 ? void 0 : item.prodTime) !== null && _item$prodTime !== void 0 ? _item$prodTime : 0;
});
export const productionTimeForItemPopupSelector = createStructuredSelector({
  productId: selectedItemProductIdSelector,
  prodTime: prodTimeForItemPopupSelector,
  open: isProductionTimePopupShownSelector,
  isSharedItem: isSelectedItemIsSharedItemSelector,
  popupParams: popupParamsSelector,
  uniqueColorsForOrderItem: uniqueColorsForOrderItemSelector,
  productSettings: productSettingsNotBlankSelector,
  debossedEmbellishmentMethodId: debossedEmbellishmentMethodIdSelector,
  selectedProductSettings: currentProductSettingsSelector,
  hasDebossedPopupBeenShown: hasDebossedPopupBeenShownSelector
});
export const sampleDeductionDiscountSelector = createSelector(cartItemsSelector, calculateSampleDeductionDiscount);
export const sampleDeductionDiscountLabelSelector = createSelector(cartItemsSelector, items => {
  if (items.some(item => item.isReorderFromSwagSampleBox)) {
    return 'Discount: ';
  }
  return 'Discount (Printed sample cost):';
});
export const setupFeeDeductionDiscountSelector = createSelector(cartItemsSelector, calculateSetupFeeDeductionDiscount);
export const absoluteDiscountValueSelector = createSelector(cartRootSelector, absoluteDiscountValueFn);
export const taxesPriceSelector = createSelector(cartRootSelector, state => state && state.taxesPrice || 0);
export const swagSpaceTaxesPriceSelector = createSelector(cartRootSelector, state => state && state.swagSpaceTaxesPrice);
export const swagSpaceTotalPriceSelector = createSelector(cartRootSelector, state => state && state.swagSpaceTotalPrice);
export const totalBeforeDiscountSelector = createSelector(orderProductionTotalSelector, shippingPriceSelector, taxesPriceSelector, ksFulfillmentPriceSelector, getTotalBeforeDiscount);
export const swagSpaceOrderTotalSelector = createSelector(swagSpaceTotalPriceSelector, shippingPriceSelector, swagSpaceTaxesPriceSelector, (swagSpaceTotalPrice, shipping, swagSpaceTaxesPrice) => {
  return getTotalBeforeDiscount(swagSpaceTotalPrice, shipping, swagSpaceTaxesPrice, 0);
});
export const totalBeforeDiscountForCartSelector = createSelector(orderProductionTotalSelector, ksFulfillmentPriceSelector, (production, ksFulfillment) => production + ksFulfillment);
export const isPaymentByCreditCardSelector = createSelector(paymentMethodSelector, payMethod => isPaymentCC(payMethod));
function getVariantFromProdVariants({
  variantIndex,
  quantities
}, prod) {
  return {
    color: prod.variants[variantIndex] && prod.variants[variantIndex].color || '',
    name: prod.variants[variantIndex] && prod.variants[variantIndex].name || '',
    sizeSkus: prod.variants[variantIndex] ? prod.variants[variantIndex].sizeSkus : null,
    isAlwaysInStockVariant: prod.variants[variantIndex] && prod.variants[variantIndex].isAlwaysInStockVariant || false,
    productSku: prod.variants[variantIndex] && prod.variants[variantIndex].productSku || '',
    quantities,
    variantIndex,
    isVariantInStock: true
  };
}
export const itemVariantsSelector = createSelector(cartItemToEditWithSplitShippedSelector, item => {
  if (!item) {
    return null;
  }
  const {
    variants,
    prod
  } = item;
  return variants.map(variant => getVariantFromProdVariants(variant, prod));
});
export const sizeSelector = createSelector(cartItemToEditSelector, item => {
  if (!item) {
    return null;
  }
  return item.prod.size;
});
export const relatedItemsOfEditingItemSelector = createSelector(allItemsInCartAndDraftSelector, cartItemToEditSelector, getAllRelatedItems);
export const sharedItemsOfEditingItemSelector = createSelector(allItemsInCartAndDraftSelector, cartItemToEditSelector, getAllSharedItems);
export const maxQuantSelector = createSelector(cartItemToEditSelector, item => {
  var _item$prod2, _item$prod2$productSe, _item$prod2$productSe2, _item$prod2$productSe3;
  const isSuperSpeed = isSuperSpeedItem(item);
  return isSuperSpeed && (_item$prod2 = item.prod) !== null && _item$prod2 !== void 0 && (_item$prod2$productSe = _item$prod2.productSettings) !== null && _item$prod2$productSe !== void 0 && (_item$prod2$productSe2 = _item$prod2$productSe[0]) !== null && _item$prod2$productSe2 !== void 0 && (_item$prod2$productSe3 = _item$prod2$productSe2.superSpeed) !== null && _item$prod2$productSe3 !== void 0 && _item$prod2$productSe3.maxQuant ? item.prod.productSettings[0].superSpeed.maxQuant : MAX_QUANTITY_TO_BUY;
});
export const relatedMinQuantSelector = createSelector(relatedItemsOfEditingItemSelector, relatedItems => {
  if (!relatedItems || relatedItems.length < 2) {
    return null;
  }
  return Math.min.apply(null, relatedItems.map(related => {
    var _related$prod;
    return (related === null || related === void 0 ? void 0 : (_related$prod = related.prod) === null || _related$prod === void 0 ? void 0 : _related$prod.minQuant) || 0;
  }));
});
export const isRelatedItemByMinQuantSelector = createSelector(relatedMinQuantSelector, relatedMinQuant => {
  return _isFinite(relatedMinQuant);
});
export const minQuantSelector = createSelector(cartItemToEditSelector, relatedMinQuantSelector, isRelatedItemByMinQuantSelector, (item, relatedMinQuant, isRelated) => {
  var _item$prod4;
  const isSuperSpeed = isSuperSpeedItem(item);
  if (isRelated) {
    return relatedMinQuant;
  }
  if (isSuperSpeed) {
    var _item$prod3, _item$prod3$productSe, _item$prod3$productSe2, _item$prod3$productSe3;
    return (_item$prod3 = item.prod) === null || _item$prod3 === void 0 ? void 0 : (_item$prod3$productSe = _item$prod3.productSettings) === null || _item$prod3$productSe === void 0 ? void 0 : (_item$prod3$productSe2 = _item$prod3$productSe[0]) === null || _item$prod3$productSe2 === void 0 ? void 0 : (_item$prod3$productSe3 = _item$prod3$productSe2.superSpeed) === null || _item$prod3$productSe3 === void 0 ? void 0 : _item$prod3$productSe3.minQuant;
  }
  return (_item$prod4 = item.prod) === null || _item$prod4 === void 0 ? void 0 : _item$prod4.minQuant;
});
export const cartItemsPriceSelector = createSelector(cartItemsSelector, getCartItemsPrice);
export const isPromocodeDiscountPresentSelector = createSelector(promocodeDiscountSelector, isShippingFreeSelector, isSetupFeeFreeSelector, (promocodeDiscount, isShippingFree, isSetupFeeFree) => promocodeDiscount || isShippingFree || isSetupFeeFree);
export const isPromocodeDiscountPresentInCartSelector = createSelector(promocodeDiscountSelector, isSetupFeeFreeSelector, (promocodeDiscount, isSetupFeeFree) => promocodeDiscount || isSetupFeeFree);
const isDeductionPriceExistsSelector = createSelector(sampleDeductionDiscountSelector, setupFeeDeductionDiscountSelector, (sampleDeductionDiscount, setupFeeDeductionDiscount) => sampleDeductionDiscount || setupFeeDeductionDiscount);
export const isDiscountedPriceShownSelector = createSelector(isPromocodeDiscountPresentSelector, isDeductionPriceExistsSelector, resellerAdminModeSelector, (promocodeDiscount, isDeductionPriceExists, resellerAdminMode) => checkIsOrderCreationMode(resellerAdminMode) || promocodeDiscount || isDeductionPriceExists);
export const isDiscountedPriceShownInCartSelector = createSelector(isPromocodeDiscountPresentInCartSelector, isDeductionPriceExistsSelector, (promocodeDiscount, isDeductionPriceExists) => promocodeDiscount || isDeductionPriceExists);
export const quantitySelector = createSelector(itemSelector, item => item.quantity);
export const itemIdSelector = createSelector(itemSelector, item => item._id);
export const isSwagSampleBoxSelector = createSelector(itemSelector, item => item && isItemSampleBox(item));
export const isFullDesignSelector = createSelector(itemSelector, item => _get(item, ['prod', 'designUploadMode'], false) === DesignUploadMode.fullDesign);
export const isBoxCrinklePaperSelector = createSelector(itemSelector, isItemBoxSpecialCrinklePaper);
export const isBoxNotecardSelector = createSelector(itemSelector, isItemBoxSpecialNotecard);
export const isSuperSpeedItemSelector = createSelector(itemSelector, item => {
  return isSuperSpeedItem(item) && !item.isCustomizedBlank;
});
export const isItemLimitedEditionSelector = createSelector(itemSelector, isSuperSpeedItemSelector, (item, isSuperSpeed) => isLEItem(item) && !isSuperSpeed);
export const isBoxTapeSelector = createSelector(itemSelector, isItemBoxSpecialTape);
export const isStrictInHandDateSelector = createSelector(itemSelector, item => item.isStrictInHandDate);
export const productNameSelector = createSelector(itemSelector, item => capitalize(item.name || item.prod.name));
export const modifiedProductNameSelector = createSelector(itemSelector, getModifiedProductNameFromItem);
export const lineItemPriceSelector = createSelector(itemSelector, productionCostCalculate);
export const setupFeeForLogoProductSelector = createSelector(itemSelector, cartItemsSelector, relatedItemsBasedOnProvidedId, isItemSampleSelector, getSetupFeeForLogoProduct);
export const setupFeeForBoxProductSelector = createSelector(itemSelector, cartItemsSelector, isItemSampleSelector, getSetupFeeForBoxProduct);
export const setupFeeSelector = createSelector(itemSelector, setupFeeForBoxProductSelector, setupFeeForLogoProductSelector, setupFeeForItem);
export const orderItemImageSelector = createSelector(itemSelector, ({
  variants,
  prod,
  sizeInd,
  customerMockups
}) => {
  var _variants$;
  const variantInd = (_variants$ = variants[0]) === null || _variants$ === void 0 ? void 0 : _variants$.variantIndex;
  const image = getOrderItemImage({
    product: prod,
    customerMockups,
    variantInd,
    sizeInd
  });
  return image.url;
});
export const orderItemQuantitySelector = createSelector(propsItemSelector, item => item.quantity);
export const shipmentChargesSelector = createSelector(itemSelector, cartItemsSelector, draftItemsSelector, (item, cartItems, draftItems) => {
  const allItems = [...cartItems, ...draftItems];
  return getItemShippingCost(getDataForGettingItemShippingCost(item, allItems));
});
export const oldSetupFeeSelector = createSelector(itemSelector, setupFeeSelector, (item, setupFee) => {
  if (item.boxItemsId) {
    if (!item.setupFeeDeductionDiscount) {
      return 0;
    }
    const multiplier = getItemPriceMultiplier(item);
    const calculatedSetupFee = calculateSetupFeeValue({
      product: item.prod,
      logos: item.logos,
      texts: item.texts,
      isSample: item.isItemSample,
      prodTime: item.prodTime,
      multiplier
    });
    return item.setupFeeDeductionDiscount > calculatedSetupFee ? calculatedSetupFee : item.setupFeeDeductionDiscount + setupFee;
  }
  return item.setupFeeDeductionDiscount && item.setupFeeDeductionDiscount + setupFee || 0;
});
export const lineItemPriceWithShippingSelector = createSelector(itemSelector, shipmentChargesSelector, (item, shippingPrice) => {
  const productionPrice = productionCostCalculate(item);
  return productionPrice + shippingPrice;
});
export const pricePerItemInLineItem = createSelector(propsItemSelector, lineItemPriceSelector, quantitySelector, setupFeeSelector, (propsItem, price, quantity, setupFee) => {
  const screenPrice = Price.getScreenPriceForItem({
    item: propsItem,
    setupFee
  });
  return Price.getPricePerItem({
    assemblyFee: propsItem.prod.assemblyFee,
    quantity: quantity,
    screenPrice,
    totalPriceForItems: price
  });
});
const makeDeliveryOptions = deliveriesArray => deliveriesArray.reduce((res, cur) => {
  res[cur.serviceCode] = cur;
  return res;
}, {});
export const possibleShippingOptionsSelector = createSelector(itemSelector, shipmentChargesSelector, (item, boxShipmentPrice) => {
  const {
    shipmentCharges,
    boxShipmentCharge
  } = item;
  let possibleDeliveryOptions = [...shipmentCharges].sort((a, b) => a.totalCharges - b.totalCharges);
  if (isItemBlankSampleOrPrintedSample(item)) {
    const cheapestShipmentRate = findCheapestShipmentRate(shipmentCharges);
    possibleDeliveryOptions = [_objectSpread({}, cheapestShipmentRate, {
      upsCharge: 0,
      totalCharges: 0
    })];
  }
  if (isBox(item)) {
    possibleDeliveryOptions = [_objectSpread({}, boxShipmentCharge, {
      totalCharges: boxShipmentPrice
    })];
  }
  const deliveries = makeDeliveryOptions(possibleDeliveryOptions);
  const renamed = renameDeliveryOptions(deliveries);
  return Object.values(renamed);
});
export const additionalItemInfoSelector = createSelector(itemSelector, possibleShippingOptionsSelector, (item, possibleShippingOptions) => {
  const {
    quantity,
    inHandDate,
    ksFlow,
    isSample,
    isPrintedSample,
    serviceCode,
    boxItemsId,
    isCustomizedBlank
  } = item;
  return {
    ksFlow,
    isSample,
    quantity,
    boxItemsId,
    inHandDate,
    serviceCode,
    isPrintedSample,
    isCustomizedBlank,
    shipmentCharges: possibleShippingOptions
  };
});
const getRushedProductionItem = item => {
  return item.prodTime > 0 && !item.isSample && !item.isPrintedSample;
};
const isRushedProduction = (items = []) => {
  const itemsWithRushedProduction = items.filter(getRushedProductionItem);
  return itemsWithRushedProduction.length > 0;
};
export const isRushedProductionSelector = createSelector(cartItemsSelector, items => isRushedProduction(items));
export const isItemEditPossibleSelector = createSelector(itemSelector, item => {
  const {
    isSample,
    isPrintedSample,
    customProductId,
    boxItemsId,
    savedBoxInfo
  } = item;
  const isNotecard = isItemBoxSpecialNotecard(item);
  if (isBox(item) || savedBoxInfo || isItemBoxSpecialTypeOrAddon(item) && !isNotecard) {
    return false;
  }
  const isCrinklePaper = isItemBoxSpecialCrinklePaper(item);
  if (customProductId) {
    return !isSample && !isBox(item) && !isCrinklePaper;
  }
  if (boxItemsId) {
    if (isBox(item) && isPrintedSample || isCrinklePaper) {
      return false;
    }
  }
  return true;
});
export const orderItemInventorySelector = (state, item) => {
  const inventories = inventoriesSelector(state);
  return inventories.find(inventory => inventory._id === item.inventoryId);
};
export const deliveryToInventoryItemsSelector = createSelector(cartItemsSelector, items => items.filter(item => {
  const isOrderMore = isItemInventoryReordered(item) || isItemOriginallyInventoryReordered(item);
  return isOrderMore && (!item.boxItemsId && !isBox(item) || isBox(item));
}));
export const productsToInventorySelector = createSelector(deliveryToInventoryItemsSelector, items => {
  const inventoriesWithProducts = utils.groupItemsIntoBoxes(items).reduce((all, i) => {
    const {
      inventoryId: inventoryIdValue,
      originalInventoryId
    } = i;
    const inventoryId = inventoryIdValue || originalInventoryId;
    if (all[inventoryId]) {
      return _objectSpread({}, all, {
        [inventoryId]: _objectSpread({}, all[inventoryId], {
          products: [...all[inventoryId].products, getBreakdown(i)]
        })
      });
    }
    return _objectSpread({}, all, {
      [inventoryId]: {
        _id: inventoryId,
        products: [getBreakdown(i)]
      }
    });
  }, {});
  return Object.values(inventoriesWithProducts);
});
const itemsWithoutInventorySelector = createSelector(cartItemsSelector, items => {
  return utils.groupItemsIntoBoxes(items.filter(i => i.isReorder && !i.customProductId || !i.isReorder));
});
export const productsWithoutInventorySelector = createSelector(itemsWithoutInventorySelector, items => items.map(getBreakdown));
export const alreadyPreselectedInventorySelector = createSelector(itemsWithoutInventorySelector, items => {
  var _items$find;
  return (_items$find = items.find(({
    inventoryId
  }) => !!inventoryId)) === null || _items$find === void 0 ? void 0 : _items$find.inventoryId;
});
export const productsWithoutInventoryCountSelector = createSelector(productsWithoutInventorySelector, items => items.length);
export const isSendToCustomerAddressConfirmationShown = createSelector(productsToInventorySelector, products => Boolean(products.length));
export const planSelector = createSelector(cartRootSelector, cart => cart.plan && cart.plan._id);
export const isSetupFeeShownSelector = createSelector(setupFeeSelector, isFullDesignSelector, propsItemSelector, (setupFee, isFullDesign, item) => {
  const {
    isSample,
    isPrintedSample,
    isCustomizedBlank
  } = item;
  return Number.isFinite(setupFee) && !isFullDesign && !isSample && !isPrintedSample && !isCustomizedBlank && !isItemBoxSpecialTape(item);
});
export const shouldShowShipmentTooltipSelector = createSelector(shipmentChargesSelector, itemSelector, deliverToFulfillmentCenterSelector, (shipmentPrice, item, deliverToFulfillmentCenter) => shipmentPrice > 0 && (Boolean(item.inventoryId) || deliverToFulfillmentCenter));
export const shouldShowFreeShipmentInfoSelector = createSelector(shipmentChargesSelector, itemSelector, (shipmentPrice, item) => {
  const isSplitShippedToInventory = checkIsSplitShippedToInventory(item);
  return shipmentPrice === 0 && isSplitShippedToInventory;
});
export const cartItemsForEstimateDistributionFeeAllSelector = createSelector(cartItemsSelector, items => {
  return groupItemsAsRelatedAndDefault(items, {
    deliverToFulfillmentCenter: true
  }).items;
});
export const cartItemsForEstimateDistributionFeeSelector = createSelector(cartItemsForEstimateDistributionFeeAllSelector, items => {
  return items.reduce((acc, cartItems) => {
    return [...acc, ...cartItems.map(cartItem => {
      const isBox = Array.isArray(cartItem) && checkIsMoreThanOneItemWithSimilarKey(cartItem, 'boxItemsId');
      const item = isBox ? cartItem[0] : cartItem;
      return item;
    }).flat()];
  }, []);
});
function sumArgs(...args) {
  return args.reduce((acc, item) => acc + item);
}
export const onlyDeductionsValueSelector = createSelector(shipmentDiscountSelector, setupFeeDiscountSelector, setupFeeDeductionDiscountSelector, sampleDeductionDiscountSelector, sumArgs);
export const onlyDeductionsValueForCartSelector = createSelector(setupFeeDiscountSelector, setupFeeDeductionDiscountSelector, sampleDeductionDiscountSelector, sumArgs);
export const absoluteDiscountSelector = createSelector(absoluteDiscountValueSelector, totalBeforeDiscountSelector, onlyDeductionsValueSelector, getAbsoluteDiscount);
export const absoluteDiscountForCartSelector = createSelector(absoluteDiscountValueSelector, totalBeforeDiscountForCartSelector, onlyDeductionsValueForCartSelector, getAbsoluteDiscount);
export const allDiscountsValueSelector = createSelector(shipmentDiscountSelector, setupFeeDiscountSelector, absoluteDiscountSelector, setupFeeDeductionDiscountSelector, sampleDeductionDiscountSelector, sumArgs);
export const allDiscountsValueForCartSelector = createSelector(setupFeeDiscountSelector, absoluteDiscountForCartSelector, setupFeeDeductionDiscountSelector, sampleDeductionDiscountSelector, sumArgs);
export const orderTotalPriceForCartSelector = createSelector(totalBeforeDiscountForCartSelector, allDiscountsValueForCartSelector, (totalBeforeDiscount, discount) => {
  if (discount > totalBeforeDiscount) {
    return 0;
  }
  return totalBeforeDiscount - discount;
});
export const orderTotalPriceSelector = createSelector(totalBeforeDiscountSelector, allDiscountsValueSelector, swagSpaceOrderTotalSelector, resellerAdminModeSelector, orderTotalPrice);
export const btnTextSelector = createSelector(orderTotalPriceSelector, paymentMethodSelector, resellerAdminModeSelector, (totalPrice, paymentMethod, resellerAdminMode) => {
  if (isPresentationOrInvoiceMode(resellerAdminMode)) {
    return PROCEED_BUTTON_TEXT[resellerAdminMode];
  }
  if (!isPaymentCC(paymentMethod)) {
    return 'Place order';
  }
  return totalPrice !== 0 ? 'Add payment info' : 'Complete order';
});
export const standaloneItemsValidationsSelector = createSelector(cartItemsSelector, stockLevelsByProductIdAndVariantSkuSelector, supplierInventoryLevelsCartAndDraftSelector, (items, stockLevelsByProductIdAndVariantSku, supplierInventoryLevels) => {
  return getValidationMessagesForStandaloneItems({
    items,
    stockLevelsByProductIdAndVariantSku,
    supplierInventoryLevels,
    ignoreThreshold: true
  });
});
export const standaloneDraftItemsValidationsSelector = createSelector(draftItemsSelector, stockLevelsByProductIdAndVariantSkuSelector, supplierInventoryLevelsCartAndDraftSelector, (items, stockLevelsByProductIdAndVariantSku, supplierInventoryLevels) => {
  return getValidationMessagesForStandaloneItems({
    items,
    stockLevelsByProductIdAndVariantSku,
    supplierInventoryLevels,
    ignoreThreshold: true
  });
});
export const isConflictInStandaloneItemsSelector = createSelector(standaloneItemsValidationsSelector, conflictMessages => {
  if (_isEmpty2(conflictMessages)) {
    return false;
  }
  return Boolean(Object.values(conflictMessages).find(value => !value.isSuccessed));
});
export const isConflictInStandaloneItemsByMinQuantSelector = createSelector(standaloneItemsValidationsSelector, conflictMessages => {
  if (_isEmpty2(conflictMessages)) {
    return false;
  }
  return Boolean(Object.values(conflictMessages).find(value => value.isMinQuant));
});
export const isConflictInStandaloneItemsByMaxQuantSelector = createSelector(standaloneItemsValidationsSelector, conflictMessages => {
  if (_isEmpty2(conflictMessages)) {
    return false;
  }
  return Boolean(Object.values(conflictMessages).find(value => value.isMaxQuant));
});
export const isLEErrorLvlSelector = createSelector(standaloneItemsValidationsSelector, conflictMessages => {
  if (_isEmpty2(conflictMessages)) {
    return false;
  }
  return Object.values(conflictMessages).some(value => value.isLE);
});
export const hasSuperSpeedItemsSelector = createSelector(cartItemsSelector, items => items.some(isSuperSpeedItem));
export const cartHasForbiddenToInventoryProductsSelector = createSelector(cartItemsSelector, items => {
  return isForbiddenForInventoryItemExists(items);
});
export const isInventoryDisabledSelector = createSelector(cartHasSampleItemsSelector, cartHasForbiddenToInventoryProductsSelector, isResellerInOrderCreationModeSelector, isPreBuiltCartUserInventoryTermAcceptedSelector, (cartHasSampleItems, cartHasForbiddenToInventoryProducts, isResellerInOrderCreationMode, isPreBuiltCartUserInventoryTermAccepted) => cartHasSampleItems || cartHasForbiddenToInventoryProducts || isResellerInOrderCreationMode && !isPreBuiltCartUserInventoryTermAccepted);
const currentTotalQuantityPropsSelector = (state, props) => props.quantity;
export const upsellWidgetDataForCurrentEditingItemSelector = createSelector(cartItemToEditSelector, relatedItemsOfEditingItemSelector, sharedItemsOfEditingItemSelector, stockLevelsByProductIdAndVariantSkuSelector, setupFeeSelector, currentTotalQuantityPropsSelector, featureTogglesSelector, (item, relatedItems, sharedItems, stockLevelsByProductIdAndVariantSku, setupFee, quantity, featureFlags) => {
  const upsellData = getUpsellWidgetDataForCartItem({
    item,
    relatedItems,
    sharedItems,
    stockLevelsByProductIdAndVariantSku,
    setupFee,
    quantity,
    featureFlags
  });
  return presentUpsellWidgetForCartItem(upsellData);
});
export const relatedItemsOfCurrentItemSelector = createSelector(allItemsInCartAndDraftSelector, propsItemSelector, getAllRelatedItems);
export const sharedItemsOfCurrentItemSelector = createSelector(allItemsInCartAndDraftSelector, propsItemSelector, getAllSharedItems);
export const upsellWidgetDataForItemSelector = createSelector(propsItemSelector, relatedItemsOfCurrentItemSelector, sharedItemsOfCurrentItemSelector, stockLevelsByProductIdAndVariantSkuSelector, setupFeeSelector, cartItemsUpsellDismissedStatesSelector, featureTogglesSelector, (item, relatedItems, sharedItems, stockLevelsByProductIdAndVariantSku, setupFee, cartItemsUpsellDismissedStates, featureFlags) => {
  const dismissedStates = cartItemsUpsellDismissedStates[item._id];
  const isUpsellDismissed = dismissedStates ? dismissedStates[UPSELL_WIDGET_STATES.CHECKOUT] || dismissedStates[UPSELL_WIDGET_STATES.PRODUCT_BUILDER] && !dismissedStates[UPSELL_WIDGET_STATES.IS_QUANTITY_CHANGED] : false;
  const upsellData = getUpsellWidgetDataForCartItem({
    item,
    relatedItems,
    sharedItems,
    stockLevelsByProductIdAndVariantSku,
    setupFee,
    isUpsellDismissed,
    featureFlags
  });
  return presentUpsellWidgetForCartItem(upsellData);
});
export const stockLevelsByVariantSkuSelector = createSelector(stockLevelsByProductIdAndVariantSkuSelector, cartItemToEditSelector, (stockLevelsByProductIdAndVariantSku, item) => {
  return (stockLevelsByProductIdAndVariantSku === null || stockLevelsByProductIdAndVariantSku === void 0 ? void 0 : stockLevelsByProductIdAndVariantSku[item.prod._id]) || {};
});
function canPlaceItemToBox(item) {
  var _item$prod5;
  return !((_item$prod5 = item.prod) !== null && _item$prod5 !== void 0 && _item$prod5.forbiddenForBox) && !item.isSample && !item.isPrintedSample && !item.boxItemsId && !item.splitShippedType;
}
export const shouldShowBuildABoxButtonSelector = createSelector(cartItemsSelector, cartItems => {
  return cartItems.some(canPlaceItemToBox) && cartItems.length > 0;
});
export const alreadyHaveBoxInCartOrSaveForLaterSelector = createSelector(allItemsInCartAndDraftSelector, allItems => {
  return allItems.some(isBox);
});
export const cartBuildABoxButtonLabelSelector = createSelector(alreadyHaveBoxInCartOrSaveForLaterSelector, alreadyHaveBoxInCartOrSaveForLater => {
  return alreadyHaveBoxInCartOrSaveForLater ? 'Want to build another box?' : 'Want to build a box?';
});
export const isViewColorsShownSelector = createSelector(itemSelector, isFullDesignSelector, ({
  prod,
  variants,
  isSample,
  isPrintedSample
}, isFullDesign) => {
  const productsState = {
    products: {
      list: [prod]
    }
  };
  const isWithColoredVariants = isSingleProductColoredSelector(productsState, {
    productId: prod._id
  });
  const enoughVariants = prod.size ? variants.length >= 1 : variants.length > 1;
  return !isSample && !isPrintedSample && !isFullDesign && isWithColoredVariants && enoughVariants;
});
export const realProductNameSelector = createSelector(itemSelector, getFormattedProductNameFromItem);
export const editingItemStorageItemsSelector = createSelector((state, props) => props, cartItemsSelector, draftItemsSelector, ({
  item
}, cartItems, draftItems) => {
  const isItemFromCart = cartItems.find(innerItem => innerItem._id === item._id);
  return isItemFromCart ? cartItems : draftItems;
});
export const isRelatedItemSelector = createSelector((state, props) => props, (state, props) => editingItemStorageItemsSelector(state, props), ({
  item
}, items) => {
  return isRelatedItem(item, items, true);
});
export const isSharedOrRelatedItemSelector = createSelector((state, props) => props, (state, props) => editingItemStorageItemsSelector(state, props), ({
  item
}, items) => {
  return isSharedOrRelatedItem(item, items);
});
export const setupFeeLabelSelector = createSelector(isSharedOrRelatedItemSelector, isSharedOrRelatedItem => getSetupFeeLabel(isSharedOrRelatedItem));
export const boxesSelector = createSelector(cartItemsSelector, items => {
  return items.filter(isBox);
});
export const draftBoxesSelector = createSelector(draftItemsSelector, items => {
  return items.filter(isBox);
});
export const itemsInBoxesSelector = createSelector(cartItemsSelector, boxesSelector, (items, boxes) => {
  if (!boxes.length) {
    return [];
  }
  const boxItemsIdsMap = boxes.reduce((all, i) => _objectSpread({}, all, {
    [i.boxItemsId]: i.boxItemsId
  }), {});
  return items.filter(item => !isBox(item) && boxItemsIdsMap[item.boxItemsId]);
});
export const draftItemsInBoxesSelector = createSelector(draftItemsSelector, draftBoxesSelector, (items, boxes) => {
  if (!boxes.length) {
    return [];
  }
  const boxItemsIdsMap = boxes.reduce((all, i) => _objectSpread({}, all, {
    [i.boxItemsId]: i.boxItemsId
  }), {});
  return items.filter(item => !isBox(item) && boxItemsIdsMap[item.boxItemsId]);
});
export const itemsInBoxesConflictMessagesSelector = createSelector(cartItemsSelector, boxesSelector, stockLevelsByProductIdAndVariantSkuSelector, supplierInventoryLevelsCartAndDraftSelector, (items, boxes, stockLevelsByProductIdAndVariantSku, supplierInventoryLevels) => getValidationMessages({
  boxes,
  items,
  stockLevelsByProductIdAndVariantSku,
  supplierInventoryLevels,
  ignoreThreshold: true
}));
const isNotAllowedInBoxSelector = createSelector(itemsInBoxesConflictMessagesSelector, conflictMessages => {
  return Object.keys(conflictMessages).length > 0 && Object.values(conflictMessages).some(value => !!value.isNotAllowedInBox);
});
export const itemsValidationConflictTitleSelector = createSelector(isNotAllowedInBoxSelector, isConflictInStandaloneItemsByMaxQuantSelector, (isNotAllowedInBox, isMoreThanMaxQuant) => {
  if (isNotAllowedInBox) {
    return 'Hey, before you check out we need you to make some adjustments.';
  }
  if (isMoreThanMaxQuant) {
    return 'Quantity of products you want to purchase is more than maximum quantity.';
  }
  return 'Quantity of products you want to purchase is less than minimal quantity.';
});
export const itemsValidationConflictMessageSelector = createSelector(isNotAllowedInBoxSelector, isConflictInStandaloneItemsByMaxQuantSelector, (isNotAllowedInBox, isMoreThanMaxQuant) => {
  if (isNotAllowedInBox) {
    return 'One of the products in your cart cannot be added to a box. Please check the warning messages.';
  }
  if (isMoreThanMaxQuant) {
    return `Quantities of some products in your cart are higher than maximum quantities available
      for purchase. Please check the warning messages and adjust the quantities.`;
  }
  return `Quantities of some products in your cart are lower than minimal quantities available
      for purchase. Please check the warning messages and adjust the quantities.`;
});
export const draftItemsInBoxesConflictMessagesSelector = createSelector(draftItemsSelector, draftBoxesSelector, stockLevelsByProductIdAndVariantSkuSelector, supplierInventoryLevelsCartAndDraftSelector, (items, boxes, stockLevelsByProductIdAndVariantSku, supplierInventoryLevels) => getValidationMessages({
  boxes,
  items,
  stockLevelsByProductIdAndVariantSku,
  supplierInventoryLevels,
  ignoreThreshold: true
}));
export const globalItemsInBoxesConflictMessagesSelector = createSelector(itemsInBoxesConflictMessagesSelector, draftItemsInBoxesConflictMessagesSelector, (cartMessages, draftMessages) => _objectSpread({}, cartMessages, draftMessages));
export const isConflictInBoxesSelector = createSelector(itemsInBoxesConflictMessagesSelector, cartItemsSelector, checkIfConflictRelateToBox);
export const itemsInBoxesConflictWithoutPSMessagesSelector = createSelector(cartItemsSelector, boxesSelector, (items, boxes) => getValidationMessages({
  boxes,
  items,
  ignoreThreshold: true
}));
export const isConflictInBoxesWithoutPSSelector = createSelector(itemsInBoxesConflictWithoutPSMessagesSelector, cartItemsSelector, checkIfConflictRelateToBox);
export const standaloneItemsConflictsWithoutPSSelector = createSelector(cartItemsSelector, items => {
  return getValidationMessagesForStandaloneItems({
    items,
    ignoreThreshold: true
  });
});
export const isConflictInStandaloneItemsWithoutPSSelector = createSelector(standaloneItemsConflictsWithoutPSSelector, conflictMessages => {
  if (_isEmpty2(conflictMessages)) {
    return false;
  }
  return Boolean(Object.entries(conflictMessages).find(([, value]) => !value.isSuccessed));
});
export const isConflictWithLESelector = createSelector(itemsInBoxesConflictMessagesSelector, standaloneItemsValidationsSelector, (itemsInBoxesConflictMessages, standaloneItemsValidations) => {
  if (_isEmpty2(_objectSpread({}, itemsInBoxesConflictMessages, standaloneItemsValidations))) {
    return false;
  }
  const firstCheck = Boolean(Object.values(itemsInBoxesConflictMessages).some(v => v.isLEOOS || v.isLEExceed));
  const secondCheck = Boolean(Object.values(standaloneItemsValidations).some(v => v.isLEOOS || v.isLEExceed));
  return firstCheck || secondCheck;
});
export const isOutOfStockConflictsSelector = createSelector(isConflictWithLESelector, isConflictInBoxesSelector, isConflictInBoxesWithoutPSSelector, isConflictInStandaloneItemsSelector, isConflictInStandaloneItemsWithoutPSSelector, (isConflictWithLE, isConflictInBoxes, isConflictInBoxesWithoutPS, isConflictInStandaloneItems, isConflictInStandaloneItemsWithoutPS) => {
  return isConflictWithLE || !isConflictInBoxesWithoutPS && isConflictInBoxes || !isConflictInStandaloneItemsWithoutPS && isConflictInStandaloneItems;
});
export const includeHiddenItemsSelector = createSelector(cartItemsSelector, items => {
  return items.some(item => {
    var _item$prod6;
    if ((_item$prod6 = item.prod) !== null && _item$prod6 !== void 0 && _item$prod6.hidden) {
      return true;
    }
    return item.variants.some(variant => {
      var _item$prod7;
      const productVariant = getProdVariant({
        prodVariants: ((_item$prod7 = item.prod) === null || _item$prod7 === void 0 ? void 0 : _item$prod7.variants) || [],
        itemVariant: variant,
        itemColor: item.color
      });
      return productVariant === null || productVariant === void 0 ? void 0 : productVariant.isHidden;
    });
  });
});
export const includeSuperSpeedItemsSelector = createSelector(cartItemsSelector, items => {
  return items.some(isSuperSpeedItem);
});
export const isSuperSpeedCheckoutWarnSelector = createSelector(cartItemsSelector, includeSuperSpeedItemsSelector, specialBoxProductsSelector, (cartItems, isSuperSpeedIncluded, specialBoxProducts) => {
  if (!isSuperSpeedIncluded) {
    return false;
  }
  if (!cartItems.some(item => item.boxItemsId)) {
    return false;
  }
  const boxItemsIdsSet = new Set(cartItems.reduce((res, item) => {
    if (item.boxItemsId) {
      res.push(item.boxItemsId);
    }
    return res;
  }, []));
  let hasSuperSpeedBoxesWithAddons = false;
  boxItemsIdsSet.forEach(boxItemsId => {
    if (hasSuperSpeedBoxesWithAddons) {
      return;
    }
    const boxItself = cartItems.find(item => isBox(item) && item.boxItemsId === boxItemsId);
    if (!isBoxSuperSpeedType({
      specialBoxProducts,
      type: boxItself.prod.specialProductType
    })) {
      return false;
    }
    const boxItems = cartItems.filter(item => item.boxItemsId === boxItemsId);
    const allRegularBoxProducts = boxItems.filter(item => isItemBoxWithoutSpecialTypes(item) && !isBox(item));
    const allBoxAddonProducts = boxItems.filter(isItemBoxAddon);
    const isAllRegularBoxProductAreSuperSpeed = allRegularBoxProducts.every(isSuperSpeedItem);
    if (isAllRegularBoxProductAreSuperSpeed && allBoxAddonProducts.length) {
      hasSuperSpeedBoxesWithAddons = true;
    }
  });
  return hasSuperSpeedBoxesWithAddons;
});
export const productionTimeListForItemSelector = createSelector((_, {
  item
}) => item, (_, {
  isCart
}) => isCart, (_, {
  isCheckout
}) => !!isCheckout, draftItemsInBoxesSelector, itemsInBoxesSelector, getProductionTimeListForItem);
export const possibleProductionTimeListForItemSelector = createSelector((_, {
  item
}) => item, (_, {
  isCart
}) => isCart, (_, {
  isCheckout
}) => !!isCheckout, draftItemsInBoxesSelector, itemsInBoxesSelector, getPossibleProductionListForItem);
export const filteredProductionTimeListForItemSelector = createSelector(possibleProductionTimeListForItemSelector, isAvailableSuperSpeedSelector, (productionTimeList, isAvailableSuperSpeed) => {
  let list = productionTimeList;
  if (!isAvailableSuperSpeed) {
    list = filterSuperSpeedFromProductionTimeList(list);
  }
  return list;
});
export const productionTimeSelector = createSelector(itemSelector, ({
  prodTime,
  prod
}) => {
  var _prod$productSettings, _prod$productSettings2;
  return prod === null || prod === void 0 ? void 0 : (_prod$productSettings = prod.productSettings[0]) === null || _prod$productSettings === void 0 ? void 0 : (_prod$productSettings2 = _prod$productSettings.productionTimeList) === null || _prod$productSettings2 === void 0 ? void 0 : _prod$productSettings2[prodTime].time;
});
export const showLEWarningSelector = createSelector(cartItemsSelector, items => items.some(item => {
  var _item$prod8, _item$prod8$limitedEd;
  return !!((_item$prod8 = item.prod) !== null && _item$prod8 !== void 0 && (_item$prod8$limitedEd = _item$prod8.limitedEdition) !== null && _item$prod8$limitedEd !== void 0 && _item$prod8$limitedEd.isLimitedEdition);
}));
const showCartEmptyPageSelector = createSelector(cartPageIsLoadingSelector, isCartLoadedSelector, (isLoading, isCartLoaded) => {
  return !isCartLoaded && !isLoading;
});
export const showSuggestedProductsBeforeSFLSelector = createSelector(isSuggestedProductEmptySelector, isSavedForLaterListEmptySelector, isCartEmptySelector, showSuggestedProductsBeforeSFLSelectorFn);
export const showSuggestedProductsAfterSFLSelector = createSelector(isSuggestedProductEmptySelector, isSavedForLaterListEmptySelector, isCartEmptySelector, showSuggestedProductsAfterSFLSelectorFn);
export const cartSelector = createStructuredSelector({
  itemsCount: cartItemsCountSelector,
  isCartEmpty: isCartEmptySelector,
  isSavedForLaterListEmpty: isSavedForLaterListEmptySelector,
  isGreyRecentlyViewed: isGreyRecentlyViewedSelector,
  isLoading: cartPageIsLoadingSelector,
  showEmptyPage: showCartEmptyPageSelector,
  shouldShowWavedLoader: shouldShowWavedLoaderSelector,
  showSuggestedProductsBeforeSFL: showSuggestedProductsBeforeSFLSelector,
  showSuggestedProductsAfterSFL: showSuggestedProductsAfterSFLSelector,
  isCuratedBrandStore: isCuratedBrandStoreSelector
});
export const isReplacementOrderDisabledSelector = createSelector(cartItemsCountSelector, cartItemsSelector, (cartItemsCount, cartItems) => {
  return cartItemsCount > 1 || isBox(cartItems[0]) || isRelatedItem(cartItems[0], cartItems, true);
});
export const cartItemsWithEmptyLogosSelector = createSelector(cartItemsSelector, items => getItemsWithEmptyLogos(items));
export const isCartItemsLogoEmptySelector = createSelector(cartItemsWithEmptyLogosSelector, items => !!(items !== null && items !== void 0 && items.length));
export const cartItemsWithRemovedPrintLocationsSelector = createSelector(cartItemsSelector, items => getItemsWithRemovedPrintLocations(items));
export const isSomeItemPrintLocationRemovedSelector = createSelector(cartItemsWithRemovedPrintLocationsSelector, items => !!(items !== null && items !== void 0 && items.length));
export const shouldShowCartUnavailableShopMoreBtnSelector = createSelector(routingLocationStateSelector, routing => !!(routing !== null && routing !== void 0 && routing.shouldShowShopMoreBtn));
export const isSomeSplitShippedItemSuperSpeedSelector = createSelector(cartItemsSelector, cartItems => cartItems.some(item => checkIsSplitShipped(item) && isSuperSpeedItem(item)));
export const resellerInvoiceNumSelector = createSelector(cartRootSelector, cart => cart.resellerInvoiceNum);