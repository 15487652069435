import { createSelector } from 'reselect';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import getCanonicalUrl from '../../utils/get-canonical-url.utils';
import { getMetaTagsByPathname } from '../../utils/get-meta-tags-by-pathname.utils';
import { metaTagsSelector } from '../pages/pages.selectors';
import { pathnameSelector, tenantStoreSettingSelector } from '../common/common.selectors';
import { productSelector } from '../product/product.selectors';
import { currentCategorySelector } from '../product-listing/product-listing.selectors';
export const metaSelector = createSelector(metaTagsSelector, pathnameSelector, productSelector, tenantStoreSettingSelector, currentCategorySelector, (tags, pathname, product, tenantStoreSetting, currentCategory) => {
  const generalData = getMetaTagsByPathname(tags, APP_ROUTES.ALL_SWAG);
  const isHomePageRoute = pathname === APP_ROUTES.HOME;
  const {
    companyName
  } = tenantStoreSetting;
  if (isHomePageRoute) {
    return {
      title: generalData === null || generalData === void 0 ? void 0 : generalData.title,
      description: generalData === null || generalData === void 0 ? void 0 : generalData.description,
      canonical: getCanonicalUrl(location),
      image: generalData === null || generalData === void 0 ? void 0 : generalData.image,
      robots: '',
      type: 'page'
    };
  }
  const isProductPage = window.location.pathname.indexOf('product') !== -1;
  if (isProductPage && product && product._id) {
    const {
      name,
      logo,
      logoBranded
    } = product;
    const title = `${name.split(' ').map(word => word ? word[0].toUpperCase() + word.slice(1) : word).join(' ')} - ${companyName}`;
    return {
      title,
      description: generalData === null || generalData === void 0 ? void 0 : generalData.description,
      canonical: getCanonicalUrl(location),
      image: logoBranded || logo,
      robots: 'noindex, nofollow',
      type: 'product'
    };
  }
  const {
    name
  } = currentCategory;
  const title = name ? `${name} - ${companyName}` : generalData === null || generalData === void 0 ? void 0 : generalData.title;
  return {
    title,
    description: generalData === null || generalData === void 0 ? void 0 : generalData.description,
    canonical: getCanonicalUrl(location),
    image: generalData === null || generalData === void 0 ? void 0 : generalData.image,
    robots: 'noindex, nofollow',
    type: 'page'
  };
});