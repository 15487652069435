function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { ALL_SWAG_CATEGORY } from 'swag-client-common/constants';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { instantScrollTop } from 'swag-client-common/utils/tricks';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { PageApi } from '../../../admin/api/page.api';
import { CategoryApi } from '../../api/category.api';
import ProductApi from '../../api/product.api';
import { createFilter, isAllSwagCategory } from './product-listing.utils';
import { currentParentCategorySelector, currentCategorySelector } from './product-listing.selectors';
const PREFIX = 'PRODUCT_LISTING_';
export const GET_CATEGORIES_STARTED = `${PREFIX}GET_CATEGORIES_STARTED`;
export const GET_CATEGORIES_SUCCESS = `${PREFIX}GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_FAIL = `${PREFIX}GET_CATEGORIES_FAIL`;
export const onGetCategoriesFailure = err => ({
  type: GET_CATEGORIES_FAIL,
  err
});
export const onGetCategoriesSuccess = payload => ({
  type: GET_CATEGORIES_SUCCESS,
  payload
});
export const fetchCategories = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_CATEGORIES_STARTED
    });
  },
  fn: CategoryApi.getAll,
  success: body => onGetCategoriesSuccess(body),
  error: onGetCategoriesFailure
});
export const CHANGE_ACTIVE_SUBCATEGORY_ID = `${PREFIX}CHANGE_ACTIVE_SUBCATEGORY_ID`;
export const changeActiveSubcategoryId = activeSubcategoryId => ({
  type: CHANGE_ACTIVE_SUBCATEGORY_ID,
  payload: {
    activeSubcategoryId
  }
});
export const CHANGE_APPLIED_CATEGORY_FILTER = `${PREFIX}CHANGE_APPLIED_CATEGORY_FILTER`;
export const changeAppliedCategoryFilter = (categoryIds, includeSearchableOnly = false) => ({
  type: CHANGE_APPLIED_CATEGORY_FILTER,
  payload: {
    categoryIds,
    includeSearchableOnly
  }
});
export const CHANGE_CATEGORY = `${PREFIX}CHANGE_CATEGORY`;
export const changeCategory = ({
  category,
  subCategoryName
}) => ({
  type: CHANGE_CATEGORY,
  payload: {
    category,
    subCategoryName
  }
});
export const SET_IS_ALL_PRODUCTS_PAGE = `${PREFIX}SET_IS_ALL_PRODUCTS_PAGE`;
export const setIsAllProductsPage = ({
  isAllProductsPage,
  page,
  perpage
}) => ({
  type: SET_IS_ALL_PRODUCTS_PAGE,
  payload: {
    isAllProductsPage,
    page,
    perpage
  }
});
export const SET_SELECTED_FILTER = `${PREFIX}SET_SELECTED_FILTER`;
export const setSelectedFilter = filter => ({
  type: SET_SELECTED_FILTER,
  payload: {
    filter
  }
});
export const GET_PRODUCTS_SUCCESS = `${PREFIX}GET_PRODUCTS_SUCCESS`;
export const GET_PRODUCTS_FAIL = `${PREFIX}GET_PRODUCTS_FAIL`;
export const GET_PRODUCTS_STARTED = `${PREFIX}GET_PRODUCTS_STARTED`;
export const getProducts = createAsyncAction({
  transformData: (data = {}, getState, dispatch) => {
    let formattedFilter;
    const state = getState();
    const {
      sorting,
      appliedFilter,
      perpage,
      attributesMap
    } = state.productListing;
    const stateAppliedFilter = _objectSpread({}, appliedFilter);
    const currentParentCategory = currentParentCategorySelector(state);
    const categoryId = currentParentCategory && currentParentCategory._id;
    if (stateAppliedFilter.categoryId && stateAppliedFilter.categoryId.length) {
      const filter = stateAppliedFilter.categoryId;
      stateAppliedFilter.categoryId = filter.length < 2 ? filter : filter.filter(id => id !== categoryId);
    }

    // eslint-disable-next-line prefer-const
    formattedFilter = createFilter(stateAppliedFilter, attributesMap);
    dispatch(setSelectedFilter(_objectSpread({}, appliedFilter, {
      page: data.page || 1
    })));
    if (!!Object.keys(formattedFilter).length && !formattedFilter.categoryId || !formattedFilter.categoryId) {
      formattedFilter.categoryId = ALL_SWAG_CATEGORY;
    }
    return _objectSpread({}, data, {
      sorting,
      perpage,
      categoryId
    }, formattedFilter);
  },
  preFn: dispatch => {
    dispatch({
      type: GET_PRODUCTS_STARTED
    });
  },
  takeLatest: true,
  fn: ProductApi.getPage,
  success: ({
    products,
    page,
    lastPage,
    total,
    facets
  }) => ({
    type: GET_PRODUCTS_SUCCESS,
    products,
    page,
    lastPage,
    total,
    facets
  }),
  error: err => ({
    type: GET_PRODUCTS_FAIL,
    err,
    error: err
  })
});
export const GET_CATEGORIES_FAQ_DATA_REQUEST = `${PREFIX}GET_CATEGORIES_FAQ_DATA_REQUEST`;
export const GET_CATEGORIES_FAQ_DATA_SUCCESS = `${PREFIX}GET_CATEGORIES_FAQ_DATA_SUCCESS`;
export const GET_CATEGORIES_FAQ_DATA_FAILURE = `${PREFIX}GET_CATEGORIES_FAQ_DATA_FAILURE`;
export const getCategoriesFAQData = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_CATEGORIES_FAQ_DATA_REQUEST
    });
  },
  fn: PageApi.getPromoTexts,
  success: ({
    categories
  }) => ({
    type: GET_CATEGORIES_FAQ_DATA_SUCCESS,
    payload: categories
  }),
  error: err => ({
    type: GET_CATEGORIES_FAQ_DATA_FAILURE,
    err
  })
});
export const RESET_CATEGORIES_FAQ_DATA = `${PREFIX}RESET_CATEGORIES_FAQ_DATA`;
export const resetCategoriesFAQData = () => ({
  type: RESET_CATEGORIES_FAQ_DATA
});
export const SORTING_CHANGED = `${PREFIX}SORTING_CHANGED`;
export const sortProducts = action => {
  const {
    sorting
  } = action;
  return dispatch => {
    instantScrollTop();
    dispatch(resetProducts());
    dispatch({
      type: SORTING_CHANGED,
      sorting
    });
    dispatch(getProducts({
      page: 1
    }));
  };
};
export const RESET_PRODUCTS = `${PREFIX}RESET_PRODUCTS`;
export const resetProducts = isAllProductsPage => ({
  type: RESET_PRODUCTS,
  isAllProductsPage
});
export const APPLY_FILTER = `${PREFIX}APPLY_FILTER`;
export const applyFilter = () => (dispatch, getState) => {
  const state = getState().productListing;
  dispatch({
    type: APPLY_FILTER
  });
  instantScrollTop();
  const selectedCategoryId = state.selectedFilter.categoryId;
  if (!selectedCategoryId) {
    browserHistory.push(APP_ROUTES.ALL_SWAG);
  }
};
export const CHANGE_CHECKBOX_FILTER = `${PREFIX}CHANGE_CHECKBOX_FILTER`;
export const changeCheckboxFilter = filterData => ({
  type: CHANGE_CHECKBOX_FILTER,
  payload: filterData
});
export const CHANGE_RADIO_FILTER = `${PREFIX}CHANGE_RADIO_FILTER`;
export const changeRadioFilter = filterData => ({
  type: CHANGE_RADIO_FILTER,
  payload: filterData
});
export const CHANGE_PRICE_RANGE = `${PREFIX}CHANGE_PRICE_RANGE`;
export const changePriceRange = ({
  values
}) => ({
  type: CHANGE_PRICE_RANGE,
  payload: {
    range: values
  }
});
export const CHANGE_IS_FOR_BOX = `${PREFIX}CHANGE_IS_FOR_BOX`;
export const changeIsBox = filterData => ({
  type: CHANGE_IS_FOR_BOX,
  payload: filterData
});
export const CHANGE_IS_FOR_INVENTORY = `${PREFIX}CHANGE_IS_FOR_INVENTORY`;
export const changeIsInventory = filterData => ({
  type: CHANGE_IS_FOR_INVENTORY,
  payload: filterData
});
export const CHANGE_IS_FOR_SUPER_SPEED = `${PREFIX}CHANGE_IS_FOR_SUPER_SPEED`;
export const changeIsSuperSpeed = filterData => ({
  type: CHANGE_IS_FOR_SUPER_SPEED,
  payload: filterData
});
export const CHANGE_IS_INTERNATIONAL_SHIPPING = `${PREFIX}CHANGE_IS_INTERNATIONAL_SHIPPING`;
export const changeIsInternationalShipping = filterData => ({
  type: CHANGE_IS_INTERNATIONAL_SHIPPING,
  payload: filterData
});
export const GET_FILTER_LOOKUPS_SUCCESS = `${PREFIX}GET_FILTER_LOOKUPS_SUCCESS`;
export const GET_FILTER_LOOKUPS_ERROR = `${PREFIX}GET_FILTER_LOOKUPS_ERROR`;
export const getProductListingFilterData = createAsyncAction({
  fn: ProductApi.filter,
  success: filter => ({
    type: GET_FILTER_LOOKUPS_SUCCESS,
    payload: _objectSpread({}, filter[0])
  }),
  error: error => ({
    type: GET_FILTER_LOOKUPS_ERROR,
    error
  })
});
export const REMOVE_FILTER_ITEM = `${PREFIX}REMOVE_FILTER_ITEM`;
export const removeActiveFilter = (filterId, index) => (dispatch, getState) => {
  const state = getState().productListing;
  const filterIndex = filterId === FilterIds.categoryId && !isAllSwagCategory() ? index + 1 : index;
  dispatch({
    type: REMOVE_FILTER_ITEM,
    payload: {
      filterId,
      filterIndex
    }
  });
  if (filterId === FilterIds.categoryId && !state.appliedFilter.categoryId.length) {
    dispatch(changeActiveSubcategoryId(null));
    browserHistory.push(APP_ROUTES.ALL_SWAG);
  }
};
export const RESET_FILTER = `${PREFIX}RESET_FILTER`;
export const resetFilter = ({
  redirectAllSwag = true,
  resetCategory = true
}) => dispatch => {
  dispatch({
    type: RESET_FILTER,
    payload: {
      resetCategory
    }
  });
  if (redirectAllSwag) {
    browserHistory.push(APP_ROUTES.ALL_SWAG);
  }
};
export const autoSetCategory = () => (dispatch, getState) => {
  const state = getState();
  const currentCategory = currentCategorySelector(state);
  if (!currentCategory.parentId) {
    dispatch(changeCategory({
      category: currentCategory,
      subCategoryName: currentCategory.slug
    }));
  }
  browserHistory.replace({
    pathname: `${APP_ROUTES.COLLECTIONS}/${currentCategory.slug}`,
    search: window.location.search,
    state: {
      skipRouteHooks: true
    }
  });
};
export const RESET_PAGE_NUMBER = `${PREFIX}RESET_PAGE_NUMBER`;
export const resetPageNumber = () => ({
  type: RESET_PAGE_NUMBER
});
export const CLOSE_FILTER_POPUP = `${PREFIX}CLOSE_FILTER_POPUP`;
export const closeFilterPopup = () => ({
  type: CLOSE_FILTER_POPUP
});
export const OPEN_FILTER_POPUP = `${PREFIX}OPEN_FILTER_POPUP`;
export const openFilterPopup = () => ({
  type: OPEN_FILTER_POPUP
});
export const CLOSE_FILTER_PANEL = `${PREFIX}CLOSE_FILTER_PANEL`;
export const closeFilterPanel = () => ({
  type: CLOSE_FILTER_PANEL
});
export const SET_FILTER_VISIBILITY = `${PREFIX}SET_FILTER_VISIBILITY`;
export const setFilterVisibility = isVisible => ({
  type: SET_FILTER_VISIBILITY,
  payload: {
    isVisible
  }
});
export const SET_ANIMATION_VISIBILITY = `${PREFIX}SET_ANIMATION_VISIBILITY`;
export const setAnimationVisibility = withAnimation => ({
  type: SET_ANIMATION_VISIBILITY,
  payload: {
    withAnimation
  }
});
export const SET_INCLUDE_SEARCHABLE_ONLY = `${PREFIX}SET_INCLUDE_SEARCHABLE_ONLY`;
export const setIncludeSearchableOnly = () => ({
  type: SET_INCLUDE_SEARCHABLE_ONLY
});