import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { resellerAdminModeSelector, resellerAdminModeLabelSelector, resellerAdminModeInfoBarTextSelector } from 'swag-client-common/redux/user/user.selectors';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { RESELLER_ADMIN_MODE_EXIT_POPUP, STORE_LINK_AND_MAKE_IT_LIVE_POPUP } from 'swag-client-common/constants';
import { PreBuiltCartAction } from 'swag-common/constants/reseller.constants';
import { saveResellerPreBuiltCart } from 'swag-client-common/redux/cart/cart.actions';
import { checkIsInvoiceMode } from 'swag-common/utils/reseller/check-is-reseller-admin-mode.util';
import { shouldShowCreateCBSButtonSelector } from '../../../../../../customer/redux/curated-brand-store/curated-brand-store-other.selector.dynamic';
import { ResellerColoredFrameComponent } from './reseller-colored-frame';
const mapStateToProps = createStructuredSelector({
  resellerAdminMode: resellerAdminModeSelector,
  labelText: resellerAdminModeLabelSelector,
  infoBarText: resellerAdminModeInfoBarTextSelector,
  shouldShowCreateCBSButton: shouldShowCreateCBSButtonSelector
});
const mapDispatchToProps = dispatch => ({
  onButtonClick: resellerAdminMode => () => {
    if (checkIsInvoiceMode(resellerAdminMode)) {
      return dispatch(saveResellerPreBuiltCart({
        action: PreBuiltCartAction.Exit
      }));
    }
    dispatch(showPopup({
      popup: RESELLER_ADMIN_MODE_EXIT_POPUP
    }));
  },
  onCreateCBSClick: () => {
    dispatch(showPopup({
      popup: STORE_LINK_AND_MAKE_IT_LIVE_POPUP
    }));
  }
});
export const ResellerColoredFrame = connect(mapStateToProps, mapDispatchToProps)(ResellerColoredFrameComponent);