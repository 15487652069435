import { createSelector } from 'reselect';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { FILTER_NAMES } from 'swag-common/constants/product.constants';
const internationalShippingFacetsSelector = createSelector(state => state.searchResults.facets, facets => facets.internationalShipping || {});
export const boxAndInventorySelector = createSelector(() => {
  /* empty */
}, () => {
  /* empty */
});
export const shopCustomColorsSelector = () => ({
  secondaryColor: '#FF0000FF',
  primaryColor: '#0000FFFF'
});
export const internationalShippingSelector = createSelector(internationalShippingFacetsSelector, internationalShippingFacets => {
  const filterData = internationalShippingFacets.global || [{
    name: FilterIds.isInternationalShipping,
    id: FilterIds.isInternationalShipping,
    value: false
  }];
  return {
    filterName: 'Shipping',
    filterId: FILTER_NAMES.INTERATIONAL_SHIPPING,
    filterData
  };
});