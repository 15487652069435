import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isResellerAdminModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { SideMenu as Component } from './side-menu';
import { showBlogLinkSelector, showBuildABoxLinkSelector, showOurStoryLinkSelector, showSwagAutomationLinkSelector, showSwagDistributionLinkSelector, showTalkToUsLinkSelector, showWhatWeOfferLinkSelector } from './side-menu.selectors';
const mapStateToProps = createStructuredSelector({
  showSwagDistributionLink: showSwagDistributionLinkSelector,
  showBuildABoxLink: showBuildABoxLinkSelector,
  showSwagAutomationLink: showSwagAutomationLinkSelector,
  showTalkToUsLink: showTalkToUsLinkSelector,
  showOurStoryLink: showOurStoryLinkSelector,
  showBlogLink: showBlogLinkSelector,
  showWhatWeOfferLink: showWhatWeOfferLinkSelector,
  isResellerAdminMode: isResellerAdminModeSelector
});
export const SideMenu = connect(mapStateToProps)(Component);