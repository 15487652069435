import { connect } from 'react-redux';
import ForgotPasswordPopup from 'swag-client-common/components/popups/forgot-password-popup';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isForgotPasswordPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { resetPassword } from 'swag-client-common/redux/user/user-additional.actions';
import { SIGNUP_POPUP } from 'swag-client-common/constants';
import { checkIsRestrictedCuratedBrandStoreSelector } from '../../../redux/curated-brand-store/curated-brand-store-other.selector';
import { STATE_SUCCESS, STATE_ERROR, STATE_LOADING } from '../../../redux/redux.constants';
const mapStateToProps = state => {
  const {
    passwordResetState,
    passwordResetErrorMessage
  } = state.loginForm;
  return {
    isError: passwordResetState === STATE_ERROR,
    isSuccess: passwordResetState === STATE_SUCCESS,
    isLoading: passwordResetState === STATE_LOADING,
    errorMessage: passwordResetErrorMessage,
    isOpen: isForgotPasswordPopupShownSelector(state),
    showCloseButton: !checkIsRestrictedCuratedBrandStoreSelector()
  };
};
const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    dispatch(resetPassword(values));
  },
  onShowSignUp: () => {
    dispatch(showPopup({
      popup: SIGNUP_POPUP
    }));
  },
  onClose: () => {
    if (!checkIsRestrictedCuratedBrandStoreSelector()) {
      dispatch(hidePopup());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPopup);