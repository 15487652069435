import _debounce from "lodash/debounce";
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { mobMenuState } from 'swag-client-common/utils/constants';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { SUPER_SPEED_ERR_MSG } from 'swag-common/constants/error.const';
import { hideIntercom } from '../../utils/ui.utils';
import { changeIsShownMobMenuState } from '../pages/pages.actions';
import { getProducts, applyFilter, sortProducts, changeCheckboxFilter, changeRadioFilter, changePriceRange, resetFilter, getProductListingFilterData, resetProducts, removeActiveFilter, setFilterVisibility, closeFilterPopup, openFilterPopup, autoSetCategory, changeIsBox, changeIsInventory, changeIsSuperSpeed, changeIsInternationalShipping } from './product-listing.actions';
export const productListingDispatcher = dispatch => ({
  getProducts: ({
    categoryId,
    page
  }) => {
    dispatch(getProducts({
      categoryId,
      page
    }));
  },
  onOpenFilterPanel: () => {
    dispatch(getProductListingFilterData());
    dispatch(openFilterPopup());
  },
  onCloseFilterPanel: () => {
    dispatch(closeFilterPopup());
  },
  sortProducts: ({
    sorting,
    categoryId
  }) => {
    dispatch(sortProducts({
      sorting,
      categoryId
    }));
  },
  changeIsShownMobMenuState: isShown => {
    hideIntercom(isShown);
    dispatch(changeIsShownMobMenuState(isShown, mobMenuState.CATEGORIES));
  }
});
export const categoriesMenuDispatcher = dispatch => ({
  sortProducts: ({
    sorting,
    categoryId
  }) => {
    dispatch(sortProducts({
      sorting,
      categoryId
    }));
  }
});
export const filterItemDispatcher = dispatch => {
  const applyFilters = () => {
    dispatch(applyFilter());
    dispatch(resetProducts());
    dispatch(getProducts());
    dispatch(setFilterVisibility(true));
  };
  const debouncedApply = _debounce(applyFilters, 500);
  return {
    onChangeCheckboxFilter: (filterId, filterPropertyValue, isAttributes, categoriesIdToRemove) => {
      dispatch(changeCheckboxFilter({
        filterId,
        filterPropertyValue,
        isAttributes,
        categoriesIdToRemove
      }));
      if (filterId === FilterIds.categoryId) {
        dispatch(autoSetCategory());
      }
      applyFilters();
    },
    onChangeRadioFilter: (filterId, filterPropertyValue) => {
      dispatch(changeRadioFilter({
        filterId,
        filterPropertyValue
      }));
      applyFilters();
    },
    onChangePriceRange: (filterId, values) => {
      dispatch(changePriceRange({
        values
      }));
      debouncedApply();
    },
    onChangeBox: (filterId, isBox) => {
      dispatch(changeIsBox({
        filterId,
        isBox
      }));
      applyFilters();
    },
    onInventoryChange: (filterId, isInventory) => {
      dispatch(changeIsInventory({
        filterId,
        isInventory
      }));
      applyFilters();
    },
    onSuperSpeedChange: async (filterId, isSuperSpeed) => {
      var _payload$config;
      const {
        payload
      } = await dispatch(getSuperSpeedSettings());
      const isSuperSpeedEnabled = payload === null || payload === void 0 ? void 0 : (_payload$config = payload.config) === null || _payload$config === void 0 ? void 0 : _payload$config.enabled;
      if (isSuperSpeedEnabled || !isSuperSpeedEnabled && !isSuperSpeed) {
        dispatch(changeIsSuperSpeed({
          filterId,
          isSuperSpeed
        }));
        applyFilters();
      }
      if (!isSuperSpeedEnabled) {
        dispatch(showNotification({
          text: SUPER_SPEED_ERR_MSG
        }));
      }
    },
    onInternationalShippingChange: (filterId, isInternationalShipping) => {
      dispatch(changeIsInternationalShipping({
        filterId,
        isInternationalShipping
      }));
      applyFilters();
    }
  };
};
export const activeFiltersDispatcher = dispatch => ({
  onClearFilters: () => {
    dispatch(resetProducts());
    dispatch(resetFilter({
      redirectAllSwag: false,
      resetCategory: false
    }));
    dispatch(autoSetCategory());
    dispatch(getProducts());
    dispatch(setFilterVisibility(false));
  },
  onDeleteActiveFilter: (filterId, index) => {
    dispatch(removeActiveFilter(filterId, index));
    dispatch(autoSetCategory());
    dispatch(resetProducts());
    dispatch(getProducts());
  }
});