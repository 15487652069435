import _getFormMeta from "redux-form/es/getFormMeta";
import _formValueSelector from "redux-form/es/formValueSelector";
import { createSelector, createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getCompletedPasswordRequirements } from 'swag-client-common/utils/get-password-requirements.utils';
import { SignupPopupComponent } from 'swag-client-common/components/popups/signup-popup/signup-popup';
import { LOGIN_POPUP, ReduxFormNames } from 'swag-client-common/constants';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isSignupPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { signup } from 'swag-client-common/redux/user/user.actions';
import { MAX_PASSWORD_LENGTH, PASSWORD_REQUIREMENTS } from 'swag-common/constants/validations';
import { STATE_LOADING, STATE_ERROR } from '../../../redux/redux.constants';
import { isTOSAcceptedSelector } from '../../../redux/checkout/checkout.selectors';
import { hideError } from '../../../redux/signup-form/signup-form.actions';
import { isLimitedEditionSelector } from '../../../redux/product/product.selectors';
import { checkIsRestrictedCuratedBrandStoreSelector } from '../../../redux/curated-brand-store/curated-brand-store-other.selector';
const formStateSelector = reduxState => {
  const {
    state: formState
  } = reduxState.signupForm;
  return formState;
};
const isLoadingSelector = createSelector(formStateSelector, formState => formState === STATE_LOADING);
const errorSelector = reduxState => {
  const {
    error
  } = reduxState.signupForm;
  return error;
};
const isErrorSelector = createSelector(formStateSelector, formState => formState === STATE_ERROR);
const passwordSelector = state => _formValueSelector(ReduxFormNames.SignUpForm)(state, 'password') || '';
const completedPasswordRequirementsSelector = createSelector(passwordSelector, password => {
  return getCompletedPasswordRequirements(password);
});
const isPasswordLengthMoreThanMaxAllowedCharactersSelector = createSelector(passwordSelector, password => {
  return password.length > MAX_PASSWORD_LENGTH;
});
const isAllPasswordRequirementsCompletedSelector = createSelector(isPasswordLengthMoreThanMaxAllowedCharactersSelector, completedPasswordRequirementsSelector, (isPasswordLengthMoreThanMaxAllowedCharacters, completedPasswordRequirements) => {
  return !isPasswordLengthMoreThanMaxAllowedCharacters && completedPasswordRequirements.length === PASSWORD_REQUIREMENTS.length;
});
const isPasswordInputFocusedSelector = state => {
  var _formMeta$password;
  const formMeta = _getFormMeta(ReduxFormNames.SignUpForm)(state);
  return !!(formMeta !== null && formMeta !== void 0 && (_formMeta$password = formMeta.password) !== null && _formMeta$password !== void 0 && _formMeta$password.active);
};
const showCloseButtonSelector = () => {
  return !checkIsRestrictedCuratedBrandStoreSelector();
};
const mapStateToProps = createStructuredSelector({
  isOpen: isSignupPopupShownSelector,
  isSubmitError: isErrorSelector,
  submitErrorMessage: errorSelector,
  isLoading: isLoadingSelector,
  isBtnClickable: isTOSAcceptedSelector,
  password: passwordSelector,
  completedPasswordRequirements: completedPasswordRequirementsSelector,
  isAllPasswordRequirementsCompleted: isAllPasswordRequirementsCompletedSelector,
  isPasswordLengthMoreThanMaxAllowedCharacters: isPasswordLengthMoreThanMaxAllowedCharactersSelector,
  isPasswordInputFocused: isPasswordInputFocusedSelector,
  isLimitedEdition: isLimitedEditionSelector,
  showCloseButton: showCloseButtonSelector
});
const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    dispatch(signup(values));
  },
  onShowLogIn: () => {
    dispatch(showPopup({
      popup: LOGIN_POPUP
    }));
  },
  onClose: () => {
    if (!checkIsRestrictedCuratedBrandStoreSelector()) {
      dispatch(hideError());
      dispatch(hidePopup());
    }
  }
});
export const SignupPopup = connect(mapStateToProps, mapDispatchToProps)(SignupPopupComponent);