import { browserHistory } from 'react-router';
import { shownPopupSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { isResellerAdminLoggedAsResellerUserSelector, loggedInUserIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { ALLOWED_PAGES_CBS_RESTRICTED_LEVEL } from 'swag-common/constants/curated-brand-store.constants';
import { checkIsRestrictedCuratedBrandStoreSelector } from '../redux/curated-brand-store/curated-brand-store-other.selector';
export function isNeedToShowCBSLoginPopUp(state) {
  const shownPopup = shownPopupSelector(state);
  const isResellerAdminLoggedAsResellerUser = isResellerAdminLoggedAsResellerUserSelector(state);
  const isLoggedIn = loggedInUserIdSelector(state);
  const isRestrictedCBS = checkIsRestrictedCuratedBrandStoreSelector();
  const pathname = browserHistory.getCurrentLocation().pathname;
  const isAllowedPage = ALLOWED_PAGES_CBS_RESTRICTED_LEVEL.includes(pathname);
  return isRestrictedCBS && !shownPopup && !isLoggedIn && !isAllowedPage && !isResellerAdminLoggedAsResellerUser;
}