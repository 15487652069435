import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
export function validateOrderForInternationalShipping(order) {
  const {
    items,
    country
  } = order;
  if (country === 'US') {
    return '';
  }
  const forbiddenItems = items.reduce((acc, item) => {
    if (item !== null && item !== void 0 && item.prod && item.prod.isForbiddenToShipInternationally && !item.inventoryId && !acc.some(el => {
      var _item$prod;
      return el.productId === ((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod._id);
    })) {
      var _item$prod2;
      acc.push({
        productId: (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2._id,
        name: item.prod.name
      });
    }
    return acc;
  }, []);
  return getInternationalDeliveryErrorMsg(country, forbiddenItems);
}
export const getInternationalDeliveryErrorMsg = (country, noInternationalShippingItems) => {
  if (noInternationalShippingItems.length > 0 && country && country !== 'US') {
    const productNames = noInternationalShippingItems.map(item => item.name).join(', ');
    return `${productNames} can't be shipped outside the US. ${noInternationalShippingItems.length > 1 ? 'Please remove these products or select different ones to continue.' : 'Please remove this product or select a different one to continue.'}
    `;
  }
  return '';
};
export const checkIsCustomProductForbiddenToShipInternationally = product => {
  var _product$swagProductI;
  if (isBox(product.swagProductId)) {
    var _product$variants$0$b;
    const isSomeForbiddenBoxItems = (_product$variants$0$b = product.variants[0].boxContent) === null || _product$variants$0$b === void 0 ? void 0 : _product$variants$0$b.some(el => {
      var _el$product$swagProdu;
      return !!((_el$product$swagProdu = el.product.swagProductId) !== null && _el$product$swagProdu !== void 0 && _el$product$swagProdu.isForbiddenToShipInternationally);
    });
    return !!isSomeForbiddenBoxItems;
  }
  return !!((_product$swagProductI = product.swagProductId) !== null && _product$swagProductI !== void 0 && _product$swagProductI.isForbiddenToShipInternationally);
};
export const validateCustomProductForInternationalShipping = (country, product) => {
  if (country === 'US') {
    return '';
  }
  const isForbidden = checkIsCustomProductForbiddenToShipInternationally(product);
  if (isForbidden) {
    var _product$swagProductI2;
    if (isBox(product.swagProductId)) {
      return `Product Box ${product.name} can't be shipped outside the US.`;
    }
    return `Product ${((_product$swagProductI2 = product.swagProductId) === null || _product$swagProductI2 === void 0 ? void 0 : _product$swagProductI2.name) || ''} can't be shipped outside the US.`;
  }
  return '';
};
export const getCustomProductsForbiddenToShipInternationally = products => {
  const forbiddenItems = products.reduce((acc, item) => {
    if (isBox(item.swagProductId)) {
      var _item$variants$0$boxC;
      const isSomeForbiddenBoxItems = (_item$variants$0$boxC = item.variants[0].boxContent) === null || _item$variants$0$boxC === void 0 ? void 0 : _item$variants$0$boxC.some(el => {
        var _el$product$swagProdu2;
        return !!((_el$product$swagProdu2 = el.product.swagProductId) !== null && _el$product$swagProdu2 !== void 0 && _el$product$swagProdu2.isForbiddenToShipInternationally);
      });
      if (isSomeForbiddenBoxItems) {
        var _item$swagProductId;
        acc.push({
          productId: ((_item$swagProductId = item.swagProductId) === null || _item$swagProductId === void 0 ? void 0 : _item$swagProductId._id) || '',
          name: `Box ${item.name}`
        });
      }
    }
    if (item !== null && item !== void 0 && item.swagProductId && item.swagProductId.isForbiddenToShipInternationally && !acc.some(el => {
      var _item$swagProductId2;
      return el.productId === ((_item$swagProductId2 = item.swagProductId) === null || _item$swagProductId2 === void 0 ? void 0 : _item$swagProductId2._id);
    })) {
      var _item$swagProductId3;
      acc.push({
        productId: ((_item$swagProductId3 = item.swagProductId) === null || _item$swagProductId3 === void 0 ? void 0 : _item$swagProductId3._id) || '',
        name: item.swagProductId.name
      });
    }
    return acc;
  }, []);
  return forbiddenItems;
};
export function validateShipoutForInternationalShipping(country, products) {
  if (country === 'US') {
    return '';
  }
  const forbiddenItems = getCustomProductsForbiddenToShipInternationally(products);
  return getInternationalDeliveryErrorMsg(country, forbiddenItems);
}
export const isItemForbiddenToShipInternationally = item => {
  var _item$prod3;
  return !!((_item$prod3 = item.prod) !== null && _item$prod3 !== void 0 && _item$prod3.isForbiddenToShipInternationally);
};