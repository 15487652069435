import { createSelector } from 'reselect';
import { getSocials } from 'swag-client-common/utils/social-links.utils';
import { IS_PRODUCTION } from 'swag-common/config/constants';
import { urlReplacer } from 'swag-client-common/libs/url-modifier';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { STATE_LOADING } from '../redux.constants';
import { isCuratedBrandStoreSelector } from '../curated-brand-store/curated-brand-store-other.selector.dynamic';
export const isProductionSelector = () => IS_PRODUCTION;
export const locationSelector = state => state.routing.location || window.location;
export const showLoadingOverlaySelector = state => state.common.showLoadingOverlay;
export const commonSelector = state => state.common;
export const productLoadedSelector = state => state.product.productLoaded;
export const getProductIsLoadingSelector = state => state.product.getProductIsLoading;
export const categoriesMapSelector = state => state.productListing.categoriesMap;
export const getCategoriesStateSelector = state => state.productListing.getCategoriesState;
export const currentCategoryNameStateSelector = state => state.productListing.currentCategoryName;
export const pathnameSelector = state => {
  const location = state.routing.location || window.location;
  return urlReplacer(location === null || location === void 0 ? void 0 : location.pathname) || '';
};
export const isSearchPageSelector = createSelector(pathnameSelector, pathname => {
  return pathname.includes(APP_ROUTES.SEARCH_RESULTS);
});
export const pathSelector = createSelector(pathnameSelector, pathname => {
  // @todo. auto generated. fix this error
  // @ts-ignore
  return pathname.match(/\/[^/]*/)[0].replace('/', '');
});
export const isStorageFeesPathSelector = createSelector(pathnameSelector, pathname => {
  return pathname.includes('/storage-fees');
});
export const isGiveawayFeesPathSelector = createSelector(pathnameSelector, pathname => {
  return pathname.includes('giveaway') && pathname.includes('fees');
});
export const isHomePageSelector = createSelector(pathnameSelector, pathname => {
  return pathname === APP_ROUTES.HOME;
});
export const isProductPathSelector = createSelector(pathSelector, path => path.includes('product'));
export const isCollectionsPathSelector = createSelector(pathSelector, path => path.includes('collections'));
export const isCategoriesLoadingSelector = createSelector(getCategoriesStateSelector, getCategoriesState => getCategoriesState === STATE_LOADING);
export const isCategoriesHasLoadedSelector = createSelector(getCategoriesStateSelector, getCategoriesState => getCategoriesState !== STATE_LOADING);
export const isCurrentCategoryFoundSelector = createSelector(categoriesMapSelector, isCategoriesHasLoadedSelector, currentCategoryNameStateSelector, (categoriesMap, isCategoriesHasLoaded, currentCategoryNameState) => {
  if (isCategoriesHasLoaded) {
    return !!categoriesMap[currentCategoryNameState];
  }
  return true;
});
export const isPageFoundSelector = createSelector(isProductPathSelector, getProductIsLoadingSelector, productLoadedSelector, isCollectionsPathSelector, isCurrentCategoryFoundSelector, (isProductPath, getProductIsLoading, productHasLoaded, isCollectionsPath, isCurrentCategoryFound) => {
  if (isProductPath) {
    return !(!getProductIsLoading && !productHasLoaded);
  } else if (isCollectionsPath) {
    return isCurrentCategoryFound;
  }
  return true;
});
export const subPathSelector = createSelector(pathnameSelector, pathname =>
// @todo. auto generated. fix this error
// @ts-ignore
pathname.match(/\/[^/]*$/)[0].replace('/', ''));
export const tenantStoreSettingSelector = () => {
  var _window, _window$swag;
  return (_window = window) === null || _window === void 0 ? void 0 : (_window$swag = _window.swag) === null || _window$swag === void 0 ? void 0 : _window$swag.storeSettings;
};
export const tenantCompanyPhoneSelector = createSelector(tenantStoreSettingSelector, isCuratedBrandStoreSelector, (tenantStoreSetting, isCuratedBrandStore) => {
  if (!tenantStoreSetting) {
    return null;
  }
  const {
    companyPhone,
    companyShowPhone
  } = tenantStoreSetting;
  return isCuratedBrandStore || companyShowPhone ? companyPhone : null;
});
export const isWhatWeOfferActiveSelector = createSelector(tenantStoreSettingSelector, tenantStoreSetting => {
  return tenantStoreSetting === null || tenantStoreSetting === void 0 ? void 0 : tenantStoreSetting.isWhatWeOfferActive;
});
export const tenantSocialsSelector = createSelector(tenantStoreSettingSelector, tenantStoreSetting => {
  if (!tenantStoreSetting) {
    return null;
  }
  const {
    socials
  } = tenantStoreSetting;
  return getSocials(socials);
});
export const tenantCompanyContactInfoSelector = createSelector(tenantStoreSettingSelector, tenantStoreSetting => {
  if (!tenantStoreSetting) {
    return null;
  }
  const {
    companyName,
    companyPhone,
    companyEmail
  } = tenantStoreSetting;
  return {
    companyName,
    companyPhone,
    companyEmail
  };
});