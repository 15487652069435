function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { DEFAULT_PAYMENT_METHOD } from 'swag-common/constants/payment-methods';
import { UserPassedActions } from 'swag-common/constants/user-passed-actions.constants';
// @todo imports from non common directories are forbidden
import { FETCH_COMPANY_DEPARTMENTS_SUCCESS } from '../../../customer/redux/company-departments/company-departments.actions';
// @todo imports from non common directories are forbidden
import { PROCEED_ORDER_SUCCESS } from '../../../customer/redux/checkout/checkout.actions';
import { FETCH_PROFILE_STARTED, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAIL, LOGOUT_SUCCESS, LOGOUT_FAIL, CLIENT_LOGIN_SUCCESS, UPDATE_PROFILE_STARTED, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ERROR, CHECK_USER_EXISTS_SUCCESS, CHECK_USER_EXISTS_ERROR, UPDATE_USER_PROFILE } from './user.actions';
export const initialState = {
  isLoading: false,
  isLoggedIn: false,
  isSuccess: false,
  profileFetched: false,
  isAdminLoginAsUser: false,
  isResellerAdminLoggedAsResellerUser: false,
  adminRole: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  phoneNumber: '',
  company: '',
  passwordNotSet: false,
  emailVerified: false,
  companyEmailDomain: '',
  err: null,
  paymentMethod: DEFAULT_PAYMENT_METHOD,
  allowACHForSuperSpeedOrders: false,
  paymentMethodFrequency: '',
  unauthenticatedUserAlreadyExists: false,
  hasOwnDeletedInventories: false,
  hasInventories: false,
  inventoriesCount: 0,
  passedActions: {},
  latestOrderShipping: null,
  isCompanyOrdersAvailable: false,
  orderApprovalFlow: false,
  companyDepartments: [],
  gamification: {},
  publicApiSettings: {
    isApiAccessEnabled: false,
    apiPaymentMethod: DEFAULT_PAYMENT_METHOD,
    apiApplicationName: '',
    provider: null
  },
  isTaxExempt: false
};
export function commonUserReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_STARTED:
    case UPDATE_PROFILE_STARTED:
      {
        return _objectSpread({}, state, {
          isLoading: true,
          err: null
        });
      }
    case PROCEED_ORDER_SUCCESS:
      {
        return _objectSpread({}, state, action.body.user, {
          latestOrderShipping: action.body.latestOrderShipping
        });
      }
    case CLIENT_LOGIN_SUCCESS:
    case FETCH_PROFILE_SUCCESS:
      {
        return newUserDataReducer(state, action);
      }
    case FETCH_PROFILE_FAIL:
    case UPDATE_PROFILE_ERROR:
      {
        return _objectSpread({}, state, {
          err: action.err.message,
          isLoading: false
        });
      }
    case UPDATE_PROFILE_SUCCESS:
      {
        const {
          profile
        } = action.body;
        return _objectSpread({}, state, profile, {
          isLoading: false,
          err: null,
          isSuccess: true
        });
      }
    case UPDATE_USER_PROFILE:
      {
        const {
          payload: profile
        } = action;
        return _objectSpread({}, state, profile);
      }
    case LOGOUT_SUCCESS:
      {
        return _objectSpread({}, state, initialState);
      }
    case LOGOUT_FAIL:
      {
        return _objectSpread({}, state, {
          err: action.err.message,
          isLoading: false
        });
      }
    case FETCH_COMPANY_DEPARTMENTS_SUCCESS:
      {
        return _objectSpread({}, state, {
          companyDepartments: action.payload
        });
      }
    case CHECK_USER_EXISTS_SUCCESS:
      {
        return _objectSpread({}, state, action.payload.user, {
          unauthenticatedUserAlreadyExists: true
        });
      }
    case CHECK_USER_EXISTS_ERROR:
      {
        const {
          error
        } = action;
        return _objectSpread({}, initialState, {
          unauthenticatedUserAlreadyExists: false
        }, (error === null || error === void 0 ? void 0 : error.warehouseLocation) && {
          warehouseLocation: error.warehouseLocation
        });
      }
    case 'MARK_DEMO_INVENTORY_AS_SHOWN_SUCCESS':
      {
        return _objectSpread({}, state, {
          passedActions: _objectSpread({}, state.passedActions, {
            [UserPassedActions.demoInventoryTourShown]: true
          })
        });
      }
    default:
      {
        return state;
      }
  }
}
export function newUserDataReducer(state, action) {
  const {
    profile,
    latestOrderShipping
  } = action.body;
  return _objectSpread({}, state, profile, {
    isLoading: false,
    profileFetched: true,
    isLoggedIn: !!(profile !== null && profile !== void 0 && profile._id),
    err: null,
    latestOrderShipping
  });
}