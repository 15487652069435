import _assignIn2 from "lodash/assignIn";
import _omit from "lodash/omit";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapCartItemToRequiredFieldsForUpdate } from 'swag-common/utils/items/map-cart-item-to-required-fields.util';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { v1 } from 'uuid';
import { checkIfItemNeedCustomerMockupsRecreation } from 'swag-common/utils/items/check-if-item-need-customer-mockups-recreation';
import { getSharedItemsWithItemItself } from 'swag-common/business-logic/items/get-shared-items-with-item-itself.logic';
import { checkIsCommonValuesForSharedItemsHaveChanged } from 'swag-client-common/utils/order-item.utils';
import { isRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
import { checkIsSplitShippedToCustomer } from 'swag-common/utils/order/is-item-split-shipped';
import { isResellerInOrderCreationModeSelector, loggedInUserIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { bulkRemoveProductFromRecentlyViewed, getPreviousServiceCode, removePreviousServiceCodes } from 'swag-client-common/utils/local-storage.util';
import { isSplitShippedCouple } from 'swag-common/utils/order/is-split-shipped-couple';
import { CBS_HIDDEN_CATEGORIES_PRODUCT_COUNT } from 'swag-client-common/constants';
import { moveBoxItemsFromBox } from '../../../customer/redux/box-builder/remove-box-items';
import { allItemsInCartAndDraftSelector, cartItemsSelector, orderVersionSelector, draftVersionSelector } from '../../../customer/redux/cart/cart.selectors';
import { flatDraftItemsSelector } from '../../../customer/redux/cart/cart.selectors';
import { userIdSelector } from '../user/user.selectors';
import { flatCartItemsSelector } from '../../../customer/redux/cart/cart.selectors.no-circular';
import { totalInCategoriesSelector } from '../../../customer/redux/product-listing/product-listing.selectors';
import { isCuratedBrandStoreSelector } from '../../../customer/redux/curated-brand-store/curated-brand-store-other.selector';
import { draftTokenSelector, orderTokenSelector, isPreBuiltLimitedCartOpenedByCustomerSelector } from './cart.selectors';
import { moveItemBetweenCartAndDraft } from './move-item-between-cart-and-draft';
const DEFAULT_SHIPPING_TIME_SERVICE_CODE = '03';
const SUPER_SPEED_SHIPPING_TIME_SERVICE_CODE = '12';
const SUPERSPEED_SHIPPING_TIMES = ['01', '02', '12'];
export const addOrderToken = (data = {}, getState) => {
  const {
    cart,
    product
  } = getState();
  const orderToken = cart.orderToken;
  const inventoryId = product ? product.inventoryId : null;
  const payload = _objectSpread({}, data);
  payload.orderToken = orderToken;
  if (inventoryId) {
    payload.items = payload.items.map(i => _objectSpread({}, i, {
      inventoryId
    }));
  }
  return payload;
};
const formatItem = ({
  itemData,
  product,
  upsellDismissedStates
}) => {
  var _item$_id;
  const item = _omit(itemData, ['isDisabledBySize', 'isSampleUpdate']);
  const inventoryId = product === null || product === void 0 ? void 0 : product.inventoryId;
  if (inventoryId && !item.inventoryId && !checkIsSplitShippedToCustomer(item)) {
    item.inventoryId = inventoryId;
  }
  const id = (_item$_id = item._id) !== null && _item$_id !== void 0 ? _item$_id : item.itemId;
  if (id && upsellDismissedStates[id]) {
    item.upsellDismissedStates = upsellDismissedStates[id];
  }
  return item;
};
export const formatCart = (data, getState) => {
  const {
    items,
    itemsWithModifiedLogo = {},
    showDebossedPopup,
    itemId
  } = data;
  const state = getState();
  const {
    product,
    cart: {
      upsellDismissedStates,
      orderToken,
      orderVersion,
      draftVersion
    }
  } = state;
  return {
    userId: loggedInUserIdSelector(state),
    orderToken,
    orderVersion,
    draftVersion,
    itemsWithModifiedLogo,
    showDebossedPopup,
    itemId,
    items: items.map(itemData => {
      var _itemData$_id, _itemData$upsellDismi;
      const id = (_itemData$_id = itemData._id) !== null && _itemData$_id !== void 0 ? _itemData$_id : itemData.itemId;
      return formatItem({
        itemData,
        product,
        upsellDismissedStates: _objectSpread({}, upsellDismissedStates, {
          [id]: (_itemData$upsellDismi = itemData.upsellDismissedStates) !== null && _itemData$upsellDismi !== void 0 ? _itemData$upsellDismi : upsellDismissedStates[id]
        })
      });
    })
  };
};
const formatDraft = (data, getState) => {
  const {
    draftItems
  } = data;
  const state = getState();
  const {
    cart: {
      upsellDismissedStates,
      draftToken,
      orderVersion,
      draftVersion
    }
  } = state;
  return {
    userId: loggedInUserIdSelector(state),
    draftToken,
    orderVersion,
    draftVersion,
    draftItems: draftItems.map(itemData => formatItem({
      itemData,
      upsellDismissedStates
    }))
  };
};
export function getValuesToEditForSharedItems({
  editedItem,
  editedItemIsVariantDependentPrice,
  editedItemIsSizeDependentPrice,
  itemToMerge,
  newAsRelatedItemId
}) {
  const result = {
    variantInd: editedItem.variantInd,
    colors: editedItem.colors,
    embellishmentMethod: editedItem.embellishmentMethod,
    isCustomizedBlank: editedItem.isCustomizedBlank,
    sizeInd: editedItem.sizeInd,
    prodTime: editedItem.prodTime,
    logos: editedItem.logos,
    blendedQuantity: editedItem.blendedQuantity,
    blendedPrice: editedItem.blendedPrice,
    setupFeeDeductionDiscount: editedItem.setupFeeDeductionDiscount,
    isReorder: editedItem.isReorder
  };
  if (editedItemIsVariantDependentPrice) {
    result.variantInd = editedItem.variantInd;
    result.variants = [{
      variantIndex: editedItem.variantInd || 0,
      quantities: itemToMerge.variants[0].quantities
    }];
  }
  if (editedItemIsSizeDependentPrice) {
    var _itemToMerge$variants;
    const quantity = (_itemToMerge$variants = itemToMerge.variants[0].quantities.find(q => q > 0)) !== null && _itemToMerge$variants !== void 0 ? _itemToMerge$variants : 0;
    result.variants = [{
      variantIndex: itemToMerge.variants[0].variantIndex,
      quantities: itemToMerge.variants[0].quantities.map((q, position) => position === editedItem.sizeInd && quantity ? quantity : 0)
    }];
  }
  if (newAsRelatedItemId) {
    result.asRelatedItemId = newAsRelatedItemId;
  }
  return result;
}
export function getEditedItemWithProd(editedItems, allItems, editedItemId) {
  const idToUse = editedItemId !== null && editedItemId !== void 0 ? editedItemId : editedItems[0]._id;
  const itemInCart = allItems.find(i => i._id === idToUse);
  const editedItem = editedItems.find(i => i._id === idToUse);
  return _objectSpread({}, itemInCart, editedItem);
}
export const formatCartEditItems = (data, getState) => {
  const mergedItems = {};
  const itemsWithModifiedLogo = {};
  const {
    items,
    deletedOrderItems = {},
    editedItemId,
    isLogoDimensionsModified,
    showDebossedPopup
  } = data;
  const state = getState();
  const cartItems = cartItemsSelector(state);
  const allItems = allItemsInCartAndDraftSelector(state);
  const editedItem = getEditedItemWithProd(items, allItems, editedItemId || '');
  const isSharedItemWasChangedAcrossCartAndDraft = checkIsSharedItemWasChangedAcrossCartAndDraft(editedItem, allItems, isLogoDimensionsModified);
  const itemsToProcess = [...cartItems, ...items.filter(i => i.quantity !== 0)];
  let newAsRelatedItemId;
  if (isSharedItemWasChangedAcrossCartAndDraft) {
    newAsRelatedItemId = v1();
  }
  itemsToProcess.forEach(item => {
    let itemToMerge = _objectSpread({}, item);
    const isCouple = isSplitShippedCouple(editedItem, item);
    if (editedItem && (isSharedItem(editedItem, [item]) || isCouple)) {
      var _editedItem$prod, _editedItem$prod2;
      _extends(itemToMerge, getValuesToEditForSharedItems({
        editedItem,
        editedItemIsVariantDependentPrice: !!(editedItem !== null && editedItem !== void 0 && (_editedItem$prod = editedItem.prod) !== null && _editedItem$prod !== void 0 && _editedItem$prod.variantDependentPrice),
        editedItemIsSizeDependentPrice: !!(editedItem !== null && editedItem !== void 0 && (_editedItem$prod2 = editedItem.prod) !== null && _editedItem$prod2 !== void 0 && _editedItem$prod2.sizeDependentPrice),
        itemToMerge,
        newAsRelatedItemId
      }));
      if (isLogoDimensionsModified) {
        itemsWithModifiedLogo[itemToMerge._id] = true;
      }
    }
    if ((editedItem === null || editedItem === void 0 ? void 0 : editedItem._id) === (item === null || item === void 0 ? void 0 : item._id) && newAsRelatedItemId) {
      itemToMerge.asRelatedItemId = newAsRelatedItemId;
    }

    // there no opportunity to edit printed sample design, resetting isPrintedSample in this case
    if ((editedItem === null || editedItem === void 0 ? void 0 : editedItem._id) === (item === null || item === void 0 ? void 0 : item._id) && editedItem.isPrintedSample) {
      itemToMerge.isPrintedSample = false;
    }
    const deletedItemsIds = Object.keys(deletedOrderItems);
    if (!deletedItemsIds.includes(item._id)) {
      const oldItem = cartItems.find(i => i._id === item._id);
      if (mergedItems[item._id]) {
        itemToMerge = _assignIn2(mergedItems[item._id], itemToMerge);
      }
      mergedItems[item._id] = mapCartItemToRequiredFieldsForUpdate(itemToMerge);
      if (!checkIfItemNeedCustomerMockupsRecreation(oldItem, itemToMerge)) {
        mergedItems[item._id].customerMockups = oldItem.customerMockups;
      }
    }
  });
  return formatCart({
    items: Object.values(mergedItems),
    itemsWithModifiedLogo,
    showDebossedPopup,
    itemId: editedItemId
  }, getState);
};
const formatRemoveItem = ({
  id,
  ids,
  isRemoveFromBox = false,
  items
}) => {
  const newItems = isRemoveFromBox ? moveBoxItemsFromBox({
    cartItems: items,
    itemsDeleteFromCartIds: [id || ''],
    newBoxItems: []
  }) : items.filter(i => ids ? !ids.includes(i._id) : i._id !== id);
  const newItemsIds = newItems.map(i => i._id);
  const itemsToDelete = items.filter(i => !newItemsIds.includes(i._id));
  removePreviousServiceCodes(itemsToDelete);
  const relatedOrSharedItemsForItemsToDelete = itemsToDelete.map(itemToDelete => newItems.filter(newItem => {
    const isShared = isSharedItem(newItem, [itemToDelete]);
    const isRelated = isRelatedItem(newItem, [itemToDelete], true);
    return isShared || isRelated;
  }));
  relatedOrSharedItemsForItemsToDelete.forEach(relatedOrSharedItems => {
    relatedOrSharedItems.forEach(relatedOrSharedItem => {
      const newRelatedOrSharedItemIndex = newItems.findIndex(newItem => newItem._id === relatedOrSharedItem._id);
      newItems[newRelatedOrSharedItemIndex].blendedQuantity = relatedOrSharedItems.reduce((acc, i) => acc + i.quantity, 0);
    });
  });
  return newItems;
};
export const formatCartRemoveItem = (data, getState) => {
  const {
    cart: {
      items
    }
  } = getState();
  const {
    id,
    ids,
    isRemoveFromBox
  } = data;
  const newItems = formatRemoveItem({
    id,
    ids,
    isRemoveFromBox,
    items
  });
  return formatCart({
    items: newItems
  }, getState);
};
export const formatDraftRemoveItem = (data, getState) => {
  const {
    cart: {
      draftItems
    }
  } = getState();
  const {
    id,
    isRemoveFromBox
  } = data;
  const newDraftItems = formatRemoveItem({
    id,
    isRemoveFromBox,
    items: draftItems
  });
  return formatDraft({
    draftItems: newDraftItems
  }, getState);
};
export const addDraftToken = (data = {}, getState) => {
  const {
    draftToken
  } = getState().cart;
  const payload = _objectSpread({}, data);
  payload.draftToken = draftToken;
  return payload;
};
export const addOrderAndDraftTokens = (data = {}, getState) => {
  var _payload$items;
  const state = getState();
  const {
    orderToken,
    draftToken
  } = state.cart;
  const payload = _objectSpread({}, data);
  payload.items = (_payload$items = payload.items) === null || _payload$items === void 0 ? void 0 : _payload$items.map(item => {
    if (!item.embellishmentMethod) {
      return item;
    }
    item.embellishmentMethod = item.embellishmentMethod._id;
    return item;
  });
  payload.draftToken = draftToken;
  payload.orderToken = orderToken;
  return payload;
};
export const getStandaloneRelatedItemsFromCart = mainItem => {
  const {
    boxItemsId,
    asRelatedItemId,
    _id
  } = mainItem;
  return i => !i.boxItemsId && !boxItemsId && asRelatedItemId === i.asRelatedItemId && _id !== i._id && !i.splitShippedType;
};
export const getStandaloneRelatedItemBasedOnCartSourceItem = (source, item) => {
  return isSharedItem(source, [item]) && !source.boxItemsId;
};
export const checkIsSharedItemExistsOnlyInBox = (cartItem, item, sourceOrderItems) => {
  const standaloneItems = sourceOrderItems.filter(source => getStandaloneRelatedItemBasedOnCartSourceItem(source, item));
  const isShared = cartItem ? Boolean(cartItem.asRelatedItemId === item.asRelatedItemId && item.boxItemsId) : Boolean(item.asRelatedItemId && item.boxItemsId);
  const isNoStandaloneItemsInStorage = standaloneItems.length === 0;
  return isShared && isNoStandaloneItemsInStorage;
};
export const transformDataForMoveItemsBetweenOrders = direction => (data, getState) => {
  const state = getState();
  const orderToken = orderTokenSelector(state);
  const draftToken = draftTokenSelector(state);
  const oldCartItems = flatCartItemsSelector(state);
  const oldDraftItems = flatDraftItemsSelector(state);
  const orderVersion = orderVersionSelector(state);
  const draftVersion = draftVersionSelector(state);
  const {
    cartItems,
    draftItems
  } = moveItemBetweenCartAndDraft({
    cartItems: oldCartItems,
    draftItems: oldDraftItems,
    itemId: data.itemId,
    direction
  });
  return {
    items: cartItems,
    orderToken,
    draftItems,
    draftToken,
    orderVersion,
    draftVersion,
    isTransferBetweenCartAndDraft: true,
    userId: loggedInUserIdSelector(state)
  };
};
export function checkIsSharedItemWasChangedAcrossCartAndDraft(editedItem, items, isLogoDimensionsModified) {
  if (!editedItem) {
    return false;
  }
  const sharedItems = getSharedItemsWithItemItself(editedItem, items);
  const isSharedItem = sharedItems.length > 1;
  if (!isSharedItem) {
    return false;
  }
  return sharedItems.some(sharedItem => checkIsCommonValuesForSharedItemsHaveChanged(editedItem, sharedItem, isLogoDimensionsModified));
}
export const removeRecentlyViewedForGuests = (getState, res) => {
  const state = getState();
  const userId = userIdSelector(state);
  if (!userId) {
    var _res$order$items, _res$order;
    const itemsIds = ((_res$order$items = res === null || res === void 0 ? void 0 : (_res$order = res.order) === null || _res$order === void 0 ? void 0 : _res$order.items) !== null && _res$order$items !== void 0 ? _res$order$items : []).map(({
      prodId
    }) => String(prodId));
    bulkRemoveProductFromRecentlyViewed(itemsIds);
  }
};
export const changeServiceCode = (isChangedToSuperSpeedProdTime, item, newServiceCode) => {
  const previousServiceCode = getPreviousServiceCode(item._id);
  if (previousServiceCode) {
    if (isChangedToSuperSpeedProdTime && !SUPERSPEED_SHIPPING_TIMES.includes(previousServiceCode)) {
      newServiceCode = SUPER_SPEED_SHIPPING_TIME_SERVICE_CODE;
    } else if (!isChangedToSuperSpeedProdTime) {
      newServiceCode = previousServiceCode;
    }
  } else {
    if (!isChangedToSuperSpeedProdTime && item.serviceCode !== DEFAULT_SHIPPING_TIME_SERVICE_CODE) {
      newServiceCode = DEFAULT_SHIPPING_TIME_SERVICE_CODE;
    } else if (isChangedToSuperSpeedProdTime && !SUPERSPEED_SHIPPING_TIMES.includes(item.serviceCode)) {
      newServiceCode = SUPER_SPEED_SHIPPING_TIME_SERVICE_CODE;
    }
  }
  return newServiceCode;
};
export const hideForCustomerWithPreBuiltLimitedCart = state => !isPreBuiltLimitedCartOpenedByCustomerSelector(state);
export const hideForLimitedBuyingCartView = state => {
  return !isPreBuiltLimitedCartOpenedByCustomerSelector(state) && !isResellerInOrderCreationModeSelector(state);
};
export const hideForCBSWithLessThen12Products = state => {
  const totalInCategories = totalInCategoriesSelector(state);
  const isCBS = isCuratedBrandStoreSelector();
  return isCBS && totalInCategories <= CBS_HIDDEN_CATEGORIES_PRODUCT_COUNT;
};
export const getLogosAndLayersFromCBSProductLogos = itemLogos => {
  let logos = {};
  const layers = {};
  if (itemLogos) {
    logos = Object.keys(itemLogos).reduce((acc, key) => {
      const id = itemLogos[key][0].id;
      layers[key] = [id];
      acc[id] = itemLogos[key][0];
      return acc;
    }, {});
  }
  return {
    logos,
    layers
  };
};