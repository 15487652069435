import { ResellerAdminMode } from 'swag-common/constants/reseller.constants';
export function createModeChecker(modes) {
  return mode => {
    if (!mode) {
      return false;
    }
    return modes.includes(mode);
  };
}
export const checkIsResellerAdminMode = createModeChecker([ResellerAdminMode.CreatingCart, ResellerAdminMode.EditingCart, ResellerAdminMode.CreatingPresentation, ResellerAdminMode.EditingPresentation, ResellerAdminMode.CreatingInvoice, ResellerAdminMode.EditingInvoice, ResellerAdminMode.OrderCreation, ResellerAdminMode.CreatingCBS, ResellerAdminMode.EditingCBS]);
export const checkIsPreBuiltCartMode = createModeChecker([ResellerAdminMode.CreatingCart, ResellerAdminMode.EditingCart]);
export const checkIsPresentationMode = createModeChecker([ResellerAdminMode.CreatingPresentation, ResellerAdminMode.EditingPresentation]);
export const checkIsInvoiceMode = createModeChecker([ResellerAdminMode.CreatingInvoice, ResellerAdminMode.EditingInvoice]);
export const checkIsCBSMode = createModeChecker([ResellerAdminMode.CreatingCBS, ResellerAdminMode.EditingCBS]);
export const checkIsCBSCreationMode = createModeChecker([ResellerAdminMode.CreatingCBS]);
export const checkIsCBSEditingMode = createModeChecker([ResellerAdminMode.EditingCBS]);
export const checkIsOrderCreationMode = createModeChecker([ResellerAdminMode.OrderCreation]);
export const checkIsInvoiceOrOrderMode = createModeChecker([ResellerAdminMode.CreatingInvoice, ResellerAdminMode.EditingInvoice, ResellerAdminMode.OrderCreation]);
export const isPresentationOrInvoiceMode = mode => checkIsPresentationMode(mode) || checkIsInvoiceMode(mode);