import { ResellerApi } from 'swag-client-common/api/reseller.api';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { browserHistory } from 'react-router';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { loadingTurnOff } from '../common/common.actions';
const PREFIX = 'CURATED_BRAND_STORE_';
export const ADD_CBS_PRODUCT_REQUEST = `${PREFIX}ADD_CBS_PRODUCT_REQUEST`;
export const ADD_CBS_PRODUCT_SUCCESS = `${PREFIX}ADD_CBS_PRODUCT_SUCCESS`;
export const ADD_CBS_PRODUCT_FAILURE = `${PREFIX}ADD_CBS_PRODUCT_FAILURE`;
export const GET_CBS_PRODUCTS_REQUEST = `${PREFIX}GET_CBS_PRODUCTS_REQUEST`;
export const GET_CBS_PRODUCTS_SUCCESS = `${PREFIX}GET_CBS_PRODUCTS_SUCCESS`;
export const GET_CBS_PRODUCTS_FAILURE = `${PREFIX}GET_CBS_PRODUCTS_FAILURE`;
export const DELETE_CBS_PRODUCT_REQUEST = `${PREFIX}DELETE_CBS_PRODUCT_REQUEST`;
export const DELETE_CBS_PRODUCT_SUCCESS = `${PREFIX}DELETE_CBS_PRODUCT_SUCCESS`;
export const DELETE_CBS_PRODUCT_FAILURE = `${PREFIX}DELETE_CBS_PRODUCT_FAILURE`;
export const EDIT_CBS_PRODUCT_REQUEST = `${PREFIX}EDIT_CBS_PRODUCT_REQUEST`;
export const EDIT_CBS_PRODUCT_SUCCESS = `${PREFIX}EDIT_CBS_PRODUCT_SUCCESS`;
export const EDIT_CBS_PRODUCT_FAILURE = `${PREFIX}EDIT_CBS_PRODUCT_FAILURE`;
export const REBUILD_CBS_PRODUCT = `${PREFIX}REBUILD_CBS_PRODUCT`;
export const addCBSProduct = createAsyncAction({
  preFn: dispatch => dispatch({
    type: ADD_CBS_PRODUCT_REQUEST
  }),
  transformData: ({
    item
  }) => ({
    item
  }),
  fn: ResellerApi.addCBSProduct,
  success: () => ({
    type: ADD_CBS_PRODUCT_SUCCESS
  }),
  postSuccess: dispatch => {
    dispatch(loadingTurnOff());
    dispatch(getCBSProducts());
    browserHistory.push(APP_ROUTES.ALL_SWAG);
  },
  error: errorAction(ADD_CBS_PRODUCT_FAILURE)
});
export const editCBSProduct = createAsyncAction({
  preFn: dispatch => dispatch({
    type: EDIT_CBS_PRODUCT_REQUEST
  }),
  fn: ResellerApi.editCBSProduct,
  success: () => ({
    type: EDIT_CBS_PRODUCT_SUCCESS
  }),
  postSuccess: dispatch => {
    dispatch(loadingTurnOff());
    dispatch(getCBSProducts());
    browserHistory.push(APP_ROUTES.ALL_SWAG);
  },
  error: errorAction(EDIT_CBS_PRODUCT_FAILURE)
});
export const getCBSProducts = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_CBS_PRODUCTS_REQUEST
  }),
  fn: ResellerApi.getCBSProducts,
  success: payload => ({
    type: GET_CBS_PRODUCTS_SUCCESS,
    payload
  }),
  error: errorAction(GET_CBS_PRODUCTS_FAILURE)
});
export const deleteBSProduct = createAsyncAction({
  preFn: dispatch => dispatch({
    type: DELETE_CBS_PRODUCT_REQUEST
  }),
  fn: ResellerApi.deleteCBSProduct,
  success: payload => ({
    type: DELETE_CBS_PRODUCT_SUCCESS,
    payload
  }),
  error: errorAction(DELETE_CBS_PRODUCT_FAILURE)
});
export const rebuildCBSProduct = product => dispatch => {
  window.onbeforeunload = null;
  browserHistory.push(`${APP_ROUTES.PRODUCT}/${product.prodId}?cbsProductId=${product._id}`);
  dispatch({
    type: REBUILD_CBS_PRODUCT,
    payload: product
  });
};