import { browserHistory } from 'react-router';
import { ResellerApi } from 'swag-client-common/api/reseller.api';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { RESELLER_ADMIN_CREATE_INVOICE_POPUP } from 'swag-client-common/constants';
import { saveResellerPreBuiltCart } from 'swag-client-common/redux/cart/cart.actions';
import { PreBuiltCartAction } from 'swag-common/constants/reseller.constants';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { SIGN_INVENTORY_CONTRACT_TOKEN_KEY } from 'swag-client-common/constants/local-storage-keys';
import { cartOrderDiscountsSelector } from '../cart/cart.selectors';
import { selectedDistributionAreaSelector } from '../inventory/inventory.selectors';
import { invoiceLineItemsInitialSelector, invoiceSavePayloadSelector } from './reseller-invoice.selectors';
const PREFIX = 'RESELLER_INVOICE_';
export const SET_INITIAL_VALUES = `${PREFIX}SET_INITIAL_VALUES`;
export const setInvoiceInitialValues = () => (dispatch, getState) => {
  const state = getState();
  const initialLineItems = invoiceLineItemsInitialSelector(state);
  const initialDiscounts = cartOrderDiscountsSelector(state);
  return dispatch({
    type: SET_INITIAL_VALUES,
    payload: {
      lineItems: initialLineItems,
      discountData: initialDiscounts
    }
  });
};
export const SET_INVOICE_DISCOUNT_VALUE = `${PREFIX}SET_FORM_VALUE`;
export const setInvoiceDiscountValue = ({
  name,
  value
}) => ({
  type: SET_INVOICE_DISCOUNT_VALUE,
  payload: {
    name,
    value
  }
});
export const SET_PAYMENT_TERM_VALUE = `${PREFIX}SET_PAYMENT_TERM_VALUE`;
export const setPaymentTerm = ({
  value
}) => ({
  type: SET_PAYMENT_TERM_VALUE,
  payload: value
});
export const SET_LINE_ITEM_VALUE = `${PREFIX}SET_LINE_ITEM_VALUE`;
export const setLineItemValue = ({
  id,
  value,
  name
}) => ({
  type: SET_LINE_ITEM_VALUE,
  payload: {
    id,
    name,
    value
  }
});
export const SAVE_INVOICE_REQUEST = `${PREFIX}SAVE_INVOICE_REQUEST`;
export const SAVE_INVOICE_SUCCESS = `${PREFIX}SAVE_INVOICE_SUCCESS`;
export const SAVE_INVOICE_ERROR = `${PREFIX}SAVE_INVOICE_ERROR`;
export const saveResellerInvoice = createAsyncAction({
  preFn: dispatch => dispatch({
    type: SAVE_INVOICE_REQUEST
  }),
  transformData: (_, getState) => {
    const state = getState();
    return invoiceSavePayloadSelector(state);
  },
  fn: ResellerApi.saveInvoice,
  success: () => ({
    type: SAVE_INVOICE_SUCCESS
  }),
  error: error => ({
    type: SAVE_INVOICE_ERROR,
    payload: {
      error
    },
    error
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(saveResellerPreBuiltCart({
      action: PreBuiltCartAction.Save
    }));
  }
});
export const GET_RESELLER_COMMISSION_REQUEST = `${PREFIX}GET_RESELLER_COMMISSION_REQUEST`;
export const GET_RESELLER_COMMISSION_SUCCESS = `${PREFIX}GET_RESELLER_COMMISSION_SUCCESS`;
export const GET_RESELLER_COMMISSION_ERROR = `${PREFIX}GET_RESELLER_COMMISSION_ERROR`;
export const getResellerCommission = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_RESELLER_COMMISSION_REQUEST
  }),
  fn: ResellerApi.getCommission,
  success: payload => ({
    type: GET_RESELLER_COMMISSION_SUCCESS,
    payload
  }),
  error: error => ({
    type: GET_RESELLER_COMMISSION_ERROR,
    payload: {
      error
    },
    error
  }),
  postSuccess: dispatch => {
    dispatch(setInvoiceInitialValues());
    dispatch(showPopup({
      popup: RESELLER_ADMIN_CREATE_INVOICE_POPUP
    }));
  }
});
export const GET_RESELLER_INVENTORY_CONTRACT_REQUEST = `${PREFIX}GET_RESELLER_INVENTORY_CONTRACT_REQUEST`;
export const GET_RESELLER_INVENTORY_CONTRACT_SUCCESS = `${PREFIX}GET_RESELLER_INVENTORY_CONTRACT_SUCCESS`;
export const GET_RESELLER_INVENTORY_CONTRACT_ERROR = `${PREFIX}GET_RESELLER_INVENTORY_CONTRACT_ERROR`;
export const getResellerInventoryContractDetails = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_RESELLER_INVENTORY_CONTRACT_REQUEST
  }),
  fn: ResellerApi.getContractDetails,
  success: payload => ({
    type: GET_RESELLER_INVENTORY_CONTRACT_SUCCESS,
    payload
  }),
  error: error => ({
    type: GET_RESELLER_INVENTORY_CONTRACT_ERROR,
    payload: {
      error
    },
    error
  }),
  postError: () => browserHistory.replace(APP_ROUTES.NOT_FOUND)
});
export const SIGN_INVENTORY_CONTRACT_REQUEST = `${PREFIX}SIGN_INVENTORY_CONTRACT_REQUEST`;
export const SIGN_INVENTORY_CONTRACT_SUCCESS = `${PREFIX}SIGN_INVENTORY_CONTRACT_SUCCESS`;
export const SIGN_INVENTORY_CONTRACT_ERROR = `${PREFIX}SIGN_INVENTORY_CONTRACT_ERROR`;
export const signInventoryContract = createAsyncAction({
  preFn: dispatch => dispatch({
    type: SIGN_INVENTORY_CONTRACT_REQUEST
  }),
  fn: ResellerApi.signContract,
  transformData: (_, getState) => {
    const token = localStorage.getItem(SIGN_INVENTORY_CONTRACT_TOKEN_KEY);
    const distributionArea = selectedDistributionAreaSelector(getState());
    return {
      token,
      distributionArea
    };
  },
  success: payload => ({
    type: SIGN_INVENTORY_CONTRACT_SUCCESS,
    payload
  }),
  postSuccess: () => localStorage.removeItem(SIGN_INVENTORY_CONTRACT_TOKEN_KEY),
  error: error => ({
    type: SIGN_INVENTORY_CONTRACT_ERROR,
    payload: {
      error
    },
    error
  })
});