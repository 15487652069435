import { CHANGE_SIZE_INDEX, CHANGE_QUANTITIES, FETCH_PRODUCT_SUCCESS, CHANGE_METHOD_ID } from '../product/product.actions';
import { ADD_LOGO, UPDATE_LOGO_DETAILS } from '../logo/logo.actions';
import { prefillData } from './instant-quote.actions';
import { prefilledDataSelector } from './instant-quote.selectors';
export default function ({
  dispatch,
  getState
}) {
  return next => action => {
    next(action);
    switch (action.type) {
      case FETCH_PRODUCT_SUCCESS:
      case CHANGE_QUANTITIES:
      case CHANGE_SIZE_INDEX:
      case ADD_LOGO:
      case UPDATE_LOGO_DETAILS:
      case CHANGE_METHOD_ID:
        {
          const state = getState();
          const prefilledData = prefilledDataSelector(state);
          dispatch(prefillData(prefilledData));
          break;
        }
      default:
    }
  };
}