import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isMinimumRequiredCBSPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { MinimumRequiredCBSPopup as Component } from './minimum-required-cbs';
const mapStateToProps = createStructuredSelector({
  open: isMinimumRequiredCBSPopupShownSelector
});
const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(hidePopup());
  }
});
export const MinimumRequiredCBSPopup = connect(mapStateToProps, mapDispatchToProps)(Component);