import { isCuratedBrandStoreSelector } from '../../../../customer/redux/curated-brand-store/curated-brand-store-other.selector.dynamic';
export const showSwagDistributionLinkSelector = () => false;
export const showBuildABoxLinkSelector = () => false;
export const showSwagAutomationLinkSelector = () => false;
export const showTalkToUsLinkSelector = () => false;
export const showOurStoryLinkSelector = () => false;
export const showBlogLinkSelector = () => false;
export const showWhatWeOfferLinkSelector = () => {
  var _window$swag$storeSet;
  return !isCuratedBrandStoreSelector() && ((_window$swag$storeSet = window.swag.storeSettings) === null || _window$swag$storeSet === void 0 ? void 0 : _window$swag$storeSet.isWhatWeOfferActive);
};