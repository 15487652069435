function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { v1 } from 'uuid';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { OrderStorageTypes, ALL_SWAG_CATEGORY } from 'swag-common/constants/main-swag.constants';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { getEditDraftPayload, moveItemToDraft, addItemsToCart, replaceItem, replaceItemInDraft } from 'swag-client-common/redux/cart/cart.actions';
import { selectResellerInCBSMode, userSelector } from 'swag-client-common/redux/user/user.selectors';
import { DesignUploadMode } from 'swag-common/interfaces';
import { LOGIN_FOR_SAVE_A_DESIGN, LOGIN_POPUP, REMOVE_RELATED_ITEM_WARNING_POPUP, SHOW_ERROR_POPUP, SUPER_SPEED_ERROR_TYPE, ADD_TO_CART_WARNING_POPUP, EMBELLISHMENT_METHOD_CHANGE_CONFIRMATION_POPUP } from 'swag-client-common/constants';
import { PRODUCT_BUILDER_MODES } from 'swag-client-common/utils/constants';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { showPopup, hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import applyQuantityToSingleVariant from 'swag-common/utils/product/apply-quantity-to-variant.util';
import applyQuantityToProduct from 'swag-common/utils/product/apply-quantity-to-product.util';
import { getUserFullName } from 'swag-common/business-logic/user/get-user-full-name.logic';
import { addProductToRecentlyViewed, bulkRemoveProductFromRecentlyViewed, getAndRemoveItemFromLS, getGuestRecentlyViewed } from 'swag-client-common/utils/local-storage.util';
import { getSuperSpeedProductIdFromLS, removeSuperSpeedProductIdFromLS } from 'swag-client-common/utils/super-speed-local-storage.utils';
import { SHARE_MOCKUP_DESIGN_DRAWER, NO_DESIGN_POPUP } from 'swag-client-common/constants';
import { scrollToItem } from 'swag-client-common/utils/tricks';
import UploadApi from 'swag-client-common/api/upload.api';
import { getStandaloneRelatedItemsFromCart, getLogosAndLayersFromCBSProductLogos } from 'swag-client-common/redux/cart/cart.utils';
import { UPSELL_WIDGET_STATES } from 'swag-common/constants/main-swag.constants';
import { getProductOutOfStockThreshold } from 'swag-common/utils/product/product.utils';
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { isSuperSpeedActiveSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { isSuperSpeedProductionOption } from 'swag-common/utils/product/product.utils';
import ProductApi from '../../api/product.api';
import SizeApi from '../../api/size.api';
import { MockupApi } from '../../api/mockup.api';
import { EmailApi } from '../../api/email.api';
import CustomStoreProductApi from '../../api/custom-store-product.api';
import { cartItemsUpsellDismissedStatesSelector, orderTokenSelector } from '../cart/cart.selectors';
import { currentCategorySelector } from '../product-listing/product-listing.selectors';
import RecentlyViewedApi from '../../api/recently-viewed.api';
import { handleLogoUploadRequest, continueProcessingFile } from '../logo-editing/logo-editing.actions';
import { userIdSelector, isLoggedInSelector } from '../user/user.selectors';
import { updateLogoDetails, addText, applyLogosAndLayers, setLogos } from '../logo/logo.actions';
import { isAnyLogoUploadedSelector, isLogoDimensionsManuallyModifiedSelector } from '../logo/logo.selectors';
import { loadingTurnOff, loadingTurnOn } from '../common/common.actions';
import { relatedProductSelector } from '../related-product-builder-popup/related-product.selectors';
import { getSizeSubmissionById } from '../collect-sizes/collect-sizes.actions';
import { CompanyArtFolderApi } from '../../api/company-art-folder.api';
import { shouldSaveCompanyArtFolderSelector } from '../company-art-folder/company-art-folder.selectors-other';
import { addCBSProduct, editCBSProduct, REBUILD_CBS_PRODUCT } from '../curated-brand-store/curated-brand-store.actions';
import { selectedCBSProductSelector, isCuratedBrandStoreSelector } from '../curated-brand-store/curated-brand-store-other.selector.dynamic';
import { sizeLoadedSelector, sizeIndexSelector, sizeSelector, minimumQuantitySelector, splitOrderModeSelector, selectedVariantsSelector, resetEditedItemSelector, printedSampleItemSelector, swagBoxSampleItemSelector, blankSampleItemSelector, activeProductImageSelector, orderItemSelector, customStoreProductSelector, relatedOrderItemsSelector, productSelector, productNameSelector, isCustomizedBlankSelector, quantityIncrementSelector, productVariantsToChooseSelector, isRebuildModeSelector, selectedVariantsInStockLevelsSelector, associatedProductsSelectedVariantsInStockLevelsSelector, selectedAssociatedProductsSelector, editingItemSelector, quantitiesSelector, isCreateModeSelector, isLimitedEditionSelector, selectedProductionTimeSelector, productInStockLevelsByVariantSkuSelector, selectedVariantsDataSelector, isLEProductHasInfinityQuantity, sizeSubmissionIdSelector, superSpeedColorLimitationErrorSelector, productSettingsSelector, currentProductionTimeSelector, currentProductionTimeListSelector, currentProductSettingsSelector, superSpeedProductSettingsSelector, isRebuildCBSProductModeSelector, isCBSSplitOrderModeOnSelector } from './product.selectors';
import { getDeletedRelatedItems, getDefaultProductionTimeIdx, isQuantityChangedWhenUpsellWidgetWasDismissedAtBuilder, updateRelatedItemBeforeAdd, prepareCompanyArtFolderLogosForSaving, getProductionTimeIndexFromSettings, getSuperSpeedProductionTimeWithIndex } from './product.utils';
const PREFIX = 'PRODUCT_';
export const REMOVE_ASSOCIATED_PRODUCT = `${PREFIX}REMOVE_ASSOCIATED_PRODUCT`;
export const removeAssociatedProductAction = productId => ({
  type: REMOVE_ASSOCIATED_PRODUCT,
  productId
});
export const removeAssociatedProduct = (productId, isShared) => dispatch => {
  if (isShared) {
    return dispatch(showPopup({
      popup: REMOVE_RELATED_ITEM_WARNING_POPUP,
      productId
    }));
  }
  return dispatch(removeAssociatedProductAction(productId));
};
export const CHANGE_RELATED_PRODUCTS_POPUP_VISIBILITY = `${PREFIX}CHANGE_RELATED_PRODUCTS_POPUP_VISIBILITY`;
export const changeRelatedProductsPopupVisibility = () => ({
  type: CHANGE_RELATED_PRODUCTS_POPUP_VISIBILITY
});
export const ADD_ASSOCIATED_PRODUCT = `${PREFIX}ADD_ASSOCIATED_PRODUCT`;
export const addAssociatedProduct = () => (dispatch, getState) => {
  const state = getState();
  const isSuperSpeedActive = isSuperSpeedActiveSelector(state);
  const productionTimeList = currentProductionTimeListSelector(state);
  const {
    product,
    selectedVariants,
    selectedProductionTime,
    quantities,
    sizeIndex,
    methodIndex,
    variantIndex,
    splitOrderMode,
    size
  } = relatedProductSelector(state);
  if (isSuperSpeedActive) {
    removeSuperSpeedProductIdFromLS();
  }
  const relatedProduct = {
    product,
    prodTime: selectedProductionTime,
    selectedVariants,
    quantities,
    sizeIndex,
    methodIndex,
    variantIndex,
    splitOrderMode,
    size
  };
  dispatch({
    type: ADD_ASSOCIATED_PRODUCT,
    relatedProduct,
    productionTimeList
  });
};
const calculateDefaultQuantityOnPBCreateMode = (dispatch, getState) => {
  const state = getState();
  if (!isCreateModeSelector(state)) {
    return;
  }
  const minQuant = minimumQuantitySelector(state);
  const quantIncrement = quantityIncrementSelector(state);
  const defaultQuantity = Math.ceil(minQuant / 100) * 100;
  let newQuantity = defaultQuantity;
  if (isLimitedEditionSelector(state) && !isLEProductHasInfinityQuantity(state)) {
    const inStockLvls = productInStockLevelsByVariantSkuSelector(state);
    const variants = selectedVariantsDataSelector(state);
    const product = productSelector(state);
    let totalQuantityInStock = 0;
    if (isProductFullDesign(product)) {
      var _inStockLvls$product$;
      totalQuantityInStock = (_inStockLvls$product$ = inStockLvls[product.num]) !== null && _inStockLvls$product$ !== void 0 ? _inStockLvls$product$ : 0;
    } else {
      totalQuantityInStock = variants.reduce((totalQuantityInStock, {
        productSku,
        sizeSkus
      }) => {
        let total = 0;
        if (productSku && inStockLvls[productSku]) {
          total = inStockLvls[productSku];
        }
        const sizeSkusValues = Object.values(sizeSkus !== null && sizeSkus !== void 0 ? sizeSkus : {});
        if (sizeSkusValues.length > 0) {
          total = sizeSkusValues.reduce((sum, sizeSku) => {
            var _inStockLvls$sizeSku;
            return sum + ((_inStockLvls$sizeSku = inStockLvls[sizeSku]) !== null && _inStockLvls$sizeSku !== void 0 ? _inStockLvls$sizeSku : 0);
          }, 0);
        }
        return totalQuantityInStock + total;
      }, 0);
    }
    if (totalQuantityInStock < defaultQuantity) {
      newQuantity = totalQuantityInStock;
    }
  }
  const remainder = newQuantity % quantIncrement;
  if (remainder) {
    if (newQuantity === defaultQuantity) {
      newQuantity += quantIncrement - remainder;
    } else {
      newQuantity -= remainder;
    }
  }
  dispatch(handleGeneralQuantityChange(newQuantity));
};
export const FETCH_SIZE_SUCCESS = `${PREFIX}FETCH_SIZE_SUCCESS`;
export const FETCH_SIZE_FAIL = `${PREFIX}FETCH_SIZE_FAIL`;
export const fetchSize = createAsyncAction({
  fn: SizeApi.getById,
  success: body => ({
    type: FETCH_SIZE_SUCCESS,
    size: body.size
  }),
  error: errorAction(FETCH_SIZE_FAIL),
  postSuccess: (dispatch, getState) => {
    const state = getState();
    const sizeSubmissionId = sizeSubmissionIdSelector(state);
    if (sizeSubmissionId) {
      dispatch(getSizeSubmissionById({
        id: sizeSubmissionId
      }));
    } else {
      calculateDefaultQuantityOnPBCreateMode(dispatch, getState);
    }
  }
});
export const FETCH_SWAG_SAMPLE_BOX_SUCCESS = `${PREFIX}FETCH_SWAG_SAMPLE_BOX_SUCCESS`;
export const FETCH_SWAG_SAMPLE_BOX_FAIL = `${PREFIX}FETCH_SWAG_SAMPLE_BOX_FAIL`;
export const fetchSwagSampleBox = createAsyncAction({
  fn: ProductApi.getSwagSampleBox,
  success: ({
    swagSampleBox
  }) => ({
    type: FETCH_SWAG_SAMPLE_BOX_SUCCESS,
    swagSampleBox
  }),
  error: errorAction(FETCH_SWAG_SAMPLE_BOX_FAIL)
});
export const FETCH_PRODUCT_START = `${PREFIX}FETCH_PRODUCT_START`;
export const FETCH_PRODUCT_SUCCESS = `${PREFIX}FETCH_PRODUCT_SUCCESS`;
export const FETCH_PRODUCT_FAIL = `${PREFIX}FETCH_PRODUCT_FAIL`;
export const FETCH_PRODUCT_END = `${PREFIX}FETCH_PRODUCT_END`;
export const fetchProduct = createAsyncAction({
  transformData: data => _objectSpread({}, data, {
    shouldConvertEmbellishmentMethods: true,
    shouldGetProductAttributes: false,
    excludeBasicBox: true
  }),
  preFn: dispatch => {
    dispatch(resetReducer());
    dispatch({
      type: FETCH_PRODUCT_START
    });
  },
  fn: ProductApi.getById,
  success: (body, _, dispatch) => {
    if (body.product.hidden) {
      return onProductFetchError(dispatch, {
        msg: 'Hidden product'
      });
    }
    return {
      type: FETCH_PRODUCT_SUCCESS,
      payload: body.product
    };
  },
  postSuccess: async (dispatch, getState, _, body) => {
    var _body$product;
    await dispatch(saveAsRecentlyViewed(body.product._id));
    if (body.cbsProduct) {
      dispatch({
        type: REBUILD_CBS_PRODUCT,
        payload: body.cbsProduct
      });
    }
    if (isCuratedBrandStoreSelector() && (_body$product = body.product) !== null && _body$product !== void 0 && _body$product.item) {
      const item = body.product.item;
      const logosAndLayers = getLogosAndLayersFromCBSProductLogos(item.logos);
      const isCustomizedBlank = !!item.isCustomizedBlank;
      await dispatch(setLogos(logosAndLayers));
      await dispatch(changeIsCustomizedBlank(isCustomizedBlank));
      if (item.sizeInd > 0) {
        await dispatch(changeSizeIndex(item.sizeInd));
      }
    }
    const state = getState();
    const {
      cart: {
        selectedSuggestedProduct,
        listOfNearestVariants
      }
    } = state;
    if (state.product.err) {
      return;
    }
    const {
      selectedProductionTime,
      product: {
        _id: productId,
        size,
        hasSize,
        associatedProducts,
        designUploadMode,
        variants
      }
    } = state.product;
    const validVariants = productVariantsToChooseSelector(state);
    const selectedVariants = selectedVariantsSelector(state);
    const currentVariantIndex = selectedVariants[0];
    const firstValidVariantIndex = validVariants[0] ? variants.findIndex(variant => variant.productSku === validVariants[0].productSku) : 0;
    if (designUploadMode === DesignUploadMode.box) {
      dispatch(fetchSwagSampleBox());
    }
    if (!validVariants.some(validVariant => validVariant.index === currentVariantIndex)) {
      let variantIndex = firstValidVariantIndex;
      const color = localStorage.getItem('productColor');
      if (color) {
        const foundVariant = validVariants.find(variant => variant.color === color);
        variantIndex = foundVariant ? variants.findIndex(variant => variant.color === color) : firstValidVariantIndex;
      }
      if (variantIndex === -1) {
        variantIndex = firstValidVariantIndex === -1 ? 0 : firstValidVariantIndex;
      }
      dispatch(selectVariant(variantIndex));
    }

    // preload info from suggestedProduct
    if (selectedSuggestedProduct) {
      prepareImageDataForSuggestedProduct(selectedSuggestedProduct, listOfNearestVariants, dispatch);
    }
    if (hasSize) {
      dispatch(fetchSize({
        id: size
      }));
    } else {
      dispatch({
        type: FETCH_SIZE_SUCCESS
      });
      calculateDefaultQuantityOnPBCreateMode(dispatch, getState);
    }
    dispatch(getFonts());
    if (associatedProducts.length > 0) {
      if (associatedProducts.length > 1) {
        dispatch(getRelatedProducts({
          ids: associatedProducts,
          shouldAvoidLEProducts: true
        }));
      } else {
        const id = associatedProducts[0];
        dispatch(getRelatedProduct({
          id,
          shouldAvoidLEProduct: true
        }));
      }
    }
    const currentProductSettings = currentProductSettingsSelector(state);
    const productionTimeList = (currentProductSettings === null || currentProductSettings === void 0 ? void 0 : currentProductSettings.productionTimeList) || [];
    const superSpeedProductId = getSuperSpeedProductIdFromLS();
    const isSuperSpeedActive = Boolean(superSpeedProductId);
    const isSameProduct = productId === superSpeedProductId;
    const isSuperSpeedSelected = isSuperSpeedProductionOption(productionTimeList === null || productionTimeList === void 0 ? void 0 : productionTimeList[selectedProductionTime]);
    const superSpeedProductSettings = superSpeedProductSettingsSelector(state);
    if (isSuperSpeedActive && isSameProduct && superSpeedProductSettings) {
      const {
        index: timeIdx
      } = getSuperSpeedProductionTimeWithIndex(superSpeedProductSettings.productionTimeList);
      dispatch(changeTime(timeIdx));
      dispatch(changeEmbellishmentMethodById(superSpeedProductSettings.embellishmentMethodId));
    }
    if (isSuperSpeedActive && !superSpeedProductSettings || isSuperSpeedActive && !isSameProduct || !isSuperSpeedActive && isSuperSpeedSelected) {
      const prodTime = getDefaultProductionTimeIdx(productionTimeList);
      removeSuperSpeedProductIdFromLS();
      dispatch(changeTime(prodTime));
    }
    dispatch(getSuggestedProductsByCategory());
    const isCBSSplitOrderModeOn = isCBSSplitOrderModeOnSelector(state);
    if (isCBSSplitOrderModeOn) {
      dispatch({
        type: TOGGLE_SPLIT_ORDER_ON
      });
    }
  },
  error: (err, data, dispatch) => {
    return onProductFetchError(dispatch, {
      msg: 'Hidden product'
    });
  }
});
export const SAVE_COMPANY_ART_FOLDER_SUCCESS = `${PREFIX}SAVE_COMPANY_ART_FOLDER_SUCCESS`;
export const SAVE_COMPANY_ART_FOLDER_FAIL = `${PREFIX}SAVE_COMPANY_ART_FOLDER_FAIL`;
export const saveCompanyArtFolderLogos = createAsyncAction({
  transformData: data => ({
    logos: prepareCompanyArtFolderLogosForSaving(data)
  }),
  fn: CompanyArtFolderApi.saveArtFolderLogos,
  success: () => ({
    type: SAVE_COMPANY_ART_FOLDER_SUCCESS
  }),
  error: errorAction(SAVE_COMPANY_ART_FOLDER_FAIL)
});
const onProductFetchError = (dispatch, err) => {
  dispatch({
    type: FETCH_PRODUCT_END
  });
  return errorAction(FETCH_PRODUCT_FAIL)(err);
};
export const checkLocalMockupForSaving = dispatch => {
  const savedEditorState = localStorage.getItem('savedEditorState');
  if (savedEditorState) {
    dispatch(applyLogosAndLayers());
    sendDesignFromStorage(dispatch);
  }
};
export const CHANGE_VARIANT = `${PREFIX}CHANGE_VARIANT`;
export const ADD_SELECTED_VARIANT = `${PREFIX}ADD_SELECTED_VARIANT`;
export const selectVariant = (variantIndex = 0) => (dispatch, getState) => {
  const state = getState();
  const splitOrderMode = splitOrderModeSelector(state);
  const minQuant = minimumQuantitySelector(state);
  const activeImage = activeProductImageSelector(state);
  const variant = state.product.product.variants[variantIndex];
  if (splitOrderMode) {
    dispatch({
      type: ADD_SELECTED_VARIANT,
      payload: {
        variantIndex
      }
    });
    dispatch(handleVariantGeneralQuantityChange(minQuant, variantIndex));
  } else {
    dispatch({
      type: CHANGE_VARIANT,
      payload: {
        variantIndex
      }
    });
  }
  if (!activeImage || !activeImage.isImageWithImprintArea) {
    dispatch(changeView('front'));
  }
  if (variant) {
    localStorage.setItem('productColor', variant.color);
  }
};
export const UNSELECT_VARIANT = `${PREFIX}UNSELECT_VARIANT`;
export const unselectColor = variantIndex => (dispatch, getState) => {
  const state = getState();
  const splitOrderMode = splitOrderModeSelector(state);
  if (!splitOrderMode) {
    return;
  }
  dispatch({
    type: UNSELECT_VARIANT,
    payload: {
      variantIndex
    }
  });
};
export const uploadLogo = (file, view) => dispatch => {
  dispatch(handleLogoUploadRequest(file, view));
  dispatch(changeView(view));
};
export const continueUploadLogo = (file, view) => dispatch => {
  dispatch(continueProcessingFile(file, view));
  dispatch(changeView(view));
};
export const DELETE_LOGO = `${PREFIX}DELETE_LOGO`;
export const deleteLogo = view => ({
  type: DELETE_LOGO,
  view
});
export const TOGGLE_SPLIT_ORDER_OFF = `${PREFIX}TOGGLE_SPLIT_ORDER_OFF`;
export const TOGGLE_SPLIT_ORDER_ON = `${PREFIX}TOGGLE_SPLIT_ORDER_ON`;
export const confirmSplitOrder = splitOrderMode => dispatch => {
  if (splitOrderMode) {
    dispatch({
      type: TOGGLE_SPLIT_ORDER_ON
    });
  } else {
    dispatch({
      type: TOGGLE_SPLIT_ORDER_OFF
    });
  }
};
export const CHANGE_TIME = `${PREFIX}CHANGE_TIME`;
export const changeTime = time => ({
  type: CHANGE_TIME,
  time
});
export const CHANGE_VIEW = `${PREFIX}CHANGE_VIEW`;
export const changeView = view => ({
  type: CHANGE_VIEW,
  view
});
export const CHANGE_PREVIEW = `${PREFIX}CHANGE_PREVIEW`;
export const changePreview = view => ({
  type: CHANGE_PREVIEW,
  view
});
export const CHANGE_DISCOUNT = `${PREFIX}CHANGE_DISCOUNT`;
export const changeDiscount = () => ({
  type: CHANGE_DISCOUNT
});
export const CHANGE_SIZE_INDEX = `${PREFIX}CHANGE_SIZE_INDEX`;
export const changeSizeIndex = index => ({
  type: CHANGE_SIZE_INDEX,
  payload: index
});
export const CHANGE_DESIGN_INFO = `${PREFIX}CHANGE_DESIGN_INFO`;
export const changeDesignNotes = ({
  notes,
  attachments
}) => dispatch => {
  dispatch({
    type: CHANGE_DESIGN_INFO,
    payload: {
      notes
    }
  });
  if (attachments && attachments.length) {
    dispatch(uploadAttachments({
      attachments
    }));
  }
  dispatch(hidePopup());
};
export const RESET_REDUCER = `${PREFIX}RESET_REDUCER`;
export const resetReducer = () => ({
  type: RESET_REDUCER
});
const getRebuildData = state => {
  const {
    cart: {
      draftItems,
      items
    },
    product: {
      storageType,
      inventoryId
    }
  } = state;
  switch (storageType) {
    case OrderStorageTypes.DRAFT:
      {
        return {
          items: draftItems.map(i => _objectSpread({}, i, {
            inventoryId
          })),
          action: replaceItemInDraft
        };
      }
    default:
      {
        return {
          items: items.map(i => _objectSpread({}, i, {
            inventoryId
          })),
          action: replaceItem
        };
      }
  }
};
export const placeItemInCart = () => async (dispatch, getState) => {
  var _data$payload, _data$payload$config;
  dispatch(loadingTurnOn());
  dispatch(toggleAddTextDialog(false));
  dispatch(changeOpenedToolbarItem(null));
  window.onbeforeunload = null;
  const state = getState();
  const mainItem = orderItemSelector(state);
  const relatedOrderItems = relatedOrderItemsSelector(state);
  const orderToken = orderTokenSelector(state);
  const isSuperSpeedActive = isSuperSpeedActiveSelector(state);
  const data = await dispatch(getSuperSpeedSettings());
  const isSuperSpeedEnabled = data === null || data === void 0 ? void 0 : (_data$payload = data.payload) === null || _data$payload === void 0 ? void 0 : (_data$payload$config = _data$payload.config) === null || _data$payload$config === void 0 ? void 0 : _data$payload$config.enabled;
  if (isSuperSpeedActive && !isSuperSpeedEnabled) {
    const productionTimeList = currentProductionTimeListSelector(state);
    const prodTime = getDefaultProductionTimeIdx(productionTimeList);
    dispatch(changeTime(prodTime));
    dispatch(loadingTurnOff());
    return dispatch(showPopup({
      popup: SHOW_ERROR_POPUP,
      type: SUPER_SPEED_ERROR_TYPE
    }));
  }
  if (superSpeedColorLimitationErrorSelector(state)) {
    dispatch(loadingTurnOff());
    return dispatch(showPopup({
      popup: ADD_TO_CART_WARNING_POPUP
    }));
  }
  if (mainItem.embellishmentMethod) {
    mainItem.embellishmentMethod = mainItem.embellishmentMethod._id;
  }
  delete mainItem.products;
  const mainItemId = mainItem._id || mainItem.itemId;
  const isCBSMode = selectResellerInCBSMode(state);
  const isEditCBSProductMode = isRebuildCBSProductModeSelector(state);
  const isEdit = isRebuildModeSelector(state);
  const shouldSaveCompanyArtFolder = shouldSaveCompanyArtFolderSelector(state);
  if (shouldSaveCompanyArtFolder) {
    dispatch(saveCompanyArtFolderLogos(mainItem.logos));
  }
  if (isEditCBSProductMode) {
    window.onbeforeunload = null;
    const selectedProduct = selectedCBSProductSelector(state);
    return dispatch(editCBSProduct({
      id: selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct._id,
      item: mainItem
    }));
  }
  if (isEdit) {
    var _originalCartItem$pro;
    const productionTimeList = currentProductionTimeListSelector(state);
    const selectedProductionTimeIdx = selectedProductionTimeSelector(state);
    const selectedProductionTime = productionTimeList[selectedProductionTimeIdx];
    const isSuperSpeedSelected = isSuperSpeedProductionOption(selectedProductionTime);
    if (!isSuperSpeedEnabled && (!selectedProductionTime || isSuperSpeedSelected)) {
      const prodTime = getDefaultProductionTimeIdx(productionTimeList);
      mainItem.prodTime = prodTime;
    }
    const deletedOrderItems = {};
    const {
      items,
      action
    } = getRebuildData(state);
    const cartItemsUpsellDismissedStates = cartItemsUpsellDismissedStatesSelector(state);
    const isLogoDimensionsModified = isLogoDimensionsManuallyModifiedSelector(state);
    const originalCartItem = items.find(item => String(item._id) === String(mainItem._id));
    if (isQuantityChangedWhenUpsellWidgetWasDismissedAtBuilder({
      originalCartItem,
      newItem: mainItem,
      upsellDismissedStates: cartItemsUpsellDismissedStates[mainItem._id]
    })) {
      mainItem.upsellDismissedStates[UPSELL_WIDGET_STATES.IS_QUANTITY_CHANGED] = true;
    }
    const isEmbellishmentMethodUpdated = originalCartItem.embellishmentMethod !== mainItem.embellishmentMethod;
    const isSizeUpdated = ((_originalCartItem$pro = originalCartItem.prod) === null || _originalCartItem$pro === void 0 ? void 0 : _originalCartItem$pro.sizeDependentPrice) && originalCartItem.sizeInd !== mainItem.sizeInd;
    const needToResetReorder = isEmbellishmentMethodUpdated || isSizeUpdated;
    if (originalCartItem.isReorder && needToResetReorder) {
      mainItem.isReorder = false;
      mainItem.itemId = null;
      mainItem.reorderId = null;
      mainItem.reorderItemChainId = null;
      mainItem.setupFeeDeductionDiscount = 0;
    }
    if (mainItem.asRelatedItemId) {
      const relatedOrderItemsFromCart = items.filter(getStandaloneRelatedItemsFromCart(mainItem));
      const deletedRelatedItems = relatedOrderItemsFromCart.filter(getDeletedRelatedItems(relatedOrderItems, mainItemId));
      deletedRelatedItems.forEach(item => {
        deletedOrderItems[item._id] = item._id;
      });
    }
    const payload = getEditDraftPayload(state.cart, {
      items: [_objectSpread({}, mainItem, {
        textImages: {}
      }), ...relatedOrderItems]
    });
    window.onbeforeunload = null;
    return dispatch(action(_objectSpread({}, payload, {
      deletedOrderItems,
      editedItemId: mainItem._id,
      isLogoDimensionsModified
    })));
  }
  const updatedRelatedOrderItems = relatedOrderItems.map(updateRelatedItemBeforeAdd);
  const payload = {
    orderToken,
    items: [_objectSpread({}, mainItem, {
      textImages: {}
    }), ...updatedRelatedOrderItems]
  };
  window.onbeforeunload = null;
  if (isCBSMode) {
    return dispatch(addCBSProduct({
      item: mainItem
    }));
  }
  dispatch(addItemsToCart(payload));
};
export const placeSampleInCart = ({
  isPrintedSample = undefined,
  isSwagSampleBox = undefined,
  source = null,
  shouldExcludeBoxItem = false,
  boxToMoveId = null,
  isScrollEnabled = false
} = {}) => async (dispatch, getState) => {
  let items;
  const state = getState();
  const orderToken = orderTokenSelector(state);
  const itemParams = isPrintedSample ? {
    isPrintedSample: true
  } : {
    isSample: true
  };
  if (source) {
    itemParams.source = source;
  }
  window.onbeforeunload = null;
  if (isSwagSampleBox) {
    items = swagBoxSampleItemSelector(state);
  } else if (isPrintedSample) {
    items = printedSampleItemSelector(state);
  } else {
    items = blankSampleItemSelector(state);
  }
  if (isPrintedSample && shouldSaveCompanyArtFolderSelector(state)) {
    dispatch(saveCompanyArtFolderLogos(items[0].logos));
  }
  items.map(item => {
    if (!item.embellishmentMethod) {
      return item;
    }
    item.embellishmentMethod = item.embellishmentMethod._id;
    return item;
  });
  try {
    const updatedItems = items.map(item => {
      const data = _objectSpread({}, item, itemParams, {
        textImages: {},
        asRelatedItemId: v1()
      });
      if (shouldExcludeBoxItem && item.boxItemsId && item.isMainItem) {
        data.price = 0;
        data.isBoxSampleWithoutBox = true;
      }
      return data;
    });
    const response = await dispatch(addItemsToCart({
      orderToken,
      items: updatedItems
    }));
    if (boxToMoveId) {
      await dispatch(moveItemToDraft({
        itemId: boxToMoveId
      }));
    }
    if (isSwagSampleBox && isScrollEnabled) {
      const {
        items,
        items: {
          length
        }
      } = response.body.order;
      const sample = items[length - 1];
      scrollToItem(sample._id);
    }
    browserHistory.push(APP_ROUTES.CART);
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log(e); // eslint-disable-line
  }
};

export const CHANGE_QUANTITIES = `${PREFIX}CHANGE_QUANTITIES`;
export const handleGeneralQuantityChange = (quantity, selectedAssociatedProductsPayload, isQuantityManuallyChanged = false) => (dispatch, getState) => {
  const state = getState();
  const sizeLoaded = sizeLoadedSelector(state);
  if (!sizeLoaded) {
    return;
  }
  const size = sizeSelector(state);
  const editingItem = editingItemSelector(state);
  const selectedVariants = selectedVariantsSelector(state);
  const selectedSizeIndex = sizeIndexSelector(state);
  const product = productSelector(state);
  const selectedVariantsInStockLevels = selectedVariantsInStockLevelsSelector(state);
  if (editingItem !== null && editingItem !== void 0 && editingItem.isShared) {
    const quantities = quantitiesSelector(state);
    return dispatch({
      type: CHANGE_QUANTITIES,
      payload: {
        quantities,
        selectedAssociatedProductsPayload,
        isQuantityManuallyChanged
      }
    });
  }
  const quantities = applyQuantityToProduct({
    threshold: getProductOutOfStockThreshold(product),
    product,
    quantity,
    selectedVariants,
    selectedSizeIndex,
    sizeSettings: size,
    selectedVariantsInStockLevels
  });
  dispatch({
    type: CHANGE_QUANTITIES,
    payload: {
      quantities,
      selectedAssociatedProductsPayload,
      isQuantityManuallyChanged
    }
  });
};
export const CHANGE_TOTAL_QUANTITIES_CHANGE_FOR_RELATED_PRODUCT = `${PREFIX}CHANGE_TOTAL_QUANTITIES_CHANGE_FOR_RELATED_PRODUCT`;
export const handleTotalQuantityChangeForRelatedProduct = (quantity, relatedProductState, selectedVariantsInStockLevels) => {
  const {
    quantities: stateQuantities,
    product,
    isShared,
    sizeIndex,
    size,
    selectedVariants
  } = relatedProductState;
  const relatedProductId = product._id;
  if (isShared) {
    return {
      relatedProductId,
      quantities: stateQuantities
    };
  }
  const variants = selectedVariants.sort((previousVariantIndex, variantIndex) => previousVariantIndex > variantIndex ? 1 : -1);
  const selectedSizeIndex = sizeIndex;
  const quantities = applyQuantityToProduct({
    product,
    quantity,
    selectedSizeIndex,
    selectedVariantsInStockLevels,
    sizeSettings: size,
    selectedVariants: variants,
    threshold: getProductOutOfStockThreshold(product)
  });
  return {
    quantities,
    relatedProductId
  };
};
export const handleGeneralQuantityWithRelatedProductsChange = quantity => (dispatch, getState) => {
  const state = getState();
  const editingItem = editingItemSelector(state);
  const selectedAssociatedProducts = selectedAssociatedProductsSelector(state);
  const associatedProductsSelectedVariantsInStockLevels = associatedProductsSelectedVariantsInStockLevelsSelector(state);
  const previousMainItemQuantity = (editingItem === null || editingItem === void 0 ? void 0 : editingItem.quantity) || 0;
  const mainItemCount = editingItem !== null && editingItem !== void 0 && editingItem.isShared ? 0 : 1;
  const relatedProductCount = selectedAssociatedProducts.filter(p => !p.isShared).length;
  const productsCount = mainItemCount + relatedProductCount;
  const generalQuantity = quantity;
  const quantityForEachProduct = Math.floor(generalQuantity / productsCount);
  const mainProductQuantity = editingItem !== null && editingItem !== void 0 && editingItem.isShared ? previousMainItemQuantity : quantityForEachProduct + generalQuantity % productsCount;
  const selectedAssociatedProductsPayload = selectedAssociatedProducts.map((product, index) => handleTotalQuantityChangeForRelatedProduct(quantityForEachProduct, product, associatedProductsSelectedVariantsInStockLevels[index]));
  dispatch(handleGeneralQuantityChange(mainProductQuantity, selectedAssociatedProductsPayload, true));
};
export const handleVariantGeneralQuantityChange = (quantity, variantIndex) => (dispatch, getState) => {
  const state = getState();
  const selectedSizeIndex = sizeIndexSelector(state);
  const size = sizeSelector(state);
  const product = productSelector(state);
  const selectedVariantsInStockLevels = selectedVariantsInStockLevelsSelector(state);
  const quantities = applyQuantityToSingleVariant({
    threshold: getProductOutOfStockThreshold(product),
    product,
    quantity,
    selectedSizeIndex,
    size,
    selectedVariantsInStockLevels
  });
  return dispatch(changeSingleVariantQuantities(quantities, variantIndex));
};
export const CHANGE_SINGLE_VARIANT_QUANTITIES = `${PREFIX}CHANGE_SINGLE_VARIANT_QUANTITIES`;
export const changeSingleVariantQuantities = (quantities, variantIndex) => ({
  type: CHANGE_SINGLE_VARIANT_QUANTITIES,
  payload: {
    variantIndex,
    quantities
  }
});
export const CHANGE_SINGLE_QUANTITY = `${PREFIX}CHANGE_SINGLE_QUANTITY`;
export const changeSingleQuantity = (quantity, variantIndex, sizeIndex) => ({
  type: CHANGE_SINGLE_QUANTITY,
  payload: {
    variantIndex,
    quantity,
    sizeIndex
  }
});
export const CHANGE_IS_CUSTOMIZED_BLANK = `${PREFIX}CHANGE_IS_CUSTOMIZED_BLANK`;
export const changeIsCustomizedBlank = isCustomizedBlank => ({
  type: CHANGE_IS_CUSTOMIZED_BLANK,
  payload: {
    isCustomizedBlank
  }
});
export const CHANGE_ASSOCIATED_PRODUCT_QUANTITY = `${PREFIX}CHANGE_ASSOCIATED_PRODUCT_QUANTITY`;
export const changeAssociatedProductQuantity = (productId, variantIndex, sizeIndex, quantity) => ({
  type: CHANGE_ASSOCIATED_PRODUCT_QUANTITY,
  payload: {
    productId,
    variantIndex,
    sizeIndex,
    quantity
  }
});
export const SAVE_DESIGN_REQUEST = `${PREFIX}SAVE_DESIGN_REQUEST`;
export const SAVE_DESIGN_SUCCESS = `${PREFIX}SAVE_DESIGN_SUCCESS`;
export const SAVE_DESIGN_ERROR = `${PREFIX}SAVE_DESIGN_ERROR`;
export const SAVE_DESIGN_GUEST_MODE = `${PREFIX}SAVE_DESIGN_GUEST_MODE`;
export const saveDesign = () => (dispatch, getState) => {
  const state = getState();
  const {
    product: {
      mode
    },
    user: {
      isLoggedIn
    }
  } = state;
  const item = _objectSpread({}, resetEditedItemSelector(state), {
    textImages: {}
  });
  if (item.embellishmentMethod) {
    item.embellishmentMethod = item.embellishmentMethod._id;
  }
  if (mode === PRODUCT_BUILDER_MODES.REBUILD_MOCKUP_MODE) {
    return dispatch(updateDesign({
      item
    }));
  }
  if (!isLoggedIn) {
    dispatch({
      type: SAVE_DESIGN_GUEST_MODE
    });
    dispatch(showPopup({
      popup: LOGIN_POPUP,
      title: LOGIN_FOR_SAVE_A_DESIGN
    }));
    localStorage.setItem('designMockupItem', JSON.stringify(item));
  } else {
    dispatch(createDesign({
      item
    }));
  }
};
export const createDesign = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: SAVE_DESIGN_REQUEST
    });
  },
  fn: MockupApi.save,
  success: () => ({
    type: SAVE_DESIGN_SUCCESS
  }),
  error: errorAction(SAVE_DESIGN_ERROR),
  postSuccess: (dispatch, getState) => {
    const {
      accountMockups: {
        isSaveGuestMockup
      }
    } = getState();
    dispatch(showNotification({
      text: isSaveGuestMockup && 'Success!' || 'Success! Check your saved designs dashboard.'
    }));
  },
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const updateDesign = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: SAVE_DESIGN_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      editedMockup
    } = getState().accountMockups;
    return _objectSpread({}, data, {
      id: editedMockup
    });
  },
  fn: MockupApi.update,
  success: () => ({
    type: SAVE_DESIGN_SUCCESS
  }),
  error: errorAction(SAVE_DESIGN_ERROR),
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: 'Success! Check your saved designs dashboard.'
    }));
  },
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const sendDesignFromStorage = dispatch => {
  const item = getAndRemoveItemFromLS('designMockupItem');
  dispatch(createDesign({
    item
  }));
  dispatch(hidePopup());
};
export const startSharingDesign = () => (dispatch, getState) => {
  const item = _objectSpread({}, resetEditedItemSelector(getState()), {
    textImages: {}
  });
  if (item.embellishmentMethod) {
    item.embellishmentMethod = item.embellishmentMethod._id;
  }
  const productName = productNameSelector(getState());
  dispatch(showPopup({
    popup: SHARE_MOCKUP_DESIGN_DRAWER,
    payload: {
      itemsToShare: [item],
      from: 'productBuilder',
      productName: [productName]
    }
  }));
};
export const GET_SUGGESTED_PRODUCTS_REQUEST = `${PREFIX}GET_SUGGESTED_PRODUCTS_REQUEST`;
export const GET_SUGGESTED_PRODUCTS_SUCCESS = `${PREFIX}GET_SUGGESTED_PRODUCTS_SUCCESS`;
export const GET_SUGGESTED_PRODUCTS_ERROR = `${PREFIX}GET_SUGGESTED_PRODUCTS_ERROR`;
export const getSuggestedProductsByCategory = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_SUGGESTED_PRODUCTS_REQUEST
    });
  },
  transformData: (data, getState) => {
    const state = getState();
    const {
      product: {
        product
      },
      searchProducts: {
        isOpeningProductFromCategory
      }
    } = state;
    const {
      _id,
      categories
    } = product;
    const currentCategory = currentCategorySelector(state);
    let categoryId = currentCategory ? currentCategory._id : '';
    if (!isOpeningProductFromCategory || categoryId === ALL_SWAG_CATEGORY || !categoryId) {
      const category = (categories || []).find(c => c.isDefault);
      categoryId = category && category.categoryId || product.category;
    }
    return {
      productId: _id,
      categoryId
    };
  },
  fn: ProductApi.getSuggestedByCategory,
  success: ({
    products,
    listWithNearestColor
  }) => ({
    type: GET_SUGGESTED_PRODUCTS_SUCCESS,
    payload: {
      products,
      listWithNearestColor
    }
  }),
  error: errorAction(GET_SUGGESTED_PRODUCTS_ERROR)
});
export const CHANGE_FULL_SCREEN_MODE = `${PREFIX}CHANGE_FULL_SCREEN_MODE`;
export const changeFullScreenMode = () => ({
  type: CHANGE_FULL_SCREEN_MODE
});
export const CHANGE_MORE_IMAGE_MODE = `${PREFIX}CHANGE_MORE_IMAGE_MODE`;
export const changeMoreImageMode = () => ({
  type: CHANGE_MORE_IMAGE_MODE
});
export const PRELOAD_SUGGESTED_PRODUCT = `${PREFIX}PRELOAD_SUGGESTED_PRODUCT`;
export const preloadInfoFromSuggestedProduct = info => ({
  type: PRELOAD_SUGGESTED_PRODUCT,
  payload: {
    info
  }
});
export const prepareImageDataForSuggestedProduct = (suggestedProduct, listOfNearestVariants, dispatch) => {
  const {
    logos,
    additionalPrintLocations
  } = suggestedProduct;
  let firstLocationWithLogo;
  let firstLogo;
  if (suggestedProduct.designUploadMode === DesignUploadMode.fullDesign) {
    var _additionalPrintLocat, _ref, _ref2, _ref3, _logos$front$, _logos$front, _logos$back, _logos$left, _logos$right;
    firstLocationWithLogo = additionalPrintLocations === null || additionalPrintLocations === void 0 ? void 0 : (_additionalPrintLocat = additionalPrintLocations[0]) === null || _additionalPrintLocat === void 0 ? void 0 : _additionalPrintLocat.id;
    firstLogo = (_ref = (_ref2 = (_ref3 = (_logos$front$ = logos === null || logos === void 0 ? void 0 : (_logos$front = logos.front) === null || _logos$front === void 0 ? void 0 : _logos$front[0]) !== null && _logos$front$ !== void 0 ? _logos$front$ : logos === null || logos === void 0 ? void 0 : (_logos$back = logos.back) === null || _logos$back === void 0 ? void 0 : _logos$back[0]) !== null && _ref3 !== void 0 ? _ref3 : logos === null || logos === void 0 ? void 0 : (_logos$left = logos.left) === null || _logos$left === void 0 ? void 0 : _logos$left[0]) !== null && _ref2 !== void 0 ? _ref2 : logos === null || logos === void 0 ? void 0 : (_logos$right = logos.right) === null || _logos$right === void 0 ? void 0 : _logos$right[0]) !== null && _ref !== void 0 ? _ref : logos[Object.keys(logos)[0]];
  } else {
    var _Object$keys$find;
    firstLocationWithLogo = (_Object$keys$find = Object.keys(logos).find(locationName => logos[locationName].length)) !== null && _Object$keys$find !== void 0 ? _Object$keys$find : 0;
    [firstLogo] = logos[firstLocationWithLogo] || [];
  }
  if (firstLogo) {
    const {
      colors: {
        colorsToPrint,
        detectedColors,
        selectedColors,
        convertTo,
        isConfirmedByCustomer,
        isBackgroundRemoved,
        hasBackground,
        printingMethod
      },
      id,
      original,
      originalPreview,
      preview,
      originalFileName,
      previewWOBackground,
      previewsConvertedToOneColor,
      etag
    } = firstLogo;
    dispatch(updateLogoDetails({
      colorsToPrint,
      detectedColors,
      selectedColors,
      convertTo,
      isConfirmedByCustomer,
      isBackgroundRemoved,
      hasBackground,
      printingMethod,
      original,
      originalPreview,
      originalFileName,
      preview,
      previewWOBackground,
      previewsConvertedToOneColor,
      location: firstLocationWithLogo,
      id,
      etag
    }));
  }
  dispatch(preloadInfoFromSuggestedProduct({
    id: suggestedProduct._id,
    listOfNearestVariants
  }));
};
export const CHANGE_METHOD_ID = `${PREFIX}CHANGE_METHOD_ID`;
export const changeEmbellishmentMethodById = methodId => ({
  type: CHANGE_METHOD_ID,
  payload: {
    methodId
  }
});
export const SEND_EMAIL_SUCCESS = `${PREFIX}SEND_EMAIL_SUCCESS`;
export const SEND_EMAIL_FAILURE = `${PREFIX}SEND_EMAIL_FAILURE`;
export const sendEmail = createAsyncAction({
  fn: EmailApi.shareProductLink,
  success: () => ({
    type: SEND_EMAIL_SUCCESS
  }),
  error: errorAction(SEND_EMAIL_FAILURE),
  postSuccess: dispatch => dispatch(showNotification({
    text: 'Success!'
  })),
  postError: dispatch => dispatch(showNotification({
    text: 'Something went wrong'
  }))
});
export const copyLink = () => showNotification({
  text: 'Success!'
});
export const UPLOAD_ATTACHMENT_SUCCESS = `${PREFIX}UPLOAD_ATTACHMENT_SUCCESS`;
export const UPLOAD_ATTACHMENT_FAILURE = `${PREFIX}UPLOAD_ATTACHMENT_FAILURE`;
export const UPLOAD_ATTACHMENT_REQUEST = `${PREFIX}UPLOAD_ATTACHMENT_REQUEST`;
export const uploadAttachments = createAsyncAction({
  transformData: data => {
    const {
      attachments
    } = data;
    const formAttachments = new FormData();
    const cyrillicRegExp = /[а-яА-ЯЁё]/;
    attachments.filter(file => !file.src).forEach((file, key) => {
      const attachmentName = `attachment-${key}`;
      const normalizedName = file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const filename = cyrillicRegExp.test(normalizedName) ? attachmentName.split('-').join(' ') : normalizedName;
      return formAttachments.append(attachmentName, file, filename);
    });
    return formAttachments;
  },
  preFn: dispatch => dispatch({
    type: UPLOAD_ATTACHMENT_REQUEST
  }),
  fn: UploadApi.upload,
  success: attachments => ({
    type: UPLOAD_ATTACHMENT_SUCCESS,
    payload: {
      attachments
    }
  }),
  error: error => ({
    type: UPLOAD_ATTACHMENT_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const REMOVE_ATTACHMENT = `${PREFIX}REMOVE_ATTACHMENT`;
export const removeAttachment = key => ({
  type: REMOVE_ATTACHMENT,
  payload: {
    key
  }
});
export const TOGGLE_ADD_TEXT = `${PREFIX}TOGGLE_ADD_TEXT`;
export const toggleAddTextDialog = isAddTextMode => (dispatch, getState) => {
  const {
    selectedLogo,
    texts
  } = getState().logo;
  if (isAddTextMode && !texts[selectedLogo]) {
    dispatch(addText());
  }
  dispatch({
    type: TOGGLE_ADD_TEXT,
    payload: {
      isAddTextMode
    }
  });
};
export const GET_FONTS_SUCCESS = `${PREFIX}GET_FONTS_SUCCESS`;
export const GET_FONTS_FAILURE = `${PREFIX}GET_FONTS_FAILURE`;
export const getFonts = createAsyncAction({
  fn: ProductApi.getFonts,
  success: data => ({
    type: GET_FONTS_SUCCESS,
    payload: data
  }),
  error: errorAction(GET_FONTS_FAILURE)
});
export const ADD_PRODUCT_TO_STORE_REQUEST = `${PREFIX}ADD_PRODUCT_TO_STORE_REQUEST`;
export const ADD_PRODUCT_TO_STORE_SUCCESS = `${PREFIX}ADD_PRODUCT_TO_STORE_SUCCESS`;
export const ADD_PRODUCT_TO_STORE_ERROR = `${PREFIX}ADD_PRODUCT_TO_STORE_ERROR`;
export const addToStore = () => dispatch => {
  dispatch(addRegularProductToStore());
};
export const addRegularProductToStore = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: ADD_PRODUCT_TO_STORE_REQUEST
    });
  },
  transformData: (data, getState) => _objectSpread({}, customStoreProductSelector(getState()), data),
  fn: CustomStoreProductApi.addProduct,
  success: () => ({
    type: ADD_PRODUCT_TO_STORE_SUCCESS
  }),
  postSuccess: (dispatch, getState, data, {
    productId
  }) => dispatch(showNotification({
    text: 'Product was created in your custom store',
    dataAttr: productId
  })),
  error: errorAction(ADD_PRODUCT_TO_STORE_ERROR)
});
export const placeProductToStore = () => (dispatch, getState) => {
  const state = getState();
  const isDesignUploaded = isAnyLogoUploadedSelector(state);
  const isCustomizedBlank = isCustomizedBlankSelector(state);
  if (!isDesignUploaded && !isCustomizedBlank) {
    return dispatch(showPopup({
      popup: NO_DESIGN_POPUP
    }));
  }
  dispatch(addToStore());
};
export const GET_RELATED_PRODUCTS_SUCCESS = `${PREFIX}GET_RELATED_PRODUCTS_SUCCESS`;
export const GET_RELATED_PRODUCTS_FAILURE = `${PREFIX}GET_RELATED_PRODUCTS_FAILURE`;
export const getRelatedProducts = createAsyncAction({
  fn: ProductApi.getByIds,
  success: ({
    products
  }) => ({
    type: GET_RELATED_PRODUCTS_SUCCESS,
    payload: {
      associatedProducts: products
    }
  }),
  error: errorAction(GET_RELATED_PRODUCTS_FAILURE)
});
export const getRelatedProduct = createAsyncAction({
  transformData: data => {
    return _objectSpread({}, data, {
      shouldConvertEmbellishmentMethods: true
    });
  },
  fn: ProductApi.getById,
  success: ({
    product
  }) => ({
    type: GET_RELATED_PRODUCTS_SUCCESS,
    payload: {
      associatedProducts: [product]
    }
  }),
  error: errorAction(GET_RELATED_PRODUCTS_FAILURE)
});
export const SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_REQUEST = `${PREFIX}SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_REQUEST`;
export const SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_SUCCESS = `${PREFIX}SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_SUCCESS`;
export const SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_FAILURE = `${PREFIX}SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_FAILURE`;
export const subscribeUserToOutOfStockProduct = createAsyncAction({
  transformData: ({
    email
  }, getState) => {
    var _productSelector;
    const state = getState();
    const user = userSelector(state);
    const isLimitedEdition = isLimitedEditionSelector(state);
    const {
      _id: productId
    } = (_productSelector = productSelector(state)) !== null && _productSelector !== void 0 ? _productSelector : {};
    return {
      email,
      productId,
      url: window.location.href,
      fullName: user.firstName && user.lastName ? getUserFullName(user) : null,
      company: user.company || null,
      isLimitedEdition
    };
  },
  preFn: dispatch => {
    dispatch({
      type: SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_REQUEST
    });
  },
  fn: ProductApi.subscribeUserToOutOfStockProduct,
  success: () => ({
    type: SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_SUCCESS
  }),
  error: errorAction(SUBSCRIBE_USER_TO_OUT_OF_STOCK_PRODUCT_FAILURE),
  postSuccess: (dispatch, getState, data, {
    text
  }) => dispatch(showNotification({
    text
  }))
});
export const GET_ADD_ON_PRODUCTS_FOR_BOX_REQUEST = `${PREFIX}GET_ADD_ON_PRODUCTS_FOR_BOX_REQUEST`;
export const GET_ADD_ON_PRODUCTS_FOR_BOX_SUCCESS = `${PREFIX}GET_ADD_ON_PRODUCTS_FOR_BOX_SUCCESS`;
export const getAddonProductsForBox = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_ADD_ON_PRODUCTS_FOR_BOX_REQUEST
    });
  },
  fn: ProductApi.getAddonProductsForBox,
  success: ({
    addons
  }) => ({
    type: GET_ADD_ON_PRODUCTS_FOR_BOX_SUCCESS,
    payload: addons
  }),
  error: error => ({
    type: GET_ADD_ON_PRODUCTS_FOR_BOX_SUCCESS,
    payload: [],
    error
  })
});
export const CHANGE_RELATED_PRODUCT_PREVIEW = `${PREFIX}CHANGE_RELATED_PRODUCT_PREVIEW`;
export const changeRelatedProductPreview = view => ({
  type: CHANGE_RELATED_PRODUCT_PREVIEW,
  view
});
export const CHANGE_PRODUCT_IMAGE_HOVER_STATUS = `${PREFIX}CHANGE_PRODUCT_IMAGE_HOVER_STATUS`;
export const changeProductImageHoverStatus = isProductImageHovered => ({
  type: CHANGE_PRODUCT_IMAGE_HOVER_STATUS,
  payload: {
    isProductImageHovered
  }
});
export const CHANGE_OPENED_TOOLBAR_ITEM = `${PREFIX}CHANGE_OPENED_TOOLBAR_ITEM`;
export const changeOpenedToolbarItem = openedToolbarItem => ({
  type: CHANGE_OPENED_TOOLBAR_ITEM,
  payload: {
    openedToolbarItem
  }
});
export const TOGGLE_FORCE_HIDE_TOOLBAR = `${PREFIX}TOGGLE_FORCE_HIDE_TOOLBAR`;
export const toggleForceHideToolbar = payload => ({
  type: TOGGLE_FORCE_HIDE_TOOLBAR,
  payload
});
export const MOUSE_ON_EMBELLISHMENT_METHOD = `${PREFIX}MOUSE_ON_EMBELLISHMENT_METHOD`;
export const MOUSE_OUT_EMBELLISHMENT_METHOD = `${PREFIX}MOUSE_OUT_EMBELLISHMENT_METHOD`;
export const mouseOnEmbellishmentMethod = payload => ({
  type: MOUSE_ON_EMBELLISHMENT_METHOD,
  payload
});
export const mouseOutEmbellishmentMethod = () => ({
  type: MOUSE_OUT_EMBELLISHMENT_METHOD
});
export const RESET_INVENTORY_ID = `${PREFIX}RESET_INVENTORY_ID`;
export const resetInventoryId = () => ({
  type: RESET_INVENTORY_ID
});
export const UPSELL_DISMISS_PRODUCT_BUILDER = `${PREFIX}UPSELL_DISMISS_PRODUCT_BUILDER`;
export const upsellDismissProductBuilder = () => (dispatch, getState) => {
  const state = getState();
  const isRebuildMode = isRebuildModeSelector(state);
  const itemId = isRebuildMode ? state.product.itemId : null;
  dispatch({
    type: UPSELL_DISMISS_PRODUCT_BUILDER,
    payload: {
      itemId
    }
  });
};
export const addToRecentlyViewedForUser = createAsyncAction({
  fn: RecentlyViewedApi.addToRecentlyViewed,
  success: '',
  error: ''
});
export const mergeRecentlyViewedForUser = () => async dispatch => {
  const guestRecentlyViewed = await getGuestRecentlyViewed();
  if (guestRecentlyViewed.length > 0) {
    dispatch(mergeRecentlyViewedForUserRequest({
      recentlyViewed: guestRecentlyViewed
    }));
  }
};
export const mergeRecentlyViewedForUserRequest = createAsyncAction({
  preFn: (dispatch, getState) => {
    return !isLoggedInSelector(getState());
  },
  transformData: (data, getState) => {
    const userId = userIdSelector(getState());
    return _objectSpread({
      userId
    }, data);
  },
  fn: RecentlyViewedApi.merge,
  postSuccess: (dispatch, getState, transformedData) => {
    bulkRemoveProductFromRecentlyViewed(transformedData.recentlyViewed.map(item => item.productId));
  },
  success: '',
  error: ''
});
export const saveAsRecentlyViewed = productId => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const isCreateMode = isCreateModeSelector(state);
  if (isCreateMode) {
    if (!userId) {
      addProductToRecentlyViewed(productId);
    } else {
      dispatch(addToRecentlyViewedForUser({
        userId,
        productId
      }));
    }
  }
};
export const SET_IS_LE_TIME_EXPIRED = `${PREFIX}SET_IS_LE_TIME_EXPIRED`;
export const setLETimeExpired = () => ({
  type: SET_IS_LE_TIME_EXPIRED
});
export const SET_SIZE_SUBMISSION_ID = `${PREFIX}SET_SIZE_SUBMISSION_ID`;
export const setSizeSubmissionId = ({
  id
}) => ({
  type: SET_SIZE_SUBMISSION_ID,
  payload: {
    id
  }
});
export const changeEmbellishmentMethod = method => (dispatch, getState) => {
  const state = getState();
  const settings = productSettingsSelector(state);
  const newMethodSettings = settings.find(s => s.embellishmentMethodId === method._id);
  if (!newMethodSettings) {
    return;
  }
  const currentProductionTime = currentProductionTimeSelector(state);
  if (!currentProductionTime) {
    dispatch(changeEmbellishmentMethodById(method._id));
    dispatch(changeTime(getDefaultProductionTimeIdx(newMethodSettings.productionTimeList)));
    return;
  }
  const currentProductionTimeIndex = getProductionTimeIndexFromSettings(newMethodSettings, currentProductionTime);
  if (currentProductionTimeIndex !== -1) {
    dispatch(changeEmbellishmentMethodById(method._id));
    dispatch(changeTime(currentProductionTimeIndex));
  } else {
    dispatch(showPopup({
      popup: EMBELLISHMENT_METHOD_CHANGE_CONFIRMATION_POPUP,
      methodId: method._id
    }));
  }
};
export const confirmEmbellishmentMethodChange = methodId => (dispatch, getState) => {
  const state = getState();
  const settings = productSettingsSelector(state);
  const newMethodSettings = settings.find(s => s.embellishmentMethodId === methodId);
  if (!newMethodSettings) {
    return;
  }
  const currentProductionTime = currentProductionTimeSelector(state);
  if (currentProductionTime && isSuperSpeedProductionOption(currentProductionTime)) {
    removeSuperSpeedProductIdFromLS();
  }
  dispatch(changeEmbellishmentMethodById(methodId));
  dispatch(changeTime(getDefaultProductionTimeIdx(newMethodSettings.productionTimeList)));
};