import { v4 } from 'uuid';
import { SOCKET_EVENTS } from 'swag-common/constants/socket-events';
import { getHotProductsToken, setHotProductsToken } from 'swag-client-common/utils/local-storage.util';
import { addNotification } from 'swag-client-common/redux/snackbar/snackbar.actions';
import { BC_ECOMMERCE, isBroadcastChannelSupported } from 'swag-client-common/shared-worker/broadcast-chanel';
import { port } from 'swag-client-common/shared-worker/ecommerce/ecommerce-worker-runner';
import { BCEvents } from 'swag-client-common/shared-worker/shared-worker.events';
import { is1024andSmaller, isSafari } from 'swag-client-common/utils/ui.utils';
import { getIsWhatWeOfferOrCustomPagePreview } from '../get-is-preview.util';
import { isCuratedBrandStoreSelector } from '../../redux/curated-brand-store/curated-brand-store-other.selector';
import { ROUTES_WITHOUT_NOTIFICATIONS } from './routes-without-notifications';
window.portPostMessage = ({
  userId,
  hotProductsUserToken,
  orderToken = '',
  draftToken = ''
}) => {
  port.postMessage({
    type: SOCKET_EVENTS.GET_HOT_PRODUCT_AUTOTEST,
    payload: {
      userId,
      tenantVisibility: bundle_type,
      hotProductsUserToken,
      orderToken,
      draftToken
    }
  });
};
let isListenerAdded = false;
export const getHotProductsUserToken = () => {
  const token = getHotProductsToken();
  if (!token) {
    const newToken = v4();
    setHotProductsToken(newToken);
    return newToken;
  }
  return token;
};
export const showHotProducts = ({
  dispatch,
  userId,
  orderToken,
  draftToken
}) => {
  var _window$swag$storeSet, _window, _window$swag$storeSet2;
  if (is1024andSmaller() || isCuratedBrandStoreSelector()) {
    return;
  }
  const token = getHotProductsUserToken();
  port.postMessage({
    type: SOCKET_EVENTS.GET_HOT_PRODUCT,
    payload: {
      userId,
      /** "bundle_type" defined with [`webpack.DefinePlugin`](https://webpack.js.org/plugins/define-plugin/) */
      tenant: (_window$swag$storeSet = (_window = window) === null || _window === void 0 ? void 0 : (_window$swag$storeSet2 = _window.swag.storeSettings) === null || _window$swag$storeSet2 === void 0 ? void 0 : _window$swag$storeSet2.resellerCompanyPublicId) !== null && _window$swag$storeSet !== void 0 ? _window$swag$storeSet : 'swag',
      tenantVisibility: bundle_type,
      hotProductsUserToken: token,
      orderToken,
      draftToken
    }
  });
  if (!isListenerAdded) {
    isListenerAdded = true;
    BC_ECOMMERCE.addEventListener(BCEvents.MESSAGE, event => {
      const {
        type,
        payload
      } = isBroadcastChannelSupported ? event.data : event;
      switch (type) {
        case SOCKET_EVENTS.SEND_HOT_PRODUCT:
          {
            const isVisible = document.visibilityState === 'visible';
            const isWhatWeOfferOrCustomPagePreview = getIsWhatWeOfferOrCustomPagePreview();
            const isRouteWithNotification = ROUTES_WITHOUT_NOTIFICATIONS.every(route => !window.location.pathname.includes(route));
            const shouldBeShown = isRouteWithNotification && !isWhatWeOfferOrCustomPagePreview && (!isSafari() || isVisible);
            if (shouldBeShown) {
              dispatch(addNotification(payload));
            }
            break;
          }
        default:
          {
            break;
          }
      }
    });
  }
};