import { FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME, FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE } from 'swag-common/constants/custom-store.constants';
import { FULL_DESIGN_DEFAULT_VARIANT_INDEX } from 'swag-common/constants/product.constants';
import isBox from './product-is-box.util';
import isFullDesign from './product-is-full-design.util';
export function getCustomProductColors(purchasedVariants, productVariants = [], hasNoColors) {
  if (hasNoColors) {
    return purchasedVariants.map(({
      quantities
    }) => {
      return {
        quantities,
        variantIndex: FULL_DESIGN_DEFAULT_VARIANT_INDEX,
        name: FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME,
        color: FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE
      };
    });
  }
  return purchasedVariants.map(({
    variantIndex,
    quantities
  }) => {
    const {
      color,
      name
    } = productVariants[variantIndex];
    return {
      variantIndex,
      color,
      name,
      quantities
    };
  });
}
export function getQuantitiesBasedOnColors(product = {}, itemVariants = []) {
  const {
    variants: productVariants
  } = product;
  const hasNoColors = isBox(product) || isFullDesign(product);
  const productColors = getCustomProductColors(itemVariants, productVariants, hasNoColors);
  return {
    hasNoColors,
    productColors
  };
}