import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CONFIRM_REMOVE_PRODUCT_POPUP } from 'swag-client-common/constants';
import { rebuildCBSProduct } from '../../../../customer/redux/curated-brand-store/curated-brand-store.actions';
import { AddedCBSProductsWidget as Component } from './added-cbs-products-widget';
import { cbsProductsSelector, isLoadingSelector } from './../../../../customer/redux/curated-brand-store/curated-brand-store-other.selector';
import { shouldShowCBSProductsWidgetSelector } from './../../../../customer/redux/curated-brand-store/curated-brand-store-other.selector.dynamic';
const mapStateToProps = createStructuredSelector({
  shouldShowCBSProductsWidget: shouldShowCBSProductsWidgetSelector,
  cbsProducts: cbsProductsSelector,
  isLoading: isLoadingSelector
});
const mapDispatchToProps = dispatch => ({
  onEdit: product => {
    dispatch(rebuildCBSProduct(product));
  },
  onRemove: id => {
    dispatch(showPopup({
      popup: CONFIRM_REMOVE_PRODUCT_POPUP,
      id
    }));
  }
});
export const AddedCBSProductsWidget = connect(mapStateToProps, mapDispatchToProps)(Component);