import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { SpaceStyledConfirmPopup } from 'swag-client-common/components/popups/space-styled-confirm-popup';
import { isConfirmRemoveProductPopupShownSelector, popupParamsSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { deleteBSProduct } from '../../../../../../customer/redux/curated-brand-store/curated-brand-store.actions';
const idSelector = createSelector(popupParamsSelector, ({
  id
}) => id);
const mapStateToProps = createStructuredSelector({
  open: isConfirmRemoveProductPopupShownSelector,
  id: idSelector
});
const mapDispatchToProps = dispatch => ({
  onConfirmClick: id => {
    dispatch(hidePopup());
    dispatch(deleteBSProduct({
      id
    }));
  },
  onCloseClick: () => {
    dispatch(hidePopup());
  }
});
export const ConfirmRemoveProductPopup = connect(mapStateToProps, mapDispatchToProps)(SpaceStyledConfirmPopup);