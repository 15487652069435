import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { LOGIN_POPUP } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import SetPasswordPopup from 'swag-client-common/components/partials/set-password-form';
import { setPassword } from '../../../redux/set-password/set-password.actions';
import { isLoadingSelector, isErrorSelector, errorMessageSelector, isSuccessSelector, modeSelector, passwordSelector, completedPasswordRequirementsSelector, isAllPasswordRequirementsCompletedSelector, isPasswordLengthMoreThanMaxAllowedCharactersSelector, isPasswordInputFocusedSelector } from '../../../redux/set-password/set-password.selectors';
import { isLoggedInSelector } from '../../../redux/user/user.selectors';
const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  isError: isErrorSelector,
  errorMessage: errorMessageSelector,
  isSuccess: isSuccessSelector,
  mode: modeSelector,
  password: passwordSelector,
  completedPasswordRequirements: completedPasswordRequirementsSelector,
  isAllPasswordRequirementsCompleted: isAllPasswordRequirementsCompletedSelector,
  isPasswordLengthMoreThanMaxAllowedCharacters: isPasswordLengthMoreThanMaxAllowedCharactersSelector,
  isPasswordInputFocused: isPasswordInputFocusedSelector,
  isLoggedIn: isLoggedInSelector
});
const mapDispatchToProps = (dispatch, state) => ({
  onSubmit: values => dispatch(setPassword(values)),
  onCongratsClose: showLoginPopup => {
    if (showLoginPopup) {
      dispatch(showPopup({
        popup: LOGIN_POPUP
      }));
    } else {
      dispatch(state.onClose());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPopup);