function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { BOX_PRICE_BREAKDOWN_POPUP, INSTANT_QUOTE_POPUP, PRICE_BREAKS_POPUP, SPECS_POPUP, ADD_TO_CART_WARNING_POPUP, CONFIRM_EDIT_ITEM_CHANGES_FOR_SHARED_ITEMS_POPUP, CONFIRM_CHANGE_SUPER_SPEED_PROD_TIME_POPUP, COLLECT_SIZES_POPUP, LOGIN_POPUP, SUPER_SPEED_COLOR_LIMITATION_POPUP, SAMPLES_DRAWER, PRODUCTION_TIME_DRAWER_FOR_PRODUCT, LOGIN_FOR_COLLECT_SIZES, EMBELLISHMENT_METHOD_CHANGE_CONFIRMATION_POPUP } from 'swag-client-common/constants';
import { showPopup, hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { editProductionTimeInCart } from 'swag-client-common/redux/cart/cart.actions';
import { clickAddToCart } from '../cart/cart.actions';
import { openRelatedProduct, rebuildRelatedProduct, changeTime as changeTimeForRelatedProduct, resetPopupMode, resetReducer as resetRelatedProductReducer } from '../related-product-builder-popup/related-product.actions';
import { showDebossedPopupAction, showDebossedPopupOnItemEditAction } from '../debossed-popup/debossed-popup.actions';
import { handleGeneralQuantityWithRelatedProductsChange, changeSingleQuantity, changeAssociatedProductQuantity, selectVariant, confirmSplitOrder, unselectColor, changeDesignNotes, changeTime, changeDiscount, changeSizeIndex, sendEmail, copyLink, removeAttachment, removeAssociatedProduct, changeRelatedProductsPopupVisibility, resetInventoryId, changeEmbellishmentMethodById } from './product.actions';
export const detailsDispatcher = dispatch => ({
  onVariantChange: index => {
    dispatch(selectVariant(index));
  },
  onDiscountChange: () => {
    dispatch(changeDiscount());
  },
  onSizeIndexChange: index => {
    dispatch(changeSizeIndex(index));
  },
  onDesignNotesChange: notes => {
    // @ts-ignore
    dispatch(changeDesignNotes({
      notes
    }));
  },
  onChangeRelatedProductsPopupVisibility: relatedProductsIds => {
    if (relatedProductsIds) {
      dispatch(changeRelatedProductsPopupVisibility());
    } else {
      dispatch(showNotification({
        text: 'All associated products have already been added'
      }));
    }
    dispatch(resetPopupMode());
  },
  openRelatedProductBuilder: product => {
    dispatch(rebuildRelatedProduct(product));
    dispatch(changeRelatedProductsPopupVisibility());
  },
  removeRelatedProduct: (productId, isShared) => {
    dispatch(removeAssociatedProduct(productId, isShared));
  },
  resetInventoryId: () => dispatch(resetInventoryId()),
  showDebossedPopup: productId => dispatch(showDebossedPopupAction(productId))
});
export const priceBlockDispatcher = dispatch => ({
  openPricingFormationDetailsPopup: () => {
    dispatch(showPopup({
      popup: 'pricingFormationDetails'
    }));
  },
  onInfoIconClick: () => {
    dispatch(showPopup({
      popup: BOX_PRICE_BREAKDOWN_POPUP
    }));
  }
});
export const productInformationDispatcher = dispatch => ({
  onSampleClick: () => {
    dispatch(showPopup({
      popup: SAMPLES_DRAWER
    }));
  },
  onSpecsClick: () => {
    dispatch(showPopup({
      popup: SPECS_POPUP
    }));
  },
  onClickInstantQuote: () => {
    dispatch(showPopup({
      popup: INSTANT_QUOTE_POPUP
    }));
  },
  onShowWarning: () => {
    dispatch(showPopup({
      popup: ADD_TO_CART_WARNING_POPUP
    }));
  }
});
export const multipleQuantityBlockDispatcher = (dispatch, props) => ({
  onStartCollectSizes: () => {
    if (props.isLoggedIn) {
      dispatch(showPopup({
        popup: COLLECT_SIZES_POPUP
      }));
    } else {
      dispatch(showPopup({
        popup: LOGIN_POPUP,
        title: LOGIN_FOR_COLLECT_SIZES
      }));
    }
  },
  onPriceBreakClick: () => {
    dispatch(showPopup({
      popup: PRICE_BREAKS_POPUP
    }));
  },
  onSingleQuantityChange: (value, variantIndex, sizeIndex) => {
    const quantity = parseInt(value || 0, 10) || 0;
    if (quantity.toString().length < 7) {
      dispatch(changeSingleQuantity(quantity, variantIndex, sizeIndex));
    }
  },
  onGeneralQuantityChange: (value = '0') => {
    const quantity = parseInt(value, 10) || 0;
    if (quantity.toString().length < 7) {
      dispatch(handleGeneralQuantityWithRelatedProductsChange(quantity));
    }
  },
  onAssociatedProductQuantityChange: (productId, quantity, variantIndex, sizeIndex) => {
    if (quantity.toString().length < 7) {
      dispatch(changeAssociatedProductQuantity(productId, variantIndex, sizeIndex, +quantity));
    }
  }
});
export const chooseColorDispatcher = dispatch => ({
  onColorClick: ({
    colorIndex
  }, active) => {
    if (active) {
      return dispatch(unselectColor(colorIndex));
    }
    return dispatch(selectVariant(colorIndex));
  },
  onSplitOrderClick: value => {
    dispatch(confirmSplitOrder(value));
  }
});
export const designNotesDispatcher = dispatch => ({
  onSubmit: ({
    notes,
    attachments
  }) => {
    dispatch(changeDesignNotes({
      notes,
      attachments
    }));
  },
  onDeleteAttachment: key => dispatch(removeAttachment(key))
});
export const footerDispatcher = dispatch => ({
  onTimeChange: time => dispatch(changeTime(time)),
  onShowWarning: e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(showPopup({
      popup: ADD_TO_CART_WARNING_POPUP
    }));
  },
  clickAddToCart: () => dispatch(clickAddToCart())
});
export const productionTimeBlockDispatcher = dispatch => ({
  onProductionTimeClick: () => dispatch(showPopup({
    popup: PRODUCTION_TIME_DRAWER_FOR_PRODUCT
  }))
});
export const productionTimePopupDispatcher = (dispatch, ownProps) => ({
  showDebossedPopupOnItemEdit: (itemId, isItemEdit) => {
    dispatch(showDebossedPopupOnItemEditAction(itemId, isItemEdit));
  },
  changeEmbellishmentMethod: id => {
    dispatch(changeEmbellishmentMethodById(id));
  },
  onSaveProductionTimeChanges: timeIndex => {
    dispatch(changeTime(timeIndex));
    dispatch(changeTimeForRelatedProduct(timeIndex));
  },
  onUpdateItem: params => {
    dispatch(editProductionTimeInCart(params));
  },
  onOpenConfirmPopup: params => {
    dispatch(hidePopup());
    dispatch(showPopup(_objectSpread({
      popup: CONFIRM_CHANGE_SUPER_SPEED_PROD_TIME_POPUP
    }, params)));
  },
  onShowSuperSpeedColorLimitationPopup: maxPrintColors => {
    dispatch(hidePopup());
    dispatch(showPopup({
      popup: SUPER_SPEED_COLOR_LIMITATION_POPUP,
      maxPrintColors
    }));
  },
  onShowEmbellishmentMethodChangeConfirmationPopup: ({
    itemId,
    embellishmentMethodId,
    isItemEdit,
    productionTimeId,
    storageType
  }) => {
    dispatch(showPopup({
      popup: EMBELLISHMENT_METHOD_CHANGE_CONFIRMATION_POPUP,
      methodId: embellishmentMethodId,
      isItemEdit,
      itemId,
      productionTimeId,
      storageType
    }));
  },
  onClose: () => dispatch(hidePopup()),
  onSharedItemUpdate: params => {
    dispatch(hidePopup());
    dispatch(showPopup(_objectSpread({
      popup: CONFIRM_EDIT_ITEM_CHANGES_FOR_SHARED_ITEMS_POPUP,
      prevPopupParams: ownProps.popupParams
    }, params)));
  }
});
export const shareContinueBlockDispatcher = dispatch => ({
  onShareProductClick: () => dispatch(showPopup({
    popup: 'shareProductPopup'
  }))
});
export const shareProductDispatcher = dispatch => ({
  onSendEmail: data => dispatch(sendEmail(data)),
  onLinkCopy: () => dispatch(copyLink())
});
export const relatedProductsPopupDispatcher = dispatch => ({
  onClose: () => {
    dispatch(changeRelatedProductsPopupVisibility());
  },
  openRelatedProductBuilder: product => {
    dispatch(resetRelatedProductReducer());
    dispatch(openRelatedProduct(product));
  }
});